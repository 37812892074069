/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { Container, ListaEdificios, Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { log, getClientesPublicitarios, quitarCaracteresEspeciales, saveOportunidad } from "../../utils";
import "./stylesCargaOportunidad.scss";

const currentYear = new Date().getFullYear();

export const CargaOportunidad = () => {
  const [clientesPublicitarios, setClientesPublicitarios] = useState();
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [atLeastOneBuildingSelected, setAtLeastOneBuildingSelected] = useState(false);
  const [edificiosSeleccionadosCount, setEdificiosSeleccionadosCount] = useState(0);

  const { pais, id, nombre, rol } = useAuthContext();

  const methods = useForm();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = methods;

  const edificiosSeleccionados = watch("edificiosSeleccionados");

  const months = [
    { value: "01", label: "ENE" },
    { value: "02", label: "FEB" },
    { value: "03", label: "MAR" },
    { value: "04", label: "ABR" },
    { value: "05", label: "MAY" },
    { value: "06", label: "JUN" },
    { value: "07", label: "JUL" },
    { value: "08", label: "AGO" },
    { value: "09", label: "SET" },
    { value: "10", label: "OCT" },
    { value: "11", label: "NOV" },
    { value: "12", label: "DIC" },
  ];

  const selectOptions = months.map((month) => (
    <option key={month.value} value={month.value}>
      {month.label}
    </option>
  ));

  const onSubmit = useCallback(
    (data) => {
      setShowModalConfirmacion(true);

      const newOportunidad = {
        fechaOportunidad: data.fechaOportunidad,
        cliente: quitarCaracteresEspeciales(data.clientePublicitario),
        mes: data.mes,
        mes_cierre: data.mesCierre, // Added mes_cierre field
        ano: data.ano,
        importe: data.importe,
        estatus: data.estatus,
        ejecutivo: id, // Ahora usamos 'id' en lugar de 'nombre'
        intermediario: selectedAgency,
        tipo: data.tipo,
        edificiosparticipantes: data.edificiosSeleccionados,
        edificiostotales: data.edificiosTotales,
        pantallastotales: data.pantallasTotales,
        formatopauta: data.formatopauta,
        periodopauta: Number(data.periodopauta),
        duracion: Number(data.duracion),
        categoria: selectedCategory,
        pais,
      };

      setTemporalDataForm(newOportunidad);
    },
    [pais, selectedAgency, selectedCategory, id]
  );

  const handleBuildingSelection = useCallback(() => {
    const count = edificiosSeleccionados ? edificiosSeleccionados.length : 0;
    setEdificiosSeleccionadosCount(count);
  }, [edificiosSeleccionados]);

  useEffect(() => {
    handleBuildingSelection();
  }, [handleBuildingSelection]);

  const saveButtonHandle = useCallback(async () => {
    if (edificiosSeleccionadosCount === 0) {
      toast.error("Debe seleccionar al menos un edificio.");
      return;
    }
    try {
      setShowModalConfirmacion(false);
      await saveOportunidad(temporalDataForm);
      reset();
      setValue("edificiosTotales", 0);
      setValue("pantallasTotales", 0);
      setValue("personasTotales", "0");
      toast.success("La oportunidad se guardó correctamente");
      setTimeout(() => {
        setShouldRefresh(true);
      }, 2000);
    } catch (error) {
      toast.error("Hubo un error al guardar la oportunidad, prueba de nuevo.");
      log.error("saveButtonHandle", error);
    }
  }, [atLeastOneBuildingSelected, temporalDataForm, reset, setValue]);

  const validarSelect = useCallback((value) => value !== "0", []);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const newClientesPublicitarios = await getClientesPublicitarios({ pais });

        let filteredClientes;

        if (pais === "Uruguay") {
          // Si el país es Uruguay, mostrar todos los clientes publicitarios asociados a Uruguay
          filteredClientes = newClientesPublicitarios.filter((cliente) => cliente.pais === "Uruguay");
        } else if (rol === "comercial") {
          // Filter clients based on the logged-in executive's ID for commercial roles
          filteredClientes = newClientesPublicitarios.filter((cliente) => cliente.ejecutivo === id.toString());
        } else if (["superadmin", "gerencia"].includes(rol)) {
          // For superadmin or gerencia, show all clients
          filteredClientes = newClientesPublicitarios;
        } else {
          // Handle other cases or default to no clients if role is invalid
          filteredClientes = [];
        }

        setClientesPublicitarios(filteredClientes);
      } catch (error) {
        console.error("Error al inicializar datos:", error);
        toast.error("Hubo un error al cargar los datos. Inténtalo de nuevo.");
      }
    };

    initialize();
  }, [pais, id, rol]);

  useEffect(() => {
    if (edificiosSeleccionados && edificiosSeleccionados.length > 0) {
      setAtLeastOneBuildingSelected(true);
    } else {
      setAtLeastOneBuildingSelected(false);
    }
  }, [edificiosSeleccionados]);

  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
    }
  }, [shouldRefresh]);

  const cantidadDias = useMemo(() => Array.from({ length: 260 }, (_, i) => i + 1), []);
  const cantidadSegundos = useMemo(() => Array.from({ length: 10 }, (_, i) => i + 6), []);

  useEffect(() => {
    if (clientesPublicitarios && clientesPublicitarios.length > 0) {
      const clienteSeleccionado = clientesPublicitarios.find(
        (cliente) => cliente.nombre === methods.getValues("clientePublicitario")
      );
      if (clienteSeleccionado) {
        setSelectedAgency(clienteSeleccionado.agencia);
        setSelectedCategory(clienteSeleccionado.categoria);
      }
    }
  }, [clientesPublicitarios, methods.getValues("clientePublicitario")]);

  return (
    <Container className="CargarFactura" header titulo="CARGA DE OPORTUNIDAD">
      {clientesPublicitarios ? (
        <FormProvider {...methods}>
          <form className="row g-4 mt-2 h-100" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-select", errors?.clientePublicitario && "is-invalid")}
                id="clientePublicitario"
                defaultValue="0"
                {...register("clientePublicitario", { validate: validarSelect })}
              >
                <option disabled value="0">
                  CLIENTE
                </option>

                {clientesPublicitarios && clientesPublicitarios.length > 0 ? (
                  clientesPublicitarios.map(({ nombre, id }) => (
                    <option key={id} value={nombre}>
                      {nombre}
                    </option>
                  ))
                ) : (
                  <option value="">No hay clientes disponibles</option>
                )}
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <input
                className={cx("form-control", errors?.importe && "is-invalid")}
                id="importe"
                placeholder="TICKET INVERSIÓN"
                type="number"
                step="0.01"
                {...register("importe", { required: true, min: 0 })}
              />
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.status && "is-invalid")}
                id="estatus"
                {...register("estatus", { required: true })}
              >
                <option value="" hidden>
                  STATUS %
                </option>
                <option value="100%">100%</option>
                <option value="90%">90%</option>
                <option value="75%">75%</option>
                <option value="50%">50%</option>
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.mesCierre && "is-invalid")}
                id="mesCierre"
                {...register("mesCierre", { required: true })}
              >
                <option value="" hidden>
                  MES DE CIERRE
                </option>
                {selectOptions}
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.mes && "is-invalid")}
                id="mes"
                {...register("mes", { required: true })}
              >
                <option value="" hidden>
                  MES DE FACTURACIÓN
                </option>
                {selectOptions}
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.ano && "is-invalid")}
                id="ano"
                defaultValue={currentYear.toString()} // Aquí establecemos el año actual como predeterminado
                {...register("ano", { required: true })} // Registro del campo año con react-hook-form
              >
                <option value="" hidden>
                  AÑO DE FACTURACIÓN
                </option>
                {[currentYear - 1, currentYear, currentYear + 1].map((year) => (
                  <option key={year} value={year.toString()}>
                    {year}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.formatopauta && "is-invalid")}
                id="formatopauta"
                {...register("formatopauta", { required: true })}
              >
                <option value="" hidden>
                  FRECUENCIA POR HORA
                </option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.periodopauta && "is-invalid")}
                id="periodopauta"
                {...register("periodopauta", { required: true })}
              >
                <option value="" hidden>
                  PERÍODO DE PAUTA
                </option>
                {cantidadDias.map((cantidadDias) => (
                  <option key={cantidadDias}>{cantidadDias}</option>
                ))}
              </select>
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.duracion && "is-invalid")}
                id="duracion"
                {...register("duracion", { required: true })}
              >
                <option value="" hidden>
                  DURACIÓN DE LA PAUTA
                </option>
                {cantidadSegundos.map((sec) => (
                  <option key={sec}>{sec}</option>
                ))}
              </select>
            </div>
            <div className="col-2 text-start fw-bold">
              <input
                type="text"
                className={cx("form-control", errors?.ejecutivo && "is-invalid")}
                id="ejecutivo"
                value={nombre || ""}
                placeholder="EJECUTIVO"
                style={{ backgroundColor: "#e9ecef", color: "#6c757d" }} // Gris un poco más oscuro
                readOnly
                {...register("ejecutivo", {
                  required: true,
                  setValueAs: (value) => id, // Aseguramos que el valor enviado sea el id del ejecutivo
                })}
              />
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <input
                type="text"
                className={cx("form-control", errors?.selectedAgency && "is-invalid")}
                id="selectedAgency"
                value={selectedAgency || ""}
                placeholder="AGENCIA"
                style={{ backgroundColor: "#e9ecef", color: "#6c757d" }} // Gris un poco más oscuro
                readOnly
              />
            </div>
            <div className="col-2 text-start fw-bold">
              <select
                className={cx("form-control", errors?.ejecutivo && "is-invalid")}
                id="tipo"
                {...register("tipo", { required: true })}
              >
                <option value="" hidden>
                  TIPO DE COMPRA
                </option>
                <option value="d">Digital</option>
                <option value="p">Programmatic</option>
                <option value="m">Mix</option>
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-2 text-start fw-bold">
              <input
                className={cx("form-control", errors?.edificiosTotales && "is-invalid")}
                value={watch("edificiosTotales") || "EDIFICIOS"}
                disabled
                id="edificiosTotales"
                type="text"
                {...register("edificiosTotales", { required: true })}
                style={{ backgroundColor: "#e9ecef", color: "#6c757d" }}
              />
            </div>
            <div className="col-2 text-start fw-bold">
              <input
                className={cx("form-control", errors?.pantallasTotales && "is-invalid")}
                value={watch("pantallasTotales") || "PANTALLAS"}
                disabled
                id="pantallasTotales"
                type="text"
                {...register("pantallasTotales", { required: true })}
                style={{ backgroundColor: "#e9ecef", color: "#6c757d" }}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div className="col-2 d-flex align-items-end justify-content-center" style={{ minHeight: "100%" }}>
                <button
                  className="btn btn-primary"
                  style={{ minHeight: "fit-content" }}
                  type="submit"
                  disabled={disableSubmit}
                >
                  {!disableSubmit ? (
                    "GUARDAR OPORTUNIDAD"
                  ) : (
                    <>
                      <Spinner size="sm">GUARDANDO...</Spinner>
                      <span> GUARDANDO</span>
                    </>
                  )}
                </button>
              </div>
            </div>
            <ListaEdificios />
            <input hidden type="text" {...register("personasTotales")} />
            <input hidden type="text" {...register("edificiosSeleccionados")} />
          </form>
          <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
            <ModalHeader>¿Está seguro?</ModalHeader>
            <ModalBody>Se está por guardar una nueva oportunidad</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
                Cancelar
              </Button>
              <Button color="primary" onClick={saveButtonHandle}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
