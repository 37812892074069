import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./stylesBlackArrow.scss";

export const BlackArrow = () => (
  <Link to="#" onClick={() => window.history.back()}>
    <FaArrowLeft style={{ fontSize: "2rem", marginLeft: "2rem", marginTop: "2rem", color: "black" }} />
  </Link>
);
