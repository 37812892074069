/* eslint-disable no-console */
import React, { useState, useCallback } from "react";
import {
  CotizadorPlazo,
  CotizadorProgrammaticCalculadora,
  CotizadorProgrammaticDuracion,
  CotizadorProgrammaticFrecuencia,
  CotizadorProgrammaticHorasOn,
  CotizadorProgrammaticInventario,
} from "../../components";
import "./stylesCotizadorProgrammatic.scss";

export const CotizadorProgrammatic = () => {
  const [inventarioData, setInventarioData] = useState({
    ascensoresSeleccionados: 0,
    audienciaSeleccionadaAscensor: 0,
    audienciaSeleccionadaCowork: 0,
    audienciaSeleccionadaTotal: 0,
    audienciaSeleccionadaTotems: 0,
    coworkSeleccionados: 0,
    edificiosSeleccionadosAscensores: [],
    edificiosSeleccionadosCowork: [],
    edificiosSeleccionadosTotems: [],
    edificiosSeleccionadosEspera: [],
    esperaSeleccionados: 0,
    totemsSeleccionados: 0,
  });
  const [plazo, setPlazo] = useState(0);
  const [frecuencia, setFrecuencia] = useState("");
  const [horasSeleccionadas, setHorasSeleccionadas] = useState({
    horas: 0,
    intervalos: [],
    options: [],
  });
  const [duracion, setDuracion] = useState(0);
  const [isProgrammaticSelected, setIsProgrammaticSelected] = useState(false);
  const [isDigitalSelected, setIsDigitalSelected] = useState(false);
  const [resetInventario, setResetInventario] = useState(false);

  const handleProgrammaticChange = (isProgrammatic) => {
    setIsProgrammaticSelected(isProgrammatic);
    setIsDigitalSelected(!isProgrammatic);
    setResetInventario(true);

    setInventarioData({
      ascensoresSeleccionados: 0,
      audienciaSeleccionadaAscensor: 0,
      audienciaSeleccionadaCowork: 0,
      audienciaSeleccionadaTotal: 0,
      audienciaSeleccionadaTotems: 0,
      coworkSeleccionados: 0,
      edificiosSeleccionadosAscensores: [],
      edificiosSeleccionadosCowork: [],
      edificiosSeleccionadosTotems: [],
      edificiosSeleccionadosEspera: [],
      esperaSeleccionados: 0,
      totemsSeleccionados: 0,
    });
    setHorasSeleccionadas({ horas: 0, intervalos: [], options: [] }); // Resetear opciones al cambiar
  };

  const handleDigitalChange = (isDigital) => {
    setIsDigitalSelected(isDigital);
    setIsProgrammaticSelected(!isDigital);
    setResetInventario(true);

    setInventarioData({
      ascensoresSeleccionados: 0,
      audienciaSeleccionadaAscensor: 0,
      audienciaSeleccionadaCowork: 0,
      audienciaSeleccionadaTotal: 0,
      audienciaSeleccionadaTotems: 0,
      coworkSeleccionados: 0,
      edificiosSeleccionadosAscensores: [],
      edificiosSeleccionadosCowork: [],
      edificiosSeleccionadosTotems: [],
      edificiosSeleccionadosEspera: [],
      esperaSeleccionados: 0,
      totemsSeleccionados: 0,
    });
    setHorasSeleccionadas({ horas: 0, intervalos: [], options: [] }); // Resetear opciones al cambiar
  };

  const handleResetInventarioComplete = () => {
    setResetInventario(false);
  };

  const handleSubmitInventario = (data) => {
    setInventarioData(data);
  };

  const handlePlazoChange = (numberOfDays) => {
    setPlazo(numberOfDays);
  };

  const handleFrecuenciaChange = (selectedFrecuencia) => {
    setFrecuencia(selectedFrecuencia);
  };

  const handleHorasChange = useCallback((totalHours, selectedIntervals, selectedOptions) => {
    setHorasSeleccionadas({ horas: totalHours, intervalos: selectedIntervals, options: selectedOptions });
  }, []);

  const handleDuracionChange = (selectedDuracion) => {
    setDuracion(selectedDuracion);
  };

  // Obtener la opción seleccionada desde horasSeleccionadas.options
  const selectedOption = horasSeleccionadas.options.length > 0 ? horasSeleccionadas.options[0].label : "";

  return (
    <div className="cotizador-programmatic-wrapper">
      <div className="calculator-fixed">
        <CotizadorProgrammaticCalculadora
          inventarioData={inventarioData}
          plazo={plazo}
          frecuencia={frecuencia}
          horasSeleccionadas={horasSeleccionadas}
          duracion={duracion}
          isProgrammaticSelected={isProgrammaticSelected}
        />
      </div>
      <div className="controls-container">
        <div className="controls-row">
          <CotizadorProgrammaticHorasOn
            onHorasChange={handleHorasChange}
            onProgrammaticChange={handleProgrammaticChange}
            onDigitalChange={handleDigitalChange}
            onOptionChange={(option) =>
              handleHorasChange(horasSeleccionadas.horas, horasSeleccionadas.intervalos, [{ label: option }])
            }
          />
          <CotizadorProgrammaticInventario
            onSubmit={handleSubmitInventario}
            isProgrammaticSelected={isProgrammaticSelected}
            resetInventario={resetInventario}
            onResetComplete={handleResetInventarioComplete}
          />
          <CotizadorPlazo onPlazoChange={handlePlazoChange} />
          <CotizadorProgrammaticFrecuencia
            onFrecuenciaChange={handleFrecuenciaChange}
            isProgrammaticSelected={isProgrammaticSelected}
            isDigitalSelected={isDigitalSelected}
            selectedOption={selectedOption} // Pasar la opción seleccionada
          />
          <CotizadorProgrammaticDuracion onDuracionChange={handleDuracionChange} />
        </div>
      </div>
    </div>
  );
};
