import { log } from "./log";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const client = createClient(supabaseUrl, supabaseAnonKey);

const fetchData = async (url, method, body) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        apikey: supabaseAnonKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Verificar si la respuesta fue exitosa (código de estado 2xx)
    if (response.ok) {
      // Verificar si la respuesta tiene contenido
      const contentType = response.headers.get("content-type");
      const hasContent = contentType && contentType.includes("application/json");

      // Si la respuesta es JSON, devolverla, sino se devuelve vacía
      return hasContent ? await response.json() : {};
    } else {
      // Capturar el mensaje de error del cuerpo de la respuesta
      const errorBody = await response.json();
      // Lanzar una excepción con la información del error
      throw errorBody;
    }
  } catch (error) {
    log.error("supabase fetchData:", error);
    throw error;
  }
};

export const supabase = {
  insert: async (tableName, data) => {
    const url = `${supabaseUrl}/rest/v1/${tableName}`;
    return await fetchData(url, "POST", data);
  },

  update: async (tableName, data, id) => {
    const url = `${supabaseUrl}/rest/v1/${tableName}?id=eq.${id}`;
    return await fetchData(url, "PATCH", data);
  },

  remove: async (tableName, id) => {
    const url = `${supabaseUrl}/rest/v1/${tableName}?id=eq.${id}`;
    return await fetchData(url, "DELETE");
  },

  select: async (tableName, { filters = {}, orderBy = [] } = {}) => {
    const queryParams = new URLSearchParams();

    // Agregar filtros
    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "object" && (value.gte || value.lte)) {
        // Formatear fechas en formato ISO 8601
        const formattedGte = value.gte instanceof Date ? value.gte.toISOString() : value.gte;
        const formattedLte = value.lte instanceof Date ? value.lte.toISOString() : value.lte;
        // Agregar filtro de rango (gte, lte)
        if (formattedGte) queryParams.append(`${key}`, `gte.${formattedGte}`);
        if (formattedLte) queryParams.append(`${key}`, `lte.${formattedLte}`);
      } else {
        // Agregar filtro normal o like
        if (typeof value === "string") {
          // Agregar el operador "eq" a los filtros de cadenas
          queryParams.append(key, `eq.${value}`);
        } else if (typeof value === "object" && value.like) {
          // Formatear el valor para escapar los caracteres especiales
          const formattedValue = value.like.replace(/[%_]/g, "\\$&");
          queryParams.append(`${key}`, `like.%${formattedValue}%`);
        }
      }
    }

    // Agregar ordenamientos
    if (orderBy.length > 0) {
      if (typeof orderBy === "string") {
        // Si solo se proporciona un ordenamiento como cadena
        queryParams.append("order", orderBy);
      } else {
        // Si se proporciona una lista de ordenamientos como objetos
        orderBy.forEach((order) => {
          queryParams.append("order", `${order.column}.${order.ascending ? "asc" : "desc"}`);
        });
      }
    }

    const url = `${supabaseUrl}/rest/v1/${tableName}?${queryParams.toString()}`;
    return await fetchData(url, "GET");
  },

  getCurrentUser: async () => {
    try {
      const {
        data: { user },
      } = await client.auth.getUser();

      if (user) {
        return user;
      } else throw new Error("No user found");
    } catch (error) {
      log.error("getCurrentUser:", error);
      throw error;
    }
  },

  loginWithEmail: async (email, pais) => {
    try {
      const isLocalhost = window.location.href.includes("localhost");

      log.debug("pais recibido en loginWithEmail:", pais); // Log del valor recibido
      log.debug("isLocalhost:", isLocalhost);

      const redirect = isLocalhost
        ? "http://localhost:3000/admin"
        : {
            Chile: "https://wecast.cl/admin",
            Perú: "https://visiona.pe/admin",
            Uruguay: "https://www.screenmedia.com.uy/admin",
            Region: "https://adigitalholding.com/admin",
          }[pais] || "https://adigitalholding.com";

      log.debug("redirect calculado:", redirect);

      const { data, error } = await client.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: redirect,
        },
      });

      if (error) throw error;

      return data;
    } catch (error) {
      log.error("loginWithEmail:", error);
      throw error;
    }
  },

  getSession: async () => {
    try {
      const { data } = await client.auth.getSession();

      if (data?.session !== undefined) {
        return data.session;
      } else throw new Error("No user found");
    } catch (error) {
      log.error("getSession:", error);
      throw error;
    }
  },

  logout: async () => {
    try {
      const { error } = await client.auth.signOut();

      if (error) throw error;

      return { success: true };
    } catch (error) {
      log.error("logout:", error);
      throw error;
    }
  },

  updateUsuario: async ({ apellido, nombre }) => {
    try {
      const currentDate = new Date();
      const newUserData = {
        apellido,
        nombre,
        updated_at: currentDate,
      };
      const { data, error } = await client.auth.update({ data: newUserData });
      log.debug("data", data);

      if (error) throw error;

      if (data) {
        log.debug("updateUsuario", data);
        return data;
      } else {
        throw new Error("No data");
      }
    } catch (error) {
      log.error("updateUsuario", error);
      throw error;
    }
  },

  onAuthStateChange: (callback) => client.auth.onAuthStateChange((_event, session) => callback(session)),
};
