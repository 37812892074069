/* eslint-disable unicorn/prefer-module */
import React, { useMemo } from "react";
import { FichaEdificios } from "../../components";
import { useAuthContext } from "../../context/auth";
import logoImage from "../../img/wecast-logo-transparente.png";
import "./stylesPortafolioFichaEdificios.scss"; // Importa el archivo de estilos

export const PortafolioFichaEdificios = () => {
  const { pais } = useAuthContext();

  const logo = useMemo(() => {
    switch (pais) {
      case "Chile":
        return logoImage;
      case "Perú":
        return null;
      case "Uruguay":
        return require("../../img/screenmedia-logo.png");
      default:
        return require("../../img/visiona-logo.png");
    }
  }, [pais]);

  return (
    <div className="body-portafolio">
      <header className="header-portafolio">
        {logo && <img src={logo} alt="Logo" className="logoportafolio" />} {/* Renderizar solo si hay un logo */}
        <div className="portafolio-ficha-edificios">PORTAFOLIO</div>
      </header>
      <FichaEdificios />
    </div>
  );
};
