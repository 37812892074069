import { useMemo } from "react";
import cx from "classix";
import "./stylesLoader.scss";

export const Loader = ({ fullPage = false, className }) => {
  const text = useMemo(() => "...", []);

  return (
    <div className={cx("Loader", fullPage && "full", className)}>
      <span className="animation" data-title={text}>
        {text}
      </span>
    </div>
  );
};
