import { log, supabase } from ".";

export const updateEdificio = async ({
  id,
  personas,
  estacionamientos,
  vetos,
  empresaslistado,
  empresasdestacadas,
}) => {
  try {
    // Eliminar separadores de miles y asegurar que 'personas' sea un número
    const formattedPersonas = typeof personas === "string" ? parseInt(personas.replace(/,/g, ""), 10) : personas;

    // Eliminar separadores de miles y asegurar que 'estacionamientos' sea un número
    const formattedEstacionamientos =
      typeof estacionamientos === "string" ? parseInt(estacionamientos.replace(/,/g, ""), 10) : estacionamientos;

    // Formatear 'vetos' como una cadena separada por comas
    const formattedVetos = Array.isArray(vetos) ? vetos.join(", ") : vetos;

    // Prepara los datos para enviar a Supabase
    const updatedData = {
      personas: formattedPersonas, // Enviar el valor limpio
      estacionamientos: formattedEstacionamientos, // Enviar el valor limpio de estacionamientos
      vetos: formattedVetos,
      empresaslistado: empresaslistado || null, // Asegurar que sea null si no hay valor
      empresasdestacadas: empresasdestacadas || null, // Asegurar que sea null si no hay valor
    };

    // Llama a la función personalizada 'update'
    const data = await supabase.update("edificios", updatedData, id);

    // Log de depuración
    log.debug("updateEdificio", data);
    return data;
  } catch (error) {
    log.error("updateEdificio", error);
    throw error;
  }
};
