import { VentasDetalle } from "../../components";
import "./stylesVentasTabla.scss";

const VentasTabla = () => (
  <>
    <VentasDetalle />
  </>
);

export default VentasTabla;
