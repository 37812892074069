/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getOportunidades } from "../../utils";
import "./stylesVentasTablaEjecutivo.scss";

export const VentasTablaEjecutivo = ({ filtroEjecutivo }) => {
  const [oportunidades, setOportunidades] = useState([]);
  const [filtroAno] = useState("2025");

  const { pais, rol, id } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  const objetivosPorMes2025 = useCallback(
    (rol, id) => {
      const objetivosPorId = {
        32: [
          11808054, 5284024, 18368084, 31688672, 21125781, 30368310, 35228630, 28823424, 32026027, 46966329, 30192640,
          16773689,
        ],
        270: [
          0,
          0,
          18368084 / 2,
          31688672 * 0.75,
          21125781 * 0.75,
          30368310 * 0.75,
          35228630,
          28823424,
          32026027,
          46966329,
          30192640,
          16773689,
        ],
        6: [100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000],
        1: [1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000],
        10: [
          1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000,
        ],
      };

      if (rol === "comercial") {
        return id in objetivosPorId ? objetivosPorId[id] : Array(12).fill(0);
      } else if (rol === "gerencia" || rol === "superadmin") {
        return filtroEjecutivo in objetivosPorId
          ? objetivosPorId[filtroEjecutivo]
          : id in objetivosPorId
            ? objetivosPorId[id]
            : Array(12).fill(0);
      }

      return Array(12).fill(0);
    },
    [filtroEjecutivo]
  );

  const objetivosPorMes = useCallback(
    (rol, ejecutivoId) => {
      const efectivoId = ejecutivoId || (rol === "comercial" ? id : "");
      return objetivosPorMes2025(rol, efectivoId) || [];
    },
    [objetivosPorMes2025, id]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ejecutivoId = rol === "comercial" ? id : filtroEjecutivo || id;
        const allOportunidades = await getOportunidades({ pais, ano: filtroAno });
        const filteredOportunidades = allOportunidades.filter((oportunidad) => {
          const matchesEjecutivo = oportunidad.ejecutivo === ejecutivoId.toString();
          const matchesAno = String(oportunidad.ano) === filtroAno;
          const matchesEliminada = oportunidad.eliminada === null || oportunidad.eliminada === false;

          return matchesEjecutivo && matchesAno && matchesEliminada;
        });

        setOportunidades(filteredOportunidades);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, [pais, rol, filtroAno, filtroEjecutivo, id]);

  const getMonthName = (index) => {
    const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return months[index];
  };

  const getMonthStatus = (monthIndex, status) =>
    oportunidades
      .filter((oportunidad) => parseInt(oportunidad.mes) - 1 === monthIndex && oportunidad.estatus === status)
      .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);

  const getQuarterSubtotal = (quarterIndex, status) => {
    const startMonth = quarterIndex === 1 ? 1 : quarterIndex === 3 ? 4 : quarterIndex === 6 ? 7 : 10;
    const endMonth = quarterIndex === 1 ? 3 : quarterIndex === 3 ? 6 : quarterIndex === 6 ? 9 : 12;
    return oportunidades
      .filter(
        (oportunidad) =>
          parseInt(oportunidad.mes) >= startMonth &&
          parseInt(oportunidad.mes) <= endMonth &&
          oportunidad.estatus === status &&
          !oportunidad.eliminada
      )
      .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);
  };

  const getHalfSubtotal = (halfIndex, status) => {
    const startMonth = halfIndex === 1 ? 1 : 7;
    const endMonth = halfIndex === 1 ? 6 : 12;
    return oportunidades
      .filter(
        (oportunidad) =>
          parseInt(oportunidad.mes) >= startMonth &&
          parseInt(oportunidad.mes) <= endMonth &&
          oportunidad.estatus === status &&
          !oportunidad.eliminada
      )
      .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);
  };

  const renderSubtotal = (subtotal) => (
    <td className="col-valor text-end">
      {moneda(pais)}
      {subtotal !== undefined
        ? subtotal.toLocaleString(localString(pais), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        : "0"}
    </td>
  );

  // const renderCumplimiento = (quarterOrHalf, total) => (
  //   <td className="col-porcentaje text-center">
  //     {quarterOrHalf !== 0 && total !== undefined
  //       ? ((total / quarterOrHalf) * 100).toLocaleString(localString(pais), {
  //           minimumFractionDigits: 0,
  //           maximumFractionDigits: 0,
  //         }) + "%"
  //       : "0%"}
  //   </td>
  // );

  const currentMonth = new Date().getMonth();

  const renderMissing = (acumuladoObjetivo, acumuladoTotal, shouldRender) => {
    if (!shouldRender) {
      return <td className="col-pendiente text-end">-</td>;
    }
    const diferencia =
      acumuladoObjetivo !== undefined && acumuladoTotal !== undefined ? acumuladoTotal - acumuladoObjetivo : 0;
    return (
      <td className={`col-pendiente text-end ${diferencia < 0 ? "text-danger" : "text-black"}`}>
        {diferencia >= 0
          ? "No Hay!"
          : `${moneda(pais)}-${Math.abs(diferencia).toLocaleString(localString(pais), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`}
      </td>
    );
  };

  const totalYTD = objetivosPorMes(rol, id).reduce((total, monto) => total + (monto || 0), 0);

  const objetivosAcumulados = useCallback((objetivos) => {
    const acumulado = [];
    let total = 0;
    for (let i = 0; i < objetivos.length; i++) {
      total += objetivos[i];
      acumulado.push(total);
    }
    return acumulado;
  }, []);

  return (
    <div>
      <table className="table-ventas-ejecutivo table table-striped table-hover table-bordered table-sm">
        <thead className="text-center align-middle">
          <tr>
            <th className="col-mes" rowSpan="2">
      MES
            </th>
            <th colSpan="3" className="col-100 bg-success text-white">
      OBJETIVO MENSUAL
            </th>
            <th colSpan="3" className="col-acumulado">
      OBJETIVO YTD
            </th>
            <th className="col-valor bg-danger text-white" rowSpan="2">
      OPORTUNIDADES <br /> AL 90%
            </th>
            <th className="col-valor bg-primary text-white" rowSpan="2">
      OPORTUNIDADES <br /> AL 75%
            </th>
          </tr>
          <tr>
            <th className="bg-success text-white">IMPORTE</th>
            <th className="bg-success text-white">100%</th>
            <th className="bg-success text-white">CUMPLIMIENTO</th>
            <th>IMPORTE</th>
            <th>CUMPLIMIENTO</th>
            <th>PENDIENTE</th>
          </tr>
        </thead>
        <tbody>
          {/* Monthly data */}
          {[...Array(12)].map((_, index) => {
            const objetivos = objetivosPorMes2025(rol, filtroEjecutivo ? filtroEjecutivo : id);
            const objetivosAcum = objetivosAcumulados(objetivos);
            const total100 = getMonthStatus(index, "100%");
            const totalAcumulado100 = oportunidades
              .filter(
                (oportunidad) =>
                  parseInt(oportunidad.mes) - 1 <= index && oportunidad.estatus === "100%" && !oportunidad.eliminada
              )
              .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);

            const shouldRender = index <= currentMonth;
            const isUser270 = id === 270;
            const isJanOrFeb = index === 0 || index === 1;

            return (
              <tr key={index}>
                <th className="col-mes text-start">{getMonthName(index)}</th>
                <td className="text-end">
                  {isUser270 && isJanOrFeb ? "-" : (
                    <>
                      {moneda(pais)}
                      {objetivos[index] !== undefined
                        ? objetivos[index].toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                        : "0"}
                    </>
                  )}
                </td>
                <td className="text-end">
                  {isUser270 && isJanOrFeb ? "-" : shouldRender ? (
                    <>
                      {moneda(pais)}
                      {total100 !== undefined
                        ? total100.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                        : "0"}
                    </>
                  ) : "-"}
                </td>
                <td className="text-center">
                  {isUser270 && isJanOrFeb ? "-" : shouldRender ? (
                    objetivos[index] !== 0 && total100 !== undefined
                      ? ((total100 / objetivos[index]) * 100).toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) + "%"
                      : "0%"
                  ) : "-"}
                </td>
                <td className="text-end">
                  {isUser270 && isJanOrFeb ? "-" : shouldRender ? (
                    <>
                      {moneda(pais)}
                      {objetivosAcum[index] !== undefined
                        ? objetivosAcum[index].toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                        : "0"}
                    </>
                  ) : "-"}
                </td>
                <td className="text-center">
                  {isUser270 && isJanOrFeb ? "-" : shouldRender ? (
                    totalAcumulado100 !== undefined && objetivosAcum[index] !== undefined && objetivosAcum[index] !== 0
                      ? ((totalAcumulado100 / objetivosAcum[index]) * 100).toFixed(0) + "%"
                      : "0%"
                  ) : "-"}
                </td>
                {isUser270 && isJanOrFeb ? (
                  <td className="col-pendiente text-end">-</td>
                ) : (
                  renderMissing(objetivosAcum[index], totalAcumulado100, shouldRender)
                )}
                {isUser270 && isJanOrFeb ? (
                  <td className="col-valor text-end">-</td>
                ) : (
                  renderSubtotal(getMonthStatus(index, "90%"))
                )}
                {isUser270 && isJanOrFeb ? (
                  <td className="col-valor text-end">-</td>
                ) : (
                  renderSubtotal(getMonthStatus(index, "75%"))
                )}
              </tr>
            );
          })}

          {/* YTD */}
          <tr className="table-dark">
            <th scope="row">
              YTD
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%") !== undefined
                ? (getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%")).toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "0"}
            </td>
            <td className="text-center">
              {totalYTD !== 0
                ? (((getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%")) / totalYTD) * 100).toFixed(0) + "%"
                : "0%"}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-center">
              {totalYTD !== 0
                ? (((getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%")) / totalYTD) * 100).toFixed(0) + "%"
                : "0%"}
            </td>
            {renderMissing(totalYTD, getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%"))}
            {renderSubtotal(getHalfSubtotal(1, "90%") + getHalfSubtotal(2, "90%"))}
            {renderSubtotal(getHalfSubtotal(1, "75%") + getHalfSubtotal(2, "75%"))}
          </tr>

          {/* Quarters */}
          {[1, 3, 6, 9].map((quarterIndex, idx) => {
            const totalQ = objetivosPorMes(rol, id)
              .slice(idx * 3, (idx + 1) * 3)
              .reduce((total, monto) => total + (monto || 0), 0);
            const totalQ100 = getQuarterSubtotal(quarterIndex, "100%");
            const totalQ90 = getQuarterSubtotal(quarterIndex, "90%");
            const totalQ75 = getQuarterSubtotal(quarterIndex, "75%");

            return (
              <tr key={`q${quarterIndex}`}>
                <th className="col-mes text-start">
                Q{idx + 1}
                </th>
                <td className="text-end">
                  {moneda(pais)}
                  {totalQ.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="text-end">
                  {moneda(pais)}
                  {totalQ100 !== undefined
                    ? totalQ100.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                    : "0"}
                </td>
                <td className="text-center">
                  {totalQ !== 0 ? ((totalQ100 / totalQ) * 100).toFixed(0) + "%" : "0%"}
                </td>
                <td className="text-end">
                  {moneda(pais)}
                  {totalQ.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="text-center">
                  {totalQ !== 0 ? ((totalQ100 / totalQ) * 100).toFixed(0) + "%" : "0%"}
                </td>
                {renderMissing(totalQ, totalQ100)}
                {renderSubtotal(totalQ90)}
                {renderSubtotal(totalQ75)}
              </tr>
            );
          })}

          {/* Halves */}
          {[1, 2].map((halfIndex) => {
            const totalH =
              halfIndex === 1
                ? objetivosPorMes(rol, id)
                  .slice(0, 6)
                  .reduce((total, monto) => total + (monto || 0), 0)
                : objetivosPorMes(rol, id)
                  .slice(6)
                  .reduce((total, monto) => total + (monto || 0), 0);
            const totalH100 = getHalfSubtotal(halfIndex, "100%");
            const totalH90 = getHalfSubtotal(halfIndex, "90%");
            const totalH75 = getHalfSubtotal(halfIndex, "75%");

            return (
              <tr key={`h${halfIndex}`} className="table-dark">
                <th scope="row">
                  H{halfIndex}
                </th>
                <td className="col-objetivo text-end">
                  {moneda(pais)}
                  {totalH.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="text-end">
                  {moneda(pais)}
                  {totalH100 !== undefined
                    ? totalH100.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                    : "0"}
                </td>
                <td className="text-center">
                  {totalH !== 0 ? ((totalH100 / totalH) * 100).toFixed(0) + "%" : "0%"}
                </td>
                <td className="text-end">
                  {moneda(pais)}
                  {totalH.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="text-center">
                  {totalH !== 0 ? ((totalH100 / totalH) * 100).toFixed(0) + "%" : "0%"}
                </td>
                {renderMissing(totalH, totalH100)}
                {renderSubtotal(totalH90)}
                {renderSubtotal(totalH75)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
