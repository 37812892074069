/* eslint-disable no-console */
/* eslint-disable unicorn/prefer-module */
import { useEffect, useState, useMemo } from "react";
import { Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos, getCategorias } from "../../utils";
import "./stylesVetos.scss";

function Vetos() {
  const [edificios, setEdificios] = useState();
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(""); // Estado para la categoría seleccionada
  const { pais } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios);
      const newCategorias = await getCategorias();
      setCategorias(newCategorias);
    };

    initialize();
  }, [pais]);

  // Filtra los edificios según la categoría seleccionada
  const filteredEdificios = useMemo(() => {
    if (!edificios || !categorias) return [];
    return [...edificios]
      .filter(({ vetos }) => {
        if (!selectedCategoria) return true; // Mostrar todos si no hay categoría seleccionada
        const selectedCat = categorias.find((cat) => cat.nombre === selectedCategoria);
        if (!selectedCat) return false;
        if (!vetos) return false; // No vetos, exclude from filter
        if (typeof vetos === "number") {
          return vetos === selectedCat.id;
        } else if (typeof vetos === "string") {
          // Parse vetos string (e.g., "35, 36, 44, 8") into an array of IDs
          const vetoIds = vetos
            .split(",")
            .map((id) => parseInt(id.trim(), 10))
            .filter((id) => !isNaN(id));
          return vetoIds.includes(selectedCat.id);
        }
        return false; // Default case for unexpected format
      })
      .sort((a, b) => a.edificio.localeCompare(b.edificio));
  }, [edificios, selectedCategoria, categorias]);

  // Helper function to convert vetos to category names
  const getVetoNames = (vetos) => {
    if (!vetos) return "-";
    if (typeof vetos === "number") {
      const category = categorias.find((cat) => cat.id === vetos);
      return category ? category.nombre : `ID ${vetos} (sin categoría)`;
    } else if (typeof vetos === "string") {
      // Parse vetos string (e.g., "35, 36, 44, 8") into an array of IDs
      const vetoIds = vetos
        .split(",")
        .map((id) => parseInt(id.trim(), 10))
        .filter((id) => !isNaN(id));
      return vetoIds
        .map((vetoId) => {
          const category = categorias.find((cat) => cat.id === vetoId);
          return category ? category.nombre : `ID ${vetoId} (sin categoría)`;
        })
        .join(", ");
    }
    return vetos.toString(); // Fallback for unexpected formats
  };

  return (
    <div className="listado-b2b-vetos">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      {edificios ? (
        <>
          {/* Dropdown centrado con ancho al 50% */}
          <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: "20px" }}>
            <select
              id="categoriasDropdown"
              className="form-select"
              value={selectedCategoria}
              onChange={(e) => setSelectedCategoria(e.target.value)}
              style={{
                width: "20%", // El dropdown ocupa el 20% del contenedor (ajustado para mayor legibilidad)
                maxWidth: "50%", // Asegura que no se expanda más del 50%
                padding: "8px",
                fontSize: "1rem",
                textAlign: "start",
              }}
            >
              <option value="">Seleccionar Categoría</option>
              {categorias.map((categoria) => (
                <option key={categoria.id} value={categoria.nombre}>
                  {categoria.nombre}
                </option>
              ))}
            </select>
          </div>

          {/* Tabla con estilo personalizado */}
          <div className="table-responsive mx-auto">
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="table-dark align-middle">
                <tr>
                  <th scope="col" style={{ width: "20%" }}>
                    EDIFICIO
                  </th>
                  <th scope="col" className="text-center" style={{ width: "80%" }}>
                    VETO
                  </th>
                </tr>
              </thead>
              <tbody className="table-striped align-middle">
                {filteredEdificios.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No hay vetos en los edificios según la categoría seleccionada :)
                    </td>
                  </tr>
                ) : (
                  filteredEdificios.map(({ id, edificio, vetos }) => (
                    <tr key={id}>
                      <td className="text-start">{edificio.toUpperCase()}</td>
                      <td className="text-center">{getVetoNames(vetos)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Vetos;
