import trebuchetRegular from "../../assets/fonts/TREBUC.TTF";
import trebuchetBold from "../../assets/fonts/TREBUCBD.TTF";
import trebuchetBoldItalic from "../../assets/fonts/TREBUCBI.TTF";
import trebuchetItalic from "../../assets/fonts/TREBUCIT.TTF";
import { Font, StyleSheet } from "@react-pdf/renderer";

// Registrar la fuente Trebuchet con las diferentes variantes
Font.register({
  family: "Trebuchet",
  fonts: [
    {
      src: trebuchetRegular, // Regular
    },
    {
      src: trebuchetBold, // Bold
      fontWeight: "bold",
    },
    {
      src: trebuchetItalic, // Italic
      fontStyle: "italic",
    },
    {
      src: trebuchetBoldItalic, // Bold Italic
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

// Crear los estilos con la fuente Trebuchet
const styles = StyleSheet.create({
  page: {
    padding: 5, // Reduced padding to save space
    fontFamily: "Trebuchet", // Usar la familia Trebuchet
    backgroundColor: "#F5F5F5", // Gris claro
    flexDirection: "column", // Distribute content vertically
    justifyContent: "flex-start", // Align content to the top to eliminate white space
    width: 1000, // Maintain wider custom width
    height: "auto", // Ensure height adjusts exactly to content
  },

  contentContainer: {
    flex: 1, // Allow content to take up available space
    overflow: "hidden", // Prevent overflow
    width: "100%", // Ensure full width
    justifyContent: "flex-start", // Align content to the top
    height: "100%", // Ensure content fills the page height
    padding: 0, // Remove padding to eliminate white space
  },
  table: {
    display: "table",
    marginHorizontal: "auto",
    marginBottom: 5,
    width: "100%", // Ensure full width of the page
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 3,
    textAlign: "center",
    color: "red",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 5, // Reduced margin
    marginBottom: 5, // Reduced margin
    width: "100%", // Ensure full width
  },
  tableRow: {
    flexDirection: "row",
    minHeight: 15, // Reduced row height for tighter packing
  },
  tableHeaderTipo: {
    alignItems: "center", // Centra verticalmente
    backgroundColor: "#FF8C00", // Naranja más oscuro
    color: "#ffffff", // Letras blancas
    display: "flex", // Asegura que el contenedor use flexbox
    fontSize: 10,
    height: 20,
    justifyContent: "center", // Centra horizontalmente
    marginHorizontal: "auto",
    marginBottom: 5, // Reduced margin further
    textAlign: "center", // Asegura que el texto esté centrado
    width: "25%", // Maintain relative width within full table
    borderRadius: 5, // Esquinas redondeadas
  },
  tableHeader: {
    fontSize: 9,
    backgroundColor: "#000000", // Fondo negro
    color: "#ffffff", // Letras blancas
    textAlign: "center",
  },
  tableHeaderRed: {
    backgroundColor: "#FF0000", // Fondo rojo
    color: "#ffffff", // Letras blancas
    fontSize: 9,
    textAlign: "center",
  },
  tableHeaderGreen: {
    backgroundColor: "#008000", // Fondo verde
    color: "#ffffff", // Letras blancas
    fontSize: 9,
    textAlign: "center",
  },
  tableHeaderBlue: {
    backgroundColor: "#0000FF", // Fondo azul
    color: "#ffffff", // Letras blancas
    fontSize: 9,
    textAlign: "center",
  },
  tableCell: {
    width: "20%", // Adjusted to sum to 100% with other cells
    textAlign: "center",
    padding: 3, // Reduced padding
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  tableCellQuote: {
    width: "22%", // Adjusted to sum to 100% with other cells
    textAlign: "center",
    padding: 3, // Reduced padding
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  tableCellSmall: {
    width: "18%", // Adjusted to sum to 100% with other cells
    textAlign: "center",
    padding: 3, // Reduced padding
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  tableCellStart: {
    width: "20%", // Adjusted to sum to 100% with other cells
    textAlign: "start",
    padding: 3, // Reduced padding
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  lastCell: {
    width: "20%", // Adjusted to sum to 100% with other cells
  },
  tableData: {
    fontSize: 8, // Smaller font to save space
    backgroundColor: "#f8f9fa",
  },
  tableDataTotales: {
    fontSize: 8,
    backgroundColor: "#666666", // Fondo gris intermedio
    color: "white", // Texto blanco para que sea visible sobre fondo negro
  },
  letrachica0: {
    fontSize: 10,
    width: "100%", // Ensure full width
    margin: "auto",
    marginTop: 5, // Reduced margin
    marginBottom: 5, // Reduced margin
    textAlign: "center",
  },
  letrachica1: {
    fontSize: 10,
    width: "100%", // Ensure full width
    margin: "auto",
    marginTop: 5, // Reduced margin
    marginBottom: 5, // Reduced margin
    textAlign: "center",
  },
  letrachica2: {
    fontSize: 8, // Reduced font size to save space
    width: "100%", // Ensure full width
    margin: "auto",
    marginTop: 5, // Reduced margin
    marginBottom: 5, // Reduced margin
    textAlign: "center",
  },
  hr: {
    borderBottomColor: "#000", // Color de la línea
    borderBottomWidth: 0.5, // Ancho de la línea
    margin: "auto",
    marginVertical: 5, // Espacio arriba y abajo de la línea
    width: "100%", // Ensure full width
  },
  horario: {
    marginHorizontal: "auto",
    marginTop: 5, // Reduced margin
    width: "100%", // Ensure full width
  },
  horarioTitulo: {
    fontSize: 12, // Reduced font size
    marginBottom: 5, // Reduced margin
  },
  horariotext: {
    fontSize: 10, // Reduced font size
    marginBottom: 5, // Reduced margin
    marginLeft: 10,
  },
  inventario: {
    marginHorizontal: "auto",
    marginTop: 5, // Reduced margin
    marginBottom: 0, // Remove bottom margin to eliminate white space
    width: "100%", // Ensure full width
  },

  inventarioTitulo: {
    fontSize: 12, // Reduced font size
    marginBottom: 5, // Reduced margin
    marginLeft: 10, // Add left margin for hierarchy
  },

  inventariosubTitulo: {
    fontSize: 10, // Reduced font size
    marginBottom: 5, // Reduced margin
    marginLeft: 20, // Add left margin for hierarchy (bullet points)
  },

  inventarioNombres: {
    fontSize: 8, // Smaller font for long lists
    marginBottom: 2, // Reduced margin
    marginLeft: 40, // Add left margin for tab effect
    lineHeight: 1.2, // Tighter line spacing for wrapped text
    width: "100%", // Ensure full width for text wrapping
    textAlign: "justify", // Distribute text evenly across the width
  },

  inventarioNombresLast: {
    fontSize: 8, // Smaller font for long lists
    marginBottom: 2, // Reduced margin
    marginLeft: 40, // Add left margin for tab effect
    lineHeight: 1.2, // Tighter line spacing for wrapped text
    width: "100%", // Ensure full width for text wrapping
    textAlign: "justify", // Distribute text evenly across the width
  },

  image: {
    marginTop: 5, // Reduced margin
    marginBottom: 5, // Reduced margin
    marginHorizontal: "auto",
    width: 69, // Ancho fijo en unidades PDF
    height: "auto", // Mantiene la proporción de la imagen
  },
  mail: {
    fontSize: 10,
    textAlign: "center",
    width: "100%", // Ensure full width
  },
  footer: {
    marginTop: 0, // Remove top margin to eliminate white space
    marginBottom: 0, // Remove bottom margin to eliminate white space
    width: "100%", // Ensure full width
    padding: 0, // Remove padding to eliminate white space
  },
});

export default styles;
