/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./stylesCotizadorProgrammaticInventario.scss";

export const CotizadorProgrammaticInventario = ({
  onSubmit,
  isProgrammaticSelected,
  resetInventario,
  onResetComplete,
}) => {
  const [, setAudienciaSeleccionadaTotal] = useState(0);
  const [ascensoresSeleccionados, setAscensoresSeleccionados] = useState(0);
  const [audienciaEdificiosAscensores, setAudienciaEdificiosAscensores] = useState(new Set());
  const [audienciaEdificiosCowork, setAudienciaEdificiosCowork] = useState(new Set());
  const [audienciaEdificiosEspera, setAudienciaEdificiosEspera] = useState(new Set());
  const [audienciaEdificiosTotems, setAudienciaEdificiosTotems] = useState(new Set());
  const [audienciaSeleccionadaAscensor, setAudienciaSeleccionadaAscensor] = useState(0);
  const [audienciaSeleccionadaCowork, setAudienciaSeleccionadaCowork] = useState(0);
  const [audienciaSeleccionadaEspera, setAudienciaSeleccionadaEspera] = useState(0);
  const [audienciaSeleccionadaTotems, setAudienciaSeleccionadaTotems] = useState(0);
  const [coworkSeleccionados, setCoworkSeleccionados] = useState(0);
  const [edificios, setEdificios] = useState([]);
  const [edificiosSeleccionados, setEdificiosSeleccionados] = useState(new Set());
  const [esperaSeleccionados, setEsperaSeleccionados] = useState(0);
  const [pantallasPorUbicacionMap, setPantallasPorUbicacionMap] = useState({});
  const [selectAllAscensores, setSelectAllAscensores] = useState(false);
  const [selectAllCowork, setSelectAllCowork] = useState(false);
  const [selectAllEspera, setSelectAllEspera] = useState(false);
  const [selectAllNetwork, setSelectAllNetwork] = useState(false);
  const [selectAllTotems, setSelectAllTotems] = useState(false);
  const [selectAllEdificios, setSelectAllEdificios] = useState(false); // Nuevo estado para seleccionar todos los edificios
  const [selectInventory, setSelectInventory] = useState(false);
  const [totemsSeleccionados, setTotemsSeleccionados] = useState(0);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [ubicacionesSeleccionadas, setUbicacionesSeleccionadas] = useState(new Set());

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      let newEdificios = await getEdificiosOperativos({ pais });

      // Filtrar solo edificios con estado 'OPERATIVO'
      newEdificios = newEdificios.filter((edificio) => edificio.estado === "OPERATIVO");

      if (isProgrammaticSelected) {
        newEdificios = newEdificios.filter((edificio) => edificio.programatica === "SÍ");
      }

      newEdificios.sort((a, b) => {
        if (a.edificio && b.edificio) {
          return a.edificio.localeCompare(b.edificio);
        }
        return 0;
      });

      const uniqueUbicaciones = [...new Set(newEdificios.map((edificio) => edificio.ubicacion))];
      setUbicaciones(uniqueUbicaciones);

      const pantallasMap = {};
      uniqueUbicaciones.forEach((ubicacion) => {
        pantallasMap[ubicacion] = newEdificios.reduce(
          (suma, edificio) =>
            edificio.ubicacion === ubicacion
              ? suma +
                (edificio.pantallas || 0) +
                (edificio.totems || 0) +
                (edificio.cowork || 0) +
                (edificio.espera || 0)
              : suma,
          0
        );
      });

      setPantallasPorUbicacionMap(pantallasMap);
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais, isProgrammaticSelected]);

  console.log("edificios", edificios);

  const updateAudienciaAscensor = useCallback(() => {
    let newAudienciaAscensor = 0;

    audienciaEdificiosAscensores.forEach((id) => {
      const edificio = edificios.find((e) => e.id === id && e.pantallas > 0);
      if (edificio) {
        newAudienciaAscensor += edificio.personas;
      }
    });

    setAudienciaSeleccionadaAscensor(newAudienciaAscensor);
    updateAudienciaTotal();
  }, [audienciaEdificiosAscensores, edificios]);

  useEffect(() => {
    updateAudienciaAscensor();
  }, [audienciaEdificiosAscensores]);

  useEffect(() => {
    if (resetInventario) {
      setAscensoresSeleccionados(0);
      setTotemsSeleccionados(0);
      setCoworkSeleccionados(0);
      setEsperaSeleccionados(0);
      setAudienciaEdificiosAscensores(new Set());
      setAudienciaEdificiosTotems(new Set());
      setAudienciaEdificiosCowork(new Set());
      setAudienciaSeleccionadaAscensor(0);
      setAudienciaSeleccionadaTotems(0);
      setAudienciaSeleccionadaCowork(0);
      setEdificiosSeleccionados(new Set());
      setSelectAllAscensores(false);
      setSelectAllTotems(false);
      setSelectAllCowork(false);
      setSelectAllNetwork(false);
      setSelectAllEdificios(false); // Resetear el nuevo estado
      setSelectInventory(false);
      setUbicacionesSeleccionadas(new Set());

      // eslint-disable-next-line prettier/prettier
      const uncheckAll = document.querySelectorAll(".inventario input[type=\"checkbox\"]");
      uncheckAll.forEach((checkbox) => {
        checkbox.checked = false;
      });

      onResetComplete();
    }
  }, [isProgrammaticSelected, resetInventario, onResetComplete]);

  const updateAudienciaTotal = useCallback(() => {
    const ascensoresIds = new Set(
      [...audienciaEdificiosAscensores].filter((id) => {
        const edificio = edificios.find((e) => e.id === id);
        return edificio && edificio.pantallas > 0;
      })
    );

    const totemsIds = new Set(
      [...audienciaEdificiosTotems].filter((id) => {
        const edificio = edificios.find((e) => e.id === id);
        return edificio && edificio.totems > 0;
      })
    );

    const coworkIds = new Set(
      [...audienciaEdificiosCowork].filter((id) => {
        const edificio = edificios.find((e) => e.id === id);
        return edificio && edificio.cowork > 0;
      })
    );

    const esperaIds = new Set(
      [...audienciaEdificiosEspera].filter((id) => {
        const edificio = edificios.find((e) => e.id === id);
        return edificio && edificio.espera > 0;
      })
    );

    const allUniqueIds = new Set([...ascensoresIds, ...totemsIds, ...coworkIds, ...esperaIds]);
    let totalAudiencia = 0;

    allUniqueIds.forEach((id) => {
      const edificio = edificios.find((e) => e.id === id);
      if (edificio) {
        totalAudiencia += edificio.personas;
      }
    });

    setAudienciaSeleccionadaTotal(totalAudiencia);

    onSubmit({
      ascensoresSeleccionados,
      audienciaSeleccionadaAscensor,
      audienciaSeleccionadaCowork,
      audienciaSeleccionadaTotems,
      audienciaSeleccionadaEspera,
      coworkSeleccionados,
      esperaSeleccionados,
      audienciaSeleccionadaTotal: totalAudiencia,
      edificiosSeleccionadosAscensores: [...ascensoresIds],
      edificiosSeleccionadosTotems: [...totemsIds],
      edificiosSeleccionadosCowork: [...coworkIds],
      edificiosSeleccionadosEspera: [...esperaIds],
      totemsSeleccionados,
    });
  }, [
    ascensoresSeleccionados,
    audienciaSeleccionadaAscensor,
    audienciaSeleccionadaCowork,
    audienciaSeleccionadaTotems,
    audienciaSeleccionadaEspera,
    coworkSeleccionados,
    esperaSeleccionados,
    audienciaEdificiosAscensores,
    audienciaEdificiosTotems,
    audienciaEdificiosCowork,
    audienciaEdificiosEspera,
    edificios,
    onSubmit,
    totemsSeleccionados,
  ]);

  useEffect(() => {
    updateAudienciaTotal();
  }, [audienciaEdificiosAscensores, audienciaEdificiosTotems, audienciaEdificiosCowork]);

  const handleCheckboxChange = (pantallas, isChecked, tipo, personas, id, totems = 0, cowork = 0, espera = 0) => {
    if (selectAllNetwork) return;

    const newEdificiosSeleccionados = new Set(edificiosSeleccionados);
    const edificio = edificios.find((e) => e.id === id);
    const isSelectedByLocation = [...ubicacionesSeleccionadas].includes(edificio.ubicacion);

    if (isSelectedByLocation) return;

    if (isChecked) {
      newEdificiosSeleccionados.add(id);
    } else {
      newEdificiosSeleccionados.delete(id);
    }

    let updatedAscensores = ascensoresSeleccionados;
    let updatedTotems = totemsSeleccionados;
    let updatedCowork = coworkSeleccionados;
    let updatedEspera = esperaSeleccionados;
    let updatedAudienciaAscensor = audienciaSeleccionadaAscensor;
    let updatedAudienciaTotems = audienciaSeleccionadaTotems;
    let updatedAudienciaCowork = audienciaSeleccionadaCowork;
    let updatedAudienciaEspera = audienciaSeleccionadaEspera;
    const updatedAudienciaEdificiosAscensores = new Set(audienciaEdificiosAscensores);
    const updatedAudienciaEdificiosTotems = new Set(audienciaEdificiosTotems);
    const updatedAudienciaEdificiosCowork = new Set(audienciaEdificiosCowork);
    const updatedAudienciaEdificiosEspera = new Set(audienciaEdificiosEspera);

    if (tipo === "edificios") {
      // Actualizar todos los tipos de pantallas si se selecciona un edificio completo
      if (edificio.pantallas > 0) {
        updatedAscensores = isChecked
          ? ascensoresSeleccionados + pantallas
          : Math.max(ascensoresSeleccionados - pantallas, 0);
        updatedAudienciaAscensor = isChecked
          ? audienciaSeleccionadaAscensor + personas
          : Math.max(audienciaSeleccionadaAscensor - personas, 0);
        if (isChecked) updatedAudienciaEdificiosAscensores.add(id);
        else updatedAudienciaEdificiosAscensores.delete(id);
      }
      if (edificio.totems > 0) {
        updatedTotems = isChecked ? totemsSeleccionados + totems : Math.max(totemsSeleccionados - totems, 0);
        updatedAudienciaTotems = isChecked
          ? audienciaSeleccionadaTotems + personas
          : Math.max(audienciaSeleccionadaTotems - personas, 0);
        if (isChecked) updatedAudienciaEdificiosTotems.add(id);
        else updatedAudienciaEdificiosTotems.delete(id);
      }
      if (edificio.cowork > 0) {
        updatedCowork = isChecked ? coworkSeleccionados + cowork : Math.max(coworkSeleccionados - cowork, 0);
        updatedAudienciaCowork = isChecked
          ? audienciaSeleccionadaCowork + personas
          : Math.max(audienciaSeleccionadaCowork - personas, 0);
        if (isChecked) updatedAudienciaEdificiosCowork.add(id);
        else updatedAudienciaEdificiosCowork.delete(id);
      }
      if (edificio.espera > 0) {
        updatedEspera = isChecked ? esperaSeleccionados + espera : Math.max(esperaSeleccionados - espera, 0);
        updatedAudienciaEspera = isChecked
          ? audienciaSeleccionadaEspera + personas
          : Math.max(audienciaSeleccionadaEspera - personas, 0);
        if (isChecked) updatedAudienciaEdificiosEspera.add(id);
        else updatedAudienciaEdificiosEspera.delete(id);
      }
    } else if (tipo === "ascensores" && edificio.pantallas > 0) {
      updatedAscensores = isChecked
        ? ascensoresSeleccionados + pantallas
        : Math.max(ascensoresSeleccionados - pantallas, 0);
      updatedAudienciaAscensor = isChecked
        ? audienciaSeleccionadaAscensor + personas
        : Math.max(audienciaSeleccionadaAscensor - personas, 0);
      if (isChecked) updatedAudienciaEdificiosAscensores.add(id);
      else updatedAudienciaEdificiosAscensores.delete(id);
    } else if (tipo === "totems" && edificio.totems > 0) {
      updatedTotems = isChecked ? totemsSeleccionados + pantallas : Math.max(totemsSeleccionados - pantallas, 0);
      updatedAudienciaTotems = isChecked
        ? audienciaSeleccionadaTotems + personas
        : Math.max(audienciaSeleccionadaTotems - personas, 0);
      if (isChecked) updatedAudienciaEdificiosTotems.add(id);
      else updatedAudienciaEdificiosTotems.delete(id);
    } else if (tipo === "cowork" && edificio.cowork > 0) {
      updatedCowork = isChecked ? coworkSeleccionados + pantallas : Math.max(coworkSeleccionados - pantallas, 0);
      updatedAudienciaCowork = isChecked
        ? audienciaSeleccionadaCowork + personas
        : Math.max(audienciaSeleccionadaCowork - personas, 0);
      if (isChecked) updatedAudienciaEdificiosCowork.add(id);
      else updatedAudienciaEdificiosCowork.delete(id);
    } else if (tipo === "espera" && edificio.espera > 0) {
      updatedEspera = isChecked ? esperaSeleccionados + pantallas : Math.max(esperaSeleccionados - pantallas, 0);
      updatedAudienciaEspera = isChecked
        ? audienciaSeleccionadaEspera + personas
        : Math.max(audienciaSeleccionadaEspera - personas, 0);
      if (isChecked) updatedAudienciaEdificiosEspera.add(id);
      else updatedAudienciaEdificiosEspera.delete(id);
    }

    setAscensoresSeleccionados(updatedAscensores);
    setTotemsSeleccionados(updatedTotems);
    setCoworkSeleccionados(updatedCowork);
    setEsperaSeleccionados(updatedEspera);
    setAudienciaSeleccionadaAscensor(updatedAudienciaAscensor);
    setAudienciaSeleccionadaTotems(updatedAudienciaTotems);
    setAudienciaSeleccionadaCowork(updatedAudienciaCowork);
    setAudienciaSeleccionadaEspera(updatedAudienciaEspera);
    setAudienciaEdificiosAscensores(updatedAudienciaEdificiosAscensores);
    setAudienciaEdificiosTotems(updatedAudienciaEdificiosTotems);
    setAudienciaEdificiosCowork(updatedAudienciaEdificiosCowork);
    setAudienciaEdificiosEspera(updatedAudienciaEdificiosEspera);
    setEdificiosSeleccionados(newEdificiosSeleccionados);

    onSubmit({
      ascensoresSeleccionados: updatedAscensores,
      audienciaSeleccionadaAscensor: updatedAudienciaAscensor,
      audienciaSeleccionadaCowork: updatedAudienciaCowork,
      audienciaSeleccionadaTotems: updatedAudienciaTotems,
      audienciaSeleccionadaEspera: updatedAudienciaEspera,
      coworkSeleccionados: updatedCowork,
      esperaSeleccionados: updatedEspera,
      audienciaSeleccionadaTotal: edificios.reduce((total, e) => {
        if (
          updatedAudienciaEdificiosAscensores.has(e.id) ||
          updatedAudienciaEdificiosTotems.has(e.id) ||
          updatedAudienciaEdificiosCowork.has(e.id) ||
          updatedAudienciaEdificiosEspera.has(e.id)
        ) {
          return total + e.personas;
        }
        return total;
      }, 0),
      edificiosSeleccionadosAscensores: [...updatedAudienciaEdificiosAscensores],
      edificiosSeleccionadosTotems: [...updatedAudienciaEdificiosTotems],
      edificiosSeleccionadosCowork: [...updatedAudienciaEdificiosCowork],
      edificiosSeleccionadosEspera: [...updatedAudienciaEdificiosEspera],
      totemsSeleccionados: updatedTotems,
    });
  };

  function sumarPantallas(edificios) {
    return edificios.reduce((total, edificio) => total + (edificio.pantallas || 0), 0);
  }

  function sumarTotems(edificios) {
    return edificios.reduce((total, edificio) => total + (edificio.totems || 0), 0);
  }

  function sumarCowork(edificios) {
    return edificios.reduce((total, edificio) => total + (edificio.cowork || 0), 0);
  }

  function sumarEspera(edificios) {
    return edificios.reduce((total, edificio) => total + (edificio.espera || 0), 0);
  }

  function sumarTotalPantallas(edificio) {
    return (edificio.pantallas || 0) + (edificio.totems || 0) + (edificio.cowork || 0) + (edificio.espera || 0);
  }

  const handleSelectAllChange = (isChecked, tipo, ubicacion = null) => {
    const resetState = () => {
      setEdificiosSeleccionados(new Set());
      setAscensoresSeleccionados(0);
      setTotemsSeleccionados(0);
      setCoworkSeleccionados(0);
      setEsperaSeleccionados(0);
      setAudienciaSeleccionadaAscensor(0);
      setAudienciaSeleccionadaTotems(0);
      setAudienciaSeleccionadaCowork(0);
      setAudienciaSeleccionadaEspera(0);
      setAudienciaEdificiosAscensores(new Set());
      setAudienciaEdificiosTotems(new Set());
      setAudienciaEdificiosCowork(new Set());
      setAudienciaEdificiosEspera(new Set());
      setSelectAllAscensores(false);
      setSelectAllTotems(false);
      setSelectAllCowork(false);
      setSelectAllEspera(false);
      setSelectAllEdificios(false); // Resetear el nuevo estado
      setUbicacionesSeleccionadas(new Set());
    };

    let updatedAscensores = ascensoresSeleccionados;
    let updatedTotems = totemsSeleccionados;
    let updatedCowork = coworkSeleccionados;
    let updatedEspera = esperaSeleccionados;
    let updatedAudienciaAscensor = audienciaSeleccionadaAscensor;
    let updatedAudienciaTotems = audienciaSeleccionadaTotems;
    let updatedAudienciaCowork = audienciaSeleccionadaCowork;
    let updatedAudienciaEspera = audienciaSeleccionadaEspera;
    let updatedAudienciaEdificiosAscensores = new Set(audienciaEdificiosAscensores);
    let updatedAudienciaEdificiosTotems = new Set(audienciaEdificiosTotems);
    let updatedAudienciaEdificiosCowork = new Set(audienciaEdificiosCowork);
    let updatedAudienciaEdificiosEspera = new Set(audienciaEdificiosEspera);
    let updatedEdificiosSeleccionados = new Set(edificiosSeleccionados);

    if (tipo === "network") {
      resetState();
      setSelectAllNetwork(isChecked);
      setSelectInventory(false);

      if (isChecked) {
        updatedEdificiosSeleccionados = new Set(edificios.map((e) => e.id));
        updatedAscensores = sumarPantallas(edificios);
        updatedTotems = sumarTotems(edificios);
        updatedCowork = sumarCowork(edificios);
        updatedEspera = sumarEspera(edificios);

        updatedAudienciaAscensor = edificios.reduce(
          (total, edificio) => (edificio.pantallas > 0 ? total + edificio.personas : total),
          0
        );
        updatedAudienciaTotems = edificios.reduce(
          (total, edificio) => (edificio.totems > 0 ? total + edificio.personas : total),
          0
        );
        updatedAudienciaCowork = edificios.reduce(
          (total, edificio) => (edificio.cowork > 0 ? total + edificio.personas : total),
          0
        );
        updatedAudienciaEspera = edificios.reduce(
          (total, edificio) => (edificio.espera > 0 ? total + edificio.personas : total),
          0
        );

        updatedAudienciaEdificiosAscensores = new Set(edificios.filter((e) => e.pantallas > 0).map((e) => e.id));
        updatedAudienciaEdificiosTotems = new Set(edificios.filter((e) => e.totems > 0).map((e) => e.id));
        updatedAudienciaEdificiosCowork = new Set(edificios.filter((e) => e.cowork > 0).map((e) => e.id));
        updatedAudienciaEdificiosEspera = new Set(edificios.filter((e) => e.espera > 0).map((e) => e.id));

        setEdificiosSeleccionados(updatedEdificiosSeleccionados);
        setAscensoresSeleccionados(updatedAscensores);
        setTotemsSeleccionados(updatedTotems);
        setCoworkSeleccionados(updatedCowork);
        setEsperaSeleccionados(updatedEspera);
        setAudienciaSeleccionadaAscensor(updatedAudienciaAscensor);
        setAudienciaSeleccionadaTotems(updatedAudienciaTotems);
        setAudienciaSeleccionadaCowork(updatedAudienciaCowork);
        setAudienciaSeleccionadaEspera(updatedAudienciaEspera);
        setAudienciaEdificiosAscensores(updatedAudienciaEdificiosAscensores);
        setAudienciaEdificiosTotems(updatedAudienciaEdificiosTotems);
        setAudienciaEdificiosCowork(updatedAudienciaEdificiosCowork);
        setAudienciaEdificiosEspera(updatedAudienciaEdificiosEspera);
        setSelectAllAscensores(true);
        setSelectAllTotems(true);
        setSelectAllCowork(true);
        setSelectAllEspera(true);
        setSelectAllEdificios(true); // Seleccionar todos los edificios también
      }
    } else if (tipo === "inventory") {
      resetState();
      setSelectInventory(isChecked);
      setSelectAllNetwork(false);
    } else if (selectInventory) {
      if (tipo === "ubicacion" && ubicacion) {
        if (isChecked) {
          setUbicacionesSeleccionadas((prev) => new Set([...prev, ubicacion]));
          const edificiosDeUbicacion = edificios.filter((edificio) => edificio.ubicacion === ubicacion);
          edificiosDeUbicacion.forEach((edificio) => {
            updatedEdificiosSeleccionados.add(edificio.id);
            if (edificio.pantallas > 0) {
              updatedAscensores += edificio.pantallas;
              updatedAudienciaAscensor += edificio.personas;
              updatedAudienciaEdificiosAscensores.add(edificio.id);
            }
            if (edificio.totems > 0) {
              updatedTotems += edificio.totems;
              updatedAudienciaTotems += edificio.personas;
              updatedAudienciaEdificiosTotems.add(edificio.id);
            }
            if (edificio.cowork > 0) {
              updatedCowork += edificio.cowork;
              updatedAudienciaCowork += edificio.personas;
              updatedAudienciaEdificiosCowork.add(edificio.id);
            }
            if (edificio.espera > 0) {
              updatedEspera += edificio.espera;
              updatedAudienciaEspera += edificio.personas;
              updatedAudienciaEdificiosEspera.add(edificio.id);
            }
          });
        } else {
          setUbicacionesSeleccionadas((prev) => {
            const newSet = new Set(prev);
            newSet.delete(ubicacion);
            return newSet;
          });
          const edificiosDeUbicacion = edificios.filter((edificio) => edificio.ubicacion === ubicacion);
          edificiosDeUbicacion.forEach((edificio) => {
            if (edificio.pantallas > 0) {
              updatedAscensores = Math.max(updatedAscensores - edificio.pantallas, 0);
              updatedAudienciaAscensor = Math.max(updatedAudienciaAscensor - edificio.personas, 0);
              updatedAudienciaEdificiosAscensores.delete(edificio.id);
            }
            if (edificio.totems > 0) {
              updatedTotems = Math.max(updatedTotems - edificio.totems, 0);
              updatedAudienciaTotems = Math.max(updatedAudienciaTotems - edificio.personas, 0);
              updatedAudienciaEdificiosTotems.delete(edificio.id);
            }
            if (edificio.cowork > 0) {
              updatedCowork = Math.max(updatedCowork - edificio.cowork, 0);
              updatedAudienciaCowork = Math.max(updatedAudienciaCowork - edificio.personas, 0);
              updatedAudienciaEdificiosCowork.delete(edificio.id);
            }
            if (edificio.espera > 0) {
              updatedEspera = Math.max(updatedEspera - edificio.espera, 0);
              updatedAudienciaEspera = Math.max(updatedAudienciaEspera - edificio.personas, 0);
              updatedAudienciaEdificiosEspera.delete(edificio.id);
            }
            updatedEdificiosSeleccionados.delete(edificio.id);
          });
        }
      } else if (tipo === "edificios") {
        updatedEdificiosSeleccionados = isChecked ? new Set(edificios.map((e) => e.id)) : new Set();
        updatedAscensores = isChecked ? sumarPantallas(edificios) : 0;
        updatedTotems = isChecked ? sumarTotems(edificios) : 0;
        updatedCowork = isChecked ? sumarCowork(edificios) : 0;
        updatedEspera = isChecked ? sumarEspera(edificios) : 0;
        updatedAudienciaAscensor = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.pantallas > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaTotems = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.totems > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaCowork = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.cowork > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaEspera = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.espera > 0 ? edificio.personas : 0), 0)
          : 0;

        updatedAudienciaEdificiosAscensores = isChecked
          ? new Set(edificios.filter((e) => e.pantallas > 0).map((e) => e.id))
          : new Set();
        updatedAudienciaEdificiosTotems = isChecked
          ? new Set(edificios.filter((e) => e.totems > 0).map((e) => e.id))
          : new Set();
        updatedAudienciaEdificiosCowork = isChecked
          ? new Set(edificios.filter((e) => e.cowork > 0).map((e) => e.id))
          : new Set();
        updatedAudienciaEdificiosEspera = isChecked
          ? new Set(edificios.filter((e) => e.espera > 0).map((e) => e.id))
          : new Set();

        setSelectAllEdificios(isChecked);
      } else if (tipo === "ascensores") {
        updatedAscensores = isChecked ? sumarPantallas(edificios) : 0;
        updatedAudienciaAscensor = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.pantallas > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaEdificiosAscensores = isChecked
          ? new Set(edificios.filter((e) => e.pantallas > 0).map((e) => e.id))
          : new Set();
        setSelectAllAscensores(isChecked);
      } else if (tipo === "totems") {
        updatedTotems = isChecked ? sumarTotems(edificios) : 0;
        updatedAudienciaTotems = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.totems > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaEdificiosTotems = isChecked
          ? new Set(edificios.filter((e) => e.totems > 0).map((e) => e.id))
          : new Set();
        setSelectAllTotems(isChecked);
      } else if (tipo === "cowork") {
        updatedCowork = isChecked ? sumarCowork(edificios) : 0;
        updatedAudienciaCowork = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.cowork > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaEdificiosCowork = isChecked
          ? new Set(edificios.filter((e) => e.cowork > 0).map((e) => e.id))
          : new Set();
        setSelectAllCowork(isChecked);
      } else if (tipo === "espera") {
        updatedEspera = isChecked ? sumarEspera(edificios) : 0;
        updatedAudienciaEspera = isChecked
          ? edificios.reduce((total, edificio) => total + (edificio.espera > 0 ? edificio.personas : 0), 0)
          : 0;
        updatedAudienciaEdificiosEspera = isChecked
          ? new Set(edificios.filter((e) => e.espera > 0).map((e) => e.id))
          : new Set();
        setSelectAllEspera(isChecked);
      }

      setEdificiosSeleccionados(updatedEdificiosSeleccionados);
      setAscensoresSeleccionados(updatedAscensores);
      setTotemsSeleccionados(updatedTotems);
      setCoworkSeleccionados(updatedCowork);
      setEsperaSeleccionados(updatedEspera);
      setAudienciaSeleccionadaAscensor(updatedAudienciaAscensor);
      setAudienciaSeleccionadaTotems(updatedAudienciaTotems);
      setAudienciaSeleccionadaCowork(updatedAudienciaCowork);
      setAudienciaSeleccionadaEspera(updatedAudienciaEspera);
      setAudienciaEdificiosAscensores(updatedAudienciaEdificiosAscensores);
      setAudienciaEdificiosTotems(updatedAudienciaEdificiosTotems);
      setAudienciaEdificiosCowork(updatedAudienciaEdificiosCowork);
      setAudienciaEdificiosEspera(updatedAudienciaEdificiosEspera);

      onSubmit({
        ascensoresSeleccionados: updatedAscensores,
        audienciaSeleccionadaAscensor: updatedAudienciaAscensor,
        audienciaSeleccionadaCowork: updatedAudienciaCowork,
        audienciaSeleccionadaTotems: updatedAudienciaTotems,
        audienciaSeleccionadaEspera: updatedAudienciaEspera,
        coworkSeleccionados: updatedCowork,
        esperaSeleccionados: updatedEspera,
        audienciaSeleccionadaTotal: edificios.reduce((total, e) => {
          if (
            updatedAudienciaEdificiosAscensores.has(e.id) ||
            updatedAudienciaEdificiosTotems.has(e.id) ||
            updatedAudienciaEdificiosCowork.has(e.id) ||
            updatedAudienciaEdificiosEspera.has(e.id)
          ) {
            return total + e.personas;
          }
          return total;
        }, 0),
        edificiosSeleccionadosAscensores: [...updatedAudienciaEdificiosAscensores],
        edificiosSeleccionadosTotems: [...updatedAudienciaEdificiosTotems],
        edificiosSeleccionadosCowork: [...updatedAudienciaEdificiosCowork],
        edificiosSeleccionadosEspera: [...updatedAudienciaEdificiosEspera],
        totemsSeleccionados: updatedTotems,
      });
    }
  };

  return (
    <div className="inventario">
      <p className="tituloinventario">INVENTARIO</p>
      <hr />

      <div className="seleccion">
        <input
          type="checkbox"
          className="cb123"
          checked={selectAllNetwork}
          onChange={(e) => handleSelectAllChange(e.target.checked, "network")}
          data-type="network"
        />
        <span>TODA LA RED</span>
      </div>

      <div className="seleccion">
        <input
          type="checkbox"
          className="cb123"
          checked={selectInventory}
          onChange={(e) => handleSelectAllChange(e.target.checked, "inventory")}
          data-type="inventory"
        />
        <span>SELECCIONAR</span>
      </div>
      <hr />

      {selectInventory && !selectAllNetwork && (
        <>
          <details>
            <summary className="subtitulo">EDIFICIOS</summary>
            <hr />
            <div className="checkbox">
              <input
                type="checkbox"
                style={{ width: "15px", height: "15px", marginRight: "10px" }}
                checked={selectAllEdificios}
                onChange={(e) => handleSelectAllChange(e.target.checked, "edificios")}
                data-type="edificios"
              />
              <span style={{ fontWeight: "bold" }}>{edificios.length} | SELECCIONAR TODOS</span>
            </div>
            {!selectAllEdificios && edificios.length > 0 && (
              <div>
                {edificios.map((edificio) => (
                  <div key={edificio.id} className="checkbox">
                    <input
                      type="checkbox"
                      style={{ width: "15px", height: "15px", marginRight: "10px" }}
                      onChange={(e) =>
                        handleCheckboxChange(
                          edificio.pantallas,
                          e.target.checked,
                          "edificios",
                          edificio.personas,
                          edificio.id,
                          edificio.totems,
                          edificio.cowork,
                          edificio.espera
                        )
                      }
                      value={edificio.id}
                      data-type="edificios"
                    />
                    <span>
                      {sumarTotalPantallas(edificio)} | {edificio.edificio.toUpperCase()}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {edificios.length === 0 && !selectAllEdificios && <p>Cargando edificios...</p>}
          </details>
          <hr />

          <details>
            <summary className="subtitulo">UBICACIÓN</summary>
            <hr />
            {ubicaciones.map((ubicacion, index) => (
              <div key={index} className="checkbox">
                <input
                  type="checkbox"
                  style={{ width: "15px", height: "15px", marginRight: "10px" }}
                  checked={ubicacionesSeleccionadas.has(ubicacion)}
                  onChange={(e) => handleSelectAllChange(e.target.checked, "ubicacion", ubicacion)}
                  data-type="ubicacion"
                />
                <span>
                  {pantallasPorUbicacionMap[ubicacion] || 0} | {ubicacion.toUpperCase()}
                </span>
              </div>
            ))}
          </details>
          <hr />

          <details>
            <summary className="subtitulo">ASCENSORES</summary>
            <hr />
            <div className="checkbox">
              <input
                type="checkbox"
                style={{ width: "15px", height: "15px", marginRight: "10px" }}
                checked={selectAllAscensores}
                onChange={(e) => handleSelectAllChange(e.target.checked, "ascensores")}
              />
              <span style={{ fontWeight: "bold" }}>{sumarPantallas(edificios)} | SELECCIONAR TODOS</span>
            </div>
            {!selectAllAscensores && edificios.length > 0 && (
              <div>
                {edificios
                  .filter((edificio) => edificio.pantallas > 0)
                  .map((edificio) => (
                    <div key={edificio.id} className="checkbox">
                      <input
                        type="checkbox"
                        style={{ width: "15px", height: "15px", marginRight: "10px" }}
                        onChange={(e) =>
                          handleCheckboxChange(
                            edificio.pantallas,
                            e.target.checked,
                            "ascensores",
                            edificio.personas,
                            edificio.id
                          )
                        }
                        value={edificio.id}
                        data-type="ascensores"
                      />
                      <span>
                        {edificio.pantallas} | {edificio.edificio.toUpperCase()}
                      </span>
                    </div>
                  ))}
              </div>
            )}
            {edificios.length === 0 && !selectAllAscensores && <p>Cargando edificios...</p>}
          </details>
          <hr />

          {edificios.some((edificio) => edificio.totems > 0) && (
            <details>
              <summary className="subtitulo">CIRCULACIÓN</summary>
              <hr />
              <div className="checkbox">
                <input
                  type="checkbox"
                  style={{ width: "15px", height: "15px", marginRight: "10px" }}
                  checked={selectAllTotems}
                  onChange={(e) => handleSelectAllChange(e.target.checked, "totems")}
                  data-type="totems"
                />
                <span style={{ fontWeight: "bold" }}>{sumarTotems(edificios)} | SELECCIONAR TODOS</span>
              </div>
              {!selectAllTotems && edificios.length > 0 && (
                <div>
                  {edificios
                    .filter((edificio) => edificio.totems > 0)
                    .map((edificio) => (
                      <div key={edificio.id} className="checkbox">
                        <input
                          type="checkbox"
                          style={{ width: "15px", height: "15px", marginRight: "10px" }}
                          onChange={(e) =>
                            handleCheckboxChange(
                              edificio.totems,
                              e.target.checked,
                              "totems",
                              edificio.personas,
                              edificio.id
                            )
                          }
                          value={edificio.id}
                          data-type="totems"
                        />
                        <span>
                          {edificio.totems} | {edificio.edificio.toUpperCase()}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </details>
          )}
          <hr />

          {edificios.some((edificio) => edificio.espera > 0) && (
            <details>
              <summary className="subtitulo">ESPERA</summary>
              <hr />
              <div className="checkbox">
                <input
                  type="checkbox"
                  style={{ width: "15px", height: "15px", marginRight: "10px" }}
                  checked={selectAllEspera}
                  onChange={(e) => handleSelectAllChange(e.target.checked, "espera")}
                  data-type="espera"
                />
                <span style={{ fontWeight: "bold" }}>{sumarEspera(edificios)} | SELECCIONAR TODOS</span>
              </div>
              {!selectAllEspera && edificios.length > 0 && (
                <div>
                  {edificios
                    .filter((edificio) => edificio.espera > 0)
                    .map((edificio) => (
                      <div key={edificio.id} className="checkbox">
                        <input
                          type="checkbox"
                          style={{ width: "15px", height: "15px", marginRight: "10px" }}
                          onChange={(e) =>
                            handleCheckboxChange(
                              edificio.espera,
                              e.target.checked,
                              "espera",
                              edificio.personas,
                              edificio.id
                            )
                          }
                          value={edificio.id}
                          data-type="espera"
                        />
                        <span>
                          {edificio.espera} | {edificio.edificio.toUpperCase()}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </details>
          )}
          <hr />

          {edificios.some((edificio) => edificio.cowork > 0) && (
            <details>
              <summary className="subtitulo">COWORK iF</summary>
              <hr />
              <div className="checkbox">
                <input
                  type="checkbox"
                  style={{ width: "15px", height: "15px", marginRight: "10px" }}
                  checked={selectAllCowork}
                  onChange={(e) => handleSelectAllChange(e.target.checked, "cowork")}
                  data-type="cowork"
                />
                <span style={{ fontWeight: "bold" }}>{sumarCowork(edificios)} | SELECCIONAR TODOS</span>
              </div>
              {!selectAllCowork && edificios.length > 0 && (
                <div>
                  {edificios
                    .filter((edificio) => edificio.cowork > 0)
                    .map((edificio) => (
                      <div key={edificio.id} className="checkbox">
                        <input
                          type="checkbox"
                          style={{ width: "15px", height: "15px", marginRight: "10px" }}
                          onChange={(e) =>
                            handleCheckboxChange(
                              edificio.cowork,
                              e.target.checked,
                              "cowork",
                              edificio.personas,
                              edificio.id
                            )
                          }
                          value={edificio.id}
                          data-type="cowork"
                        />
                        <span>
                          {edificio.cowork} | {edificio.edificio.toUpperCase()}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </details>
          )}
          <hr />
        </>
      )}
    </div>
  );
};
