/* eslint-disable unicorn/prefer-module */
import React, { useMemo } from "react";
import { useAuthContext } from "../../context/auth";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "./stylesPortafolio.scss";

function Portafolio() {
  const { pais } = useAuthContext();
  const newplugin = defaultLayoutPlugin();
  const getFilePluginInstance = getFilePlugin();
  const fullScreenPluginInstance = fullScreenPlugin();

  const portafolio = useMemo(
    () =>
      ({
        Chile: require("../../pdf/portafolio-wecast.pdf"),
        Perú: require("../../pdf/portafolio-visiona.pdf"),
        Uruguay: require("../../pdf/portafolio-visiona.pdf"),
      })[pais] || require("../../pdf/portafolio-visiona.pdf"),
    [pais]
  );

  return (
    <div className="pdf-container">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={portafolio} plugins={[newplugin, getFilePluginInstance, fullScreenPluginInstance]} />;
      </Worker>
    </div>
  );
}

export default Portafolio;
