import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import "./stylesCotizadorPlazo.scss";

export const CotizadorPlazo = ({ onPlazoChange }) => {
  const today = new Date();
  const thirtyDaysLater = new Date(today);
  thirtyDaysLater.setDate(today.getDate() + 30);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [useExactDays, setUseExactDays] = useState(false);
  const [useExactDates, setUseExactDates] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    updateNumberOfDays(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    updateNumberOfDays(startDate, date);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const updateNumberOfDays = (start, end) => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      let days = 0;

      if (isWeekday(startDate)) {
        days++;
      }
      if (isWeekday(endDate)) {
        days++;
      }

      const currentDate = new Date(start);
      currentDate.setDate(currentDate.getDate() + 1);

      while (currentDate < endDate) {
        if (isWeekday(currentDate)) {
          days++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setNumberOfDays(days);
      onPlazoChange(days);
    } else {
      setNumberOfDays(0);
      onPlazoChange(0);
    }
  };

  const handleDropdownChange = (event) => {
    const selectedDays = parseInt(event.target.value, 10);
    setNumberOfDays(selectedDays);
    onPlazoChange(selectedDays);
  };

  const handleExactDaysChange = () => {
    setUseExactDays(true);
    setUseExactDates(false);
    setStartDate(null);
    setEndDate(null);
    setNumberOfDays(0);
    onPlazoChange(0);
  };

  const handleExactDatesChange = () => {
    setUseExactDays(false);
    setUseExactDates(true);
    setStartDate(null);
    setEndDate(null);
    setNumberOfDays(0);
    onPlazoChange(0);
  };

  const startOf2024 = new Date(2024, 0, 1);
  const endOf2026 = new Date(2026, 11, 31); // Cambié la fecha final a 31/12/2026

  return (
    <div className="plazo">
      <p className="tituloplazo">PLAZO (días)</p>
      <hr />
      <div className="seleccion">
        <label>
          <input type="checkbox" className="cb123" checked={useExactDays} onChange={handleExactDaysChange} />
          CANTIDAD
          <br />
        </label>
      </div>
      <div className="seleccion">
        <label>
          <input type="checkbox" className="cb123" checked={useExactDates} onChange={handleExactDatesChange} />
          CALENDARIO
        </label>
      </div>
      <hr />
      {useExactDates && (
        <div>
          <div>
            <label style={{ marginTop: "1rem", marginBottom: "1rem", fontSize: "20px" }}>INICIO</label>
            <div>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                locale={es}
                dateFormat="dd/MM/yyyy"
                placeholderText={today.toLocaleDateString("es-ES", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                wrapperClassName="datePickerWrapper"
                minDate={startOf2024}
                maxDate={endOf2026}
              />
            </div>
            <label style={{ marginTop: "1rem", marginBottom: "1rem", fontSize: "20px" }}>FIN</label>
            <div>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                locale={es}
                dateFormat="dd/MM/yyyy"
                placeholderText={thirtyDaysLater.toLocaleDateString("es-ES", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                wrapperClassName="datePickerWrapper"
                minDate={startOf2024}
                maxDate={endOf2026}
              />
            </div>
          </div>
          <p className="bajada">
            SE CONTABILIZAN LOS DÍAS COMPRENDIDOS ENTRE LUNES Y VIERNES, SÁBADOS Y DOMINGOS SE DESCARTAN AUTOMÁTICAMENTE
          </p>
        </div>
      )}
      {!useExactDates && useExactDays && (
        <div className="dropdown">
          <select
            value={numberOfDays}
            onChange={handleDropdownChange}
            style={{ marginTop: "1rem", width: "5rem", textAlign: "center", height: "2rem" }}
          >
            {[...Array(261).keys()].map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
          <div className="bajada">
            EXHIBICIÓN DE LUNES A VIERNES <br /> SEMANA = 5 DÍAS
          </div>
        </div>
      )}
    </div>
  );
};
