/* eslint-disable no-console */
import { useState, useEffect, useRef } from "react";
// import { VentasListado } from "../../components/index";
import { useAuthContext } from "../../context/auth";
import { getClientesPublicitarios, getVentas, supabase } from "../../utils";
import "./stylesSales.scss";

function Sales() {
  const cargaRef = useRef();
  const [ventas, setVentas] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [clientesPublicitarios, setClientesPublicitarios] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [intermediarios, setIntermediarios] = useState([]);

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      const newClientesPublicitarios = await getClientesPublicitarios({ pais });
      setClientesPublicitarios(newClientesPublicitarios);

      const newIntermediarios = await getIntermediarios({ pais });
      setIntermediarios(newIntermediarios);

      const newVentas = await getVentas({ pais });
      setVentas(newVentas);
    };

    initialize();
  }, [pais]);

  // eslint-disable-next-line no-unused-vars
  const crearOportunidad = async (e) => {
    e.preventDefault();

    try {
      const elementos = cargaRef.current.elements;
      const newOportunidad = {
        cliente: elementos.cliente.value,
        oportunidad: elementos.oportunidad.value,
        mes: elementos.mes.value,
        estado: elementos.estado.value,
        intermediario: elementos.intermediario.value,
      };
      const { data, error } = await supabase.from("sales").upsert(newOportunidad);
      const { data: dataUpdate, error: errorUpdate } = await supabase
        .from("clientes")
        .update({ agencia: elementos.intermediario.value })
        .eq("nombre", elementos.cliente.value);

      if (error) throw error;
      if (errorUpdate) throw errorUpdate;

      if (data) {
        const newVentas = [...ventas];
        newVentas.push(data[0]);
        setVentas(newVentas);
        cargaRef.current.reset();
        // setOportunidadCreada(true);
      }

      if (dataUpdate) {
        // Blank
      }
    } catch (error) {
      alert(error.message);
    } finally {
      // Blank
    }
  };

  return <div className="Sales" />;
}

export default Sales;
