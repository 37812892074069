/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import "./stylesCotizadorProgrammaticHorasOn.scss";

export const CotizadorProgrammaticHorasOn = ({ 
  onHorasChange, onProgrammaticChange, onDigitalChange, onOptionChange }) => {
  const [selectedIntervals, setSelectedIntervals] = useState([]);
  const [tradicionalDigital, setTradicionalDigital] = useState(false);
  const [programmatic, setProgrammatic] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (interval) => {
    setSelectedIntervals((prevSelectedIntervals) => {
      const selectedIndex = prevSelectedIntervals.indexOf(interval);
      return selectedIndex === -1
        ? [...prevSelectedIntervals, interval]
        : [
            ...prevSelectedIntervals.slice(0, selectedIndex),
            ...prevSelectedIntervals.slice(selectedIndex + 1),
          ];
    });
  };

  useEffect(() => {
    const totalHours = selectedIntervals.length / 2;
    onHorasChange(totalHours, selectedIntervals, selectedOptions);
  }, [selectedIntervals, selectedOptions, onHorasChange]);

  const generateTimeIntervals = () => {
    const intervals = [];
    for (let hour = 7; hour < 21; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const startTime = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        let endTimeHour = hour;
        let endTimeMinute = minute + 30;
        if (endTimeMinute === 60) {
          endTimeHour++;
          endTimeMinute = 0;
        }
        const endTime = `${endTimeHour.toString().padStart(2, "0")}:${endTimeMinute.toString().padStart(2, "0")}`;
        const intervalLabel = `${startTime} - ${endTime}`;
        intervals.push(
          <div key={intervalLabel}>
            <input
              type="checkbox"
              id={intervalLabel}
              checked={selectedIntervals.includes(intervalLabel)}
              onChange={() => handleCheckboxChange(intervalLabel)}
              style={{ width: "15px", height: "15px", marginRight: "10px", marginBottom: "10px" }}
            />
            <label htmlFor={intervalLabel}>{intervalLabel}</label>
          </div>
        );
      }
    }
    return intervals;
  };

  const handleTradicionalDigital = (event) => {
    const isChecked = event.target.checked;
    setTradicionalDigital(isChecked);
    if (isChecked) {
      setProgrammatic(false);
      setSelectedIntervals([]);
      setSelectedOptions([]);
      onDigitalChange(isChecked);
    } else {
      onDigitalChange(false);
    }
  };

  const handleProgrammatic = (event) => {
    const isChecked = event.target.checked;
    setProgrammatic(isChecked);
    if (isChecked) {
      setTradicionalDigital(false);
      setSelectedIntervals([]);
      setSelectedOptions([]);
    }
    onProgrammaticChange(isChecked);
  };

  const handleCheckboxChangeOptions = (option) => {
    setSelectedOptions([option]);
    setSelectedIntervals([]);
    onOptionChange(option.label); // Notificar la opción seleccionada (FULL DAY, AM, PM)
  };

  const options = [
    { label: "FULL DAY | 07:00 - 21:00", hours: 14 },
    { label: "AM | 07:00 - 14:00", hours: 7 },
    { label: "PM | 14:00 - 21:00", hours: 7 },
  ];

  return (
    <div className="horason">
      <p className="titulohorason">EXHIBICIÓN</p>
      <hr />
      <div className="seleccion">
        <label>
          <input
            type="checkbox"
            className="cb123"
            checked={tradicionalDigital}
            onChange={handleTradicionalDigital}
          />
          DIGITAL
          <br />
        </label>
      </div>
      <div className="seleccion">
        <label>
          <input
            type="checkbox"
            className="cb123"
            checked={programmatic}
            onChange={handleProgrammatic}
          />
          PROGRAMMATIC
        </label>
      </div>
      <hr />
      {programmatic && (
        <div className="mt-2">
          {generateTimeIntervals()}
        </div>
      )}
      {tradicionalDigital && (
        <div className="mt-2">
          {options.map((option) => (
            <div key={option.label}>
              <input
                type="checkbox"
                id={option.label}
                checked={selectedOptions.length > 0 && selectedOptions[0].label === option.label}
                onChange={() => handleCheckboxChangeOptions(option)}
                style={{ width: "15px", height: "15px", marginRight: "10px", marginBottom: "10px" }}
              />
              <label htmlFor={option.label}>{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
