/* eslint-disable unicorn/prefer-module */
/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useEffect, useCallback, useState, useMemo } from "react"; // Removed useRef
import { Dropdown } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ListaEdificios } from "..";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getOportunidades, updateOportunidad, getUsers } from "../../utils";
import "./stylesVentasDetalle.scss";

export const VentasDetalle = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [categorias, setCategorias] = useState();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletedOpportunity, setDeletedOpportunity] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDesc, setOrderDesc] = useState(false);
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroAno, setFiltroAno] = useState(["2025"]);
  const [users, setUsers] = useState([]);
  const [filtroEjecutivo, setFiltroEjecutivo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingOpportunity, setEditingOpportunity] = useState(null);

  const methods = useForm();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const { pais, id } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "-";
  }, []);

  const handleMesChange = useCallback((e) => {
    const selectedMonths = Array.from(
      e.target.closest(".dropdown-menu").querySelectorAll("input[type=checkbox]:checked"),
      (checkbox) => checkbox.value
    );
    setFiltroMes(selectedMonths);
  }, []);

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMes.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={handleMesChange}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`mes${index}`} style={{ marginLeft: "10px" }}>
          {mes}
        </label>
      </div>
    ));

  const renderClientesOptions = () => {
    const clientesOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.cliente))].sort();
    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const renderIntermediariosOptions = () => {
    const intermediariosOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.intermediario))].sort();
    const grupos = pais === "Perú" ? ["OMG", "IPG", "GroupM"] : pais === "Chile" ? ["OMG", "IPG"] : [];
    const todosIntermediarios = [...new Set([...intermediariosOrdenados, ...grupos])].sort();
    return todosIntermediarios.map((intermediario, index) => (
      <option key={index} value={intermediario}>
        {intermediario}
      </option>
    ));
  };

  const handleEstatusChange = (e) => {
    const value = e.target.value;
    setFiltroEstatus((prev) => (prev.includes(value) ? prev.filter((estatus) => estatus !== value) : [...prev, value]));
  };

  const renderEstatusOptions = () =>
    Object.entries(customStatusOrder).map(([estatus, _], index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`estatus${index}`}
          value={estatus}
          checked={filtroEstatus.includes(estatus)}
          onChange={handleEstatusChange}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`estatus${index}`} style={{ marginLeft: "10px" }}>
          {estatus}
        </label>
      </div>
    ));

  const handleEdit = (oportunidad) => {
    setEditingOpportunity(oportunidad);
    setIsEditing(true);
    setValue("importe", oportunidad.importe);
    setValue("estatus", oportunidad.estatus);
    setValue("mes", oportunidad.mes);
    setValue("mesCierre", oportunidad.mes_cierre || "");
    setValue("ano", oportunidad.ano || "2025");
    setValue("edificiosSeleccionados", oportunidad.edificiosparticipantes || "");
    setValue("edificiosTotales", oportunidad.edificiosTotales || 0);
    setValue("pantallasTotales", oportunidad.pantallasTotales || 0);
  };

  const onSubmitEdit = async (data) => {
    try {
      setLoading(true);
      const updatedOportunidad = {
        importe: Number(data.importe),
        estatus: data.estatus,
        mes: data.mes,
        mes_cierre: data.mesCierre,
        ano: data.ano,
        edificiosparticipantes: data.edificiosSeleccionados,
        edificiostotales: Number(data.edificiosTotales),
        pantallastotales: Number(data.pantallasTotales),
        updated_at: new Date().toISOString(),
      };
      await updateOportunidad({ id: editingOpportunity.id, oportunidad: updatedOportunidad });
      toast.success("Oportunidad actualizada correctamente");
      setOportunidades((prev) =>
        prev.map((op) => (op.id === editingOpportunity.id ? { ...op, ...updatedOportunidad } : op))
      );
      setIsEditing(false);
      setEditingOpportunity(null);
    } catch (error) {
      console.error("Error updating oportunidad:", error);
      toast.error("Error al actualizar la oportunidad");
    } finally {
      setLoading(false);
    }
  };

  const months = [
    { value: "01", label: "ENE" },
    { value: "02", label: "FEB" },
    { value: "03", label: "MAR" },
    { value: "04", label: "ABR" },
    { value: "05", label: "MAY" },
    { value: "06", label: "JUN" },
    { value: "07", label: "JUL" },
    { value: "08", label: "AGO" },
    { value: "09", label: "SEP" },
    { value: "10", label: "OCT" },
    { value: "11", label: "NOV" },
    { value: "12", label: "DIC" },
  ];

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await updateOportunidad({ id, oportunidad: { eliminada: true } });
      setOportunidades((prev) => prev.map((op) => (op.id === id ? { ...op, eliminada: true } : op)));
      toast.success("La oportunidad se eliminó correctamente");
      setShowConfirmationModal(false);
      setDeletedOpportunity(null);
      setTimeout(() => setShouldRefresh(true), 1000);
    } catch (error) {
      console.error("Error deleting oportunidad:", error);
      toast.error("Hubo un error al eliminar la oportunidad, prueba de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmationModal = (oportunidad) => {
    setShowConfirmationModal(true);
    setDeletedOpportunity(oportunidad);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newOportunidades = await getOportunidades({ pais });
        setOportunidades(newOportunidades);
        const newCategorias = await getCategorias();
        setCategorias(newCategorias);
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers || []);
        setFiltroAno(["2025"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [pais]);

  const renderCategoriaOptions = () =>
    categorias?.map((categoria, index) => (
      <option key={index} value={categoria.nombre}>
        {categoria.nombre}
      </option>
    ));

  const handleCategoriaChange = (e) => setFiltroCategoria(e.target.value);

  const handleAnoChange = (e) => {
    const value = e.target.value;
    setFiltroAno((prev) => (prev.includes(value) ? prev.filter((ano) => ano !== value) : [...prev, value]));
  };

  const oportunidadesFiltradas = oportunidades.filter((oportunidad) => {
    const clienteMatch = !filtroCliente || oportunidad.cliente.includes(filtroCliente);
    const mesMatch = filtroMes.length === 0 || filtroMes.includes(oportunidad.mes);
    const estatusMatch = filtroEstatus.length === 0 || filtroEstatus.includes(oportunidad.estatus);
    const categoriaMatch = !filtroCategoria || oportunidad.categoria === filtroCategoria;
    const ejecutivoMatch = !filtroEjecutivo || oportunidad.ejecutivo === filtroEjecutivo;
    const anoOportunidad = oportunidad.ano || 2024;
    const anoMatch = filtroAno.length === 0 || filtroAno.includes(anoOportunidad.toString());
    const intermediarioMatch =
      !filtroIntermediario ||
      (pais === "Perú"
        ? filtroIntermediario === "OMG"
          ? ["OMG | MA", "OMG | OMD", "OMG | PHD"].includes(oportunidad.intermediario)
          : filtroIntermediario === "IPG"
            ? ["IPG | INITIATIVE", "IPG | MEDIAHUB", "IPG | UM"].includes(oportunidad.intermediario)
            : filtroIntermediario === "GroupM"
              ? ["GM | MINDSHARE", "GM | WAVEMAKER"].includes(oportunidad.intermediario)
              : oportunidad.intermediario.includes(filtroIntermediario)
        : pais === "Chile"
          ? filtroIntermediario === "OMG"
            ? ["OMG | OMD", "OMG | PHD"].includes(oportunidad.intermediario)
            : filtroIntermediario === "IPG"
              ? ["IPG | INITIATIVE", "IPG | KINESSO", "IPG | UM"].includes(oportunidad.intermediario)
              : oportunidad.intermediario.includes(filtroIntermediario)
          : oportunidad.intermediario.includes(filtroIntermediario));

    return (
      clienteMatch &&
      mesMatch &&
      estatusMatch &&
      intermediarioMatch &&
      categoriaMatch &&
      anoMatch &&
      ejecutivoMatch &&
      !oportunidad.eliminada
    );
  });

  useEffect(() => {
    if (shouldRefresh) window.location.reload();
  }, [shouldRefresh]);

  const customStatusOrder = { "100%": 0, "90%": 1, "75%": 2, "50%": 3, "0%": 4 };

  const handleSort = (column) => {
    if (orderBy === column) {
      setOrderDesc(!orderDesc);
    } else {
      setOrderBy(column);
      setOrderDesc(false);
    }
  };

  const sortedOportunidades = [...oportunidadesFiltradas].sort((a, b) => {
    if (orderBy === "mes") return parseInt(a.mes) - parseInt(b.mes);
    if (orderBy === "mes_cierre") return parseInt(a.mes_cierre || "0") - parseInt(b.mes_cierre || "0");
    if (orderBy === "estatus") {
      const statusOrder = ["100%", "90%", "75%", "50%", "0%"];
      return (orderDesc ? -1 : 1) * (statusOrder.indexOf(a.estatus) - statusOrder.indexOf(b.estatus));
    }
    if (orderBy === "importe") return (orderDesc ? -1 : 1) * (b.importe - a.importe);
    if (orderBy) {
      const valueA = a[orderBy];
      const valueB = b[orderBy];
      if (typeof valueA === "string" && typeof valueB === "string")
        return (orderDesc ? -1 : 1) * valueA.localeCompare(valueB);
      if (typeof valueA === "number" && typeof valueB === "number") return (orderDesc ? -1 : 1) * (valueA - valueB);
    }
    const defaultSort = (mesA, mesB, estatusA, estatusB, importeA, importeB) => {
      const mesDiff = parseInt(mesA) - parseInt(mesB);
      if (mesDiff !== 0) return mesDiff;
      const statusOrder = ["100%", "90%", "75%", "50%", "0%"];
      const statusDiff = statusOrder.indexOf(estatusA) - statusOrder.indexOf(estatusB);
      if (statusDiff !== 0) return statusDiff;
      return importeB - importeA;
    };
    return defaultSort(a.mes, b.mes, a.estatus, b.estatus, a.importe, b.importe);
  });

  const renderArrow = (column) => (orderBy === column ? (orderDesc ? "↓" : "↑") : null);

  const sumaImportePorEstatus = (estatus) =>
    oportunidadesFiltradas
      .filter((oportunidad) => oportunidad.estatus === estatus)
      .reduce((acc, oportunidad) => acc + oportunidad.importe, 0);

  const sumaImportes100 = sumaImportePorEstatus("100%");
  const sumaImportes90 = sumaImportePorEstatus("90%");
  const sumaImportes75 = sumaImportePorEstatus("75%");
  const sumaImportes50 = sumaImportePorEstatus("50%");
  const sumaImportes0 = sumaImportePorEstatus("0%");

  const totalImportes = sumaImportes100 + sumaImportes90 + sumaImportes75 + sumaImportes50 + sumaImportes0;

  const data = [
    {
      name: "Total",
      "100%": (sumaImportes100 / totalImportes) * 100,
      "90%": (sumaImportes90 / totalImportes) * 100,
      "75%": (sumaImportes75 / totalImportes) * 100,
      "50%": (sumaImportes50 / totalImportes) * 100,
      "0%": (sumaImportes0 / totalImportes) * 100,
      empty: totalImportes === 0 ? 100 : 0,
    },
  ];

  const getRadius = (key, data) => {
    const keys = Object.keys(data).filter((k) => data[k] > 0);
    const firstKey = keys[0];
    const lastKey = keys.at(-1);
    if (firstKey === key && lastKey === key) return [10, 10, 10, 10];
    if (firstKey === key) return [10, 0, 0, 10];
    if (lastKey === key) return [0, 10, 10, 0];
    return [0, 0, 0, 0];
  };

  const renderEjecutivosOptions = () => {
    const countryMapping = { Uruguay: "URU", Perú: "PER", Chile: "CHI" };
    const countryCode = countryMapping[pais] || "";
    return users
      .filter((user) => (user.pais === countryCode || !countryCode) && ["comercial", "gerencia"].includes(user.rol))
      .map((user, index) => (
        <option key={index} value={user.id}>
          {user.nombre}
        </option>
      ));
  };

  return (
    <div className="container-ventas-detalle">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-md-12">
          <div className="d-flex gap-3" style={{ width: "100%" }}>
            <div style={{ width: "14%" }}>
              <select
                className={`form-control ${filtroCliente ? "active-filter" : ""}`}
                value={filtroCliente}
                onChange={(e) => setFiltroCliente(e.target.value)}
              >
                <option value="">Cliente</option>
                {renderClientesOptions()}
              </select>
            </div>
            <div style={{ width: "14%" }}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="white border"
                  id="dropdown-basic"
                  className={`form-control text-start ${filtroMes.length > 0 ? "active-filter" : ""}`}
                >
                  {filtroMes.length > 0 ? filtroMes.map((mes) => mesEnLetras(mes)).join(", ") : "Mes/meses"}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderMesOptions()}</Dropdown.Menu>
              </Dropdown>
            </div>
            <div style={{ width: "14%" }}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="white border"
                  id="dropdown-status"
                  className={`form-control text-start ${filtroEstatus.length > 0 ? "active-filter" : ""}`}
                >
                  {filtroEstatus.length > 0 ? filtroEstatus.join(", ") : "Status"}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderEstatusOptions()}</Dropdown.Menu>
              </Dropdown>
            </div>
            <div style={{ width: "14%" }}>
              <select
                className={`form-control ${filtroCategoria ? "active-filter" : ""}`}
                value={filtroCategoria}
                onChange={handleCategoriaChange}
              >
                <option value="">Categoría</option>
                {renderCategoriaOptions()}
              </select>
            </div>
            <div style={{ width: "14%" }}>
              <select
                className={`form-control ${filtroIntermediario ? "active-filter" : ""}`}
                value={filtroIntermediario}
                onChange={(e) => setFiltroIntermediario(e.target.value)}
              >
                <option value="">Agencia</option>
                {renderIntermediariosOptions()}
              </select>
            </div>
            <div style={{ width: "14%" }}>
              <Dropdown>
                <Dropdown.Toggle variant="white border" id="dropdown-basic" className="form-control text-start">
                  {filtroAno.length > 0 ? filtroAno.join(", ") : "Filtrar por año"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {["2023", "2024", "2025"].map((ano, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`ano${index}`}
                        value={ano}
                        checked={filtroAno.includes(ano)}
                        onChange={handleAnoChange}
                        style={{ marginLeft: "-15px" }}
                      />
                      <label className="form-check-label" htmlFor={`ano${index}`} style={{ marginLeft: "10px" }}>
                        {ano}
                      </label>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div style={{ width: "14%" }}>
              <select
                className={`form-control ${filtroEjecutivo ? "active-filter" : ""}`}
                value={filtroEjecutivo}
                onChange={(e) => setFiltroEjecutivo(e.target.value)}
              >
                <option value="">Ejecutivo</option>
                {renderEjecutivosOptions()}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-2">
        <div className="col-md-12">
          <div className="d-flex justify-content-between">
            <div
              className="text-center p-2 bg-success border border-dark m-3 text-white"
              style={{ borderRadius: "4px", width: "225px" }}
            >
              <div>
                <strong>100%</strong>
              </div>
              <div>
                {sumaImportes100 > 0
                  ? `${moneda(pais)} ${sumaImportes100.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                  : "-"}
              </div>
            </div>
            <div
              className="text-center p-2 bg-danger border border-dark m-3 text-white"
              style={{ borderRadius: "4px", width: "225px" }}
            >
              <div>
                <strong>90%</strong>
              </div>
              <div>
                {sumaImportes90 > 0
                  ? `${moneda(pais)} ${sumaImportes90.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                  : "-"}
              </div>
            </div>
            <div
              className="text-center border border-dark p-2 bg-primary m-3 text-white"
              style={{ borderRadius: "4px", width: "225px" }}
            >
              <div>
                <strong>75%</strong>
              </div>
              <div>
                {sumaImportes75 > 0
                  ? `${moneda(pais)} ${sumaImportes75.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                  : "-"}
              </div>
            </div>
            <div
              className="text-center p-2 bg-warning m-3 border border-dark text-dark"
              style={{ borderRadius: "4px", width: "225px" }}
            >
              <div>
                <strong>50%</strong>
              </div>
              <div>
                {sumaImportes50 > 0
                  ? `${moneda(pais)} ${sumaImportes50.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                  : "-"}
              </div>
            </div>
            <div
              className="text-center p-2 bg-black m-3 border border-dark text-white"
              style={{ borderRadius: "4px", width: "225px" }}
            >
              <div>
                <strong>0%</strong>
              </div>
              <div>
                {sumaImportes0 > 0
                  ? `${moneda(pais)} ${sumaImportes0.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <ResponsiveContainer width="100%" height={80} className="p-0 m-0">
            <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis type="number" domain={[0, 100]} tick={false} axisLine={false} />
              <YAxis type="category" dataKey="name" hide />
              {data[0].empty > 0 ? (
                <Bar dataKey="empty" fill="#555555" isAnimationActive={false} radius={[10, 10, 10, 10]}>
                  <LabelList dataKey="empty" position="center" formatter={() => "SIN DATOS"} fill="#FFF" />
                </Bar>
              ) : (
                <>
                  {data[0]["100%"] > 0 && (
                    <Bar dataKey="100%" stackId="a" fill="#198754" radius={getRadius("100%", data[0])}>
                      <LabelList
                        dataKey="100%"
                        position="center"
                        formatter={(value) => `${Math.round(value)}%`}
                        fill="#fff"
                      />
                    </Bar>
                  )}
                  {data[0]["90%"] > 0 && (
                    <Bar dataKey="90%" stackId="a" fill="#dc3545" radius={getRadius("90%", data[0])}>
                      <LabelList
                        dataKey="90%"
                        position="center"
                        formatter={(value) => `${Math.round(value)}%`}
                        fill="#fff"
                      />
                    </Bar>
                  )}
                  {data[0]["75%"] > 0 && (
                    <Bar dataKey="75%" stackId="a" fill="#0d6efd" radius={getRadius("75%", data[0])}>
                      <LabelList
                        dataKey="75%"
                        position="center"
                        formatter={(value) => `${Math.round(value)}%`}
                        fill="#fff"
                      />
                    </Bar>
                  )}
                  {data[0]["50%"] > 0 && (
                    <Bar dataKey="50%" stackId="a" fill="#ffc107" radius={getRadius("50%", data[0])}>
                      <LabelList
                        dataKey="50%"
                        position="center"
                        formatter={(value) => `${Math.round(value)}%`}
                        fill="#000"
                      />
                    </Bar>
                  )}
                  {data[0]["0%"] > 0 && (
                    <Bar dataKey="0%" stackId="a" fill="#000000" radius={getRadius("0%", data[0])}>
                      <LabelList
                        dataKey="0%"
                        position="center"
                        formatter={(value) => `${Math.round(value)}%`}
                        fill="#fff"
                      />
                    </Bar>
                  )}
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <table
            className="table table-bordered table-striped table-hover table-sm"
            style={{ width: "100%", tableLayout: "fixed" }}
          >
            <thead className="table-dark text-center align-middle">
              <tr>
                <th onClick={() => handleSort("id")} style={{ width: "6%" }}>
                  ID {renderArrow("id")}
                </th>
                <th onClick={() => handleSort("cliente")} style={{ width: "20%" }}>
                  CLIENTE {renderArrow("cliente")}
                </th>
                <th onClick={() => handleSort("mes_cierre")} style={{ width: "8%" }}>
                  CIERRE {renderArrow("mes_cierre")}
                </th>
                <th onClick={() => handleSort("mes")} style={{ width: "8%" }}>
                  FACTURA {renderArrow("mes")}
                </th>
                <th onClick={() => handleSort("importe")} style={{ width: "10%" }}>
                  TICKET {renderArrow("importe")}
                </th>
                <th onClick={() => handleSort("estatus")} style={{ width: "8%" }}>
                  STATUS {renderArrow("estatus")}
                </th>
                <th onClick={() => handleSort("importePorPantalla")} style={{ width: "5%" }}>
                  TIPO {renderArrow("importePorPantalla")}
                </th>
                <th onClick={() => handleSort("formatopauta")} style={{ width: "5%" }}>
                  FREC. {renderArrow("formatopauta")}
                </th>
                <th onClick={() => handleSort("periodopauta")} style={{ width: "5%" }}>
                  DÍAS {renderArrow("periodopauta")}
                </th>
                <th onClick={() => handleSort("edificiostotales")} style={{ width: "5%" }}>
                  EDIF. {renderArrow("edificiostotales")}
                </th>
                <th onClick={() => handleSort("pantallastotales")} style={{ width: "5%" }}>
                  PANT. {renderArrow("pantallastotales")}
                </th>
                <th onClick={() => handleSort("intermediario")} style={{ width: "15%" }}>
                  INTERMEDIARIO {renderArrow("intermediario")}
                </th>
                <th onClick={() => handleSort("ejecutivo")} style={{ width: "10%" }}>
                  EJECUTIVO {renderArrow("ejecutivo")}
                </th>
                <th colSpan="2" style={{ width: "10%" }}>
                  EDITAR
                </th>
              </tr>
            </thead>
            <tbody className="table-striped" style={{ height: "35px", lineHeight: "35px" }}>
              {sortedOportunidades.length === 0 ? (
                <tr>
                  <td colSpan="14" className="text-center">
                    No hay ventas ni oportunidades según los filtros seleccionados :(
                  </td>
                </tr>
              ) : (
                sortedOportunidades.map((oportunidad) => (
                  <tr key={oportunidad.id} style={{ height: "35px", lineHeight: "35px" }}>
                    <td style={{ width: "6%" }} className="text-center align-middle">
                      {oportunidad.id}
                    </td>
                    <td style={{ width: "20%" }} className="text-start align-middle">
                      {oportunidad.cliente}
                    </td>
                    <td style={{ width: "8%" }} className="text-center align-middle">
                      {oportunidad.mes_cierre ? mesEnLetras(oportunidad.mes_cierre) : "-"}{" "}
                      {/* Corrected to show mes_cierre under MES CIERRE */}
                    </td>
                    <td style={{ width: "8%" }} className="text-center align-middle">
                      {mesEnLetras(oportunidad.mes)} {/* Corrected to show mes under MES FACTURACIÓN */}
                    </td>
                    <td style={{ width: "10%" }} className="text-end align-middle">
                      {moneda(pais)}{" "}
                      {oportunidad.importe.toLocaleString(localString(pais), {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      })}
                    </td>
                    <td
                      style={{ width: "8%" }}
                      className={`text-center align-middle ${
                        oportunidad.estatus === "100%"
                          ? "bg-success text-white"
                          : oportunidad.estatus === "90%"
                            ? "bg-danger text-white"
                            : oportunidad.estatus === "75%"
                              ? "bg-primary text-white"
                              : oportunidad.estatus === "50%"
                                ? "bg-warning text-dark"
                                : "bg-black text-white"
                      }`}
                    >
                      {oportunidad.estatus}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {oportunidad.tipo === null || oportunidad.tipo === "d"
                        ? "D"
                        : oportunidad.tipo === "p"
                          ? "P"
                          : oportunidad.tipo === "m"
                            ? "M"
                            : oportunidad.tipo}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {oportunidad.formatopauta === "A"
                        ? "5"
                        : oportunidad.formatopauta === "B"
                          ? "10"
                          : oportunidad.formatopauta === "C"
                            ? "20"
                            : oportunidad.formatopauta}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {oportunidad.periodopauta}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {oportunidad.edificiostotales}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {oportunidad.pantallastotales}
                    </td>
                    <td style={{ width: "15%" }} className="text-center align-middle">
                      {oportunidad.intermediario}
                    </td>
                    <td style={{ width: "10%" }} className="text-center align-middle">
                      {users.find((user) => user.id.toString() === oportunidad.ejecutivo)?.nombre ||
                        oportunidad.ejecutivo}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {pais === "Chile" ? (
                        oportunidad.facturanumero ||
                        oportunidad.estatus === "0%" ||
                        id.toString() !== oportunidad.ejecutivo ? (
                          "-"
                        ) : (
                          <FaEdit onClick={() => handleEdit(oportunidad)} />
                        )
                      ) : oportunidad.facturanumero || oportunidad.estatus === "0%" ? (
                        "-"
                      ) : (
                        <FaEdit onClick={() => handleEdit(oportunidad)} />
                      )}
                    </td>
                    <td style={{ width: "5%" }} className="text-center align-middle">
                      {pais === "Chile" ? (
                        oportunidad.facturanumero ||
                        oportunidad.estatus === "0%" ||
                        id.toString() !== oportunidad.ejecutivo ? (
                          "-"
                        ) : (
                          <FaTrash onClick={() => handleConfirmationModal(oportunidad)} />
                        )
                      ) : oportunidad.facturanumero || oportunidad.estatus === "0%" ? (
                        "-"
                      ) : (
                        <FaTrash onClick={() => handleConfirmationModal(oportunidad)} />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal isOpen={isEditing} toggle={() => setIsEditing(false)} size="xl">
        <ModalHeader>Editar Oportunidad #{editingOpportunity?.id}</ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitEdit)}>
              <div className="row g-3">
                <div className="col-4">
                  <label htmlFor="importe" className="form-label">
                    Importe
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">{moneda(pais)}</span>
                    <input
                      type="number"
                      className={`form-control ${errors.importe ? "is-invalid" : ""}`}
                      id="importe"
                      step="0.01"
                      {...register("importe", { required: true, min: 0, valueAsNumber: true })}
                      onChange={(e) => setValue("importe", e.target.value ? Number(e.target.value) : "")}
                    />
                    {errors.importe && <div className="invalid-feedback">Este campo es obligatorio</div>}
                  </div>
                  <small className="form-text text-muted">
                    {editingOpportunity?.importe
                      ? `${moneda(pais)} ${Number(watch("importe") || editingOpportunity.importe).toLocaleString(
                          localString(pais),
                          { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) }
                        )}`
                      : "-"}
                  </small>
                </div>
                <div className="col-4">
                  <label htmlFor="estatus" className="form-label">
                    Estatus
                  </label>
                  <select
                    className={`form-control ${errors.estatus ? "is-invalid" : ""}`}
                    id="estatus"
                    {...register("estatus", { required: true })}
                  >
                    <option value="">Selecciona un porcentaje</option>
                    <option value="100%">100%</option>
                    <option value="90%">90%</option>
                    <option value="75%">75%</option>
                    <option value="50%">50%</option>
                    <option value="0%">0%</option>
                  </select>
                  {errors.estatus && <div className="invalid-feedback">Este campo es obligatorio</div>}
                </div>
                <div className="col-4">
                  <label htmlFor="mesCierre" className="form-label">
                    Mes de Cierre
                  </label>
                  <select
                    className={`form-control ${errors.mesCierre ? "is-invalid" : ""}`}
                    id="mesCierre"
                    {...register("mesCierre")}
                  >
                    <option value="">Selecciona un mes</option>
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                  {errors.mesCierre && <div className="invalid-feedback">Error en el campo</div>}
                </div>
                <div className="col-4">
                  <label htmlFor="mes" className="form-label">
                    Mes Facturación
                  </label>
                  <select
                    className={`form-control ${errors.mes ? "is-invalid" : ""}`}
                    id="mes"
                    {...register("mes", { required: true })}
                  >
                    <option value="">Selecciona un mes</option>
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                  {errors.mes && <div className="invalid-feedback">Este campo es obligatorio</div>}
                </div>
                <div className="col-4">
                  <label htmlFor="ano" className="form-label">
                    Año
                  </label>
                  <select
                    className={`form-control ${errors.ano ? "is-invalid" : ""}`}
                    id="ano"
                    {...register("ano", { required: true })}
                  >
                    <option value="">Selecciona un año</option>
                    {["2023", "2024", "2025"].map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.ano && <div className="invalid-feedback">Este campo es obligatorio</div>}
                </div>
              </div>
              <div className="mt-3">
                <h5>Seleccionar Edificios</h5>
                <ListaEdificios edificiosPreseleccionados={editingOpportunity?.edificiosparticipantes || ""} />
              </div>
              <input hidden type="text" {...register("edificiosSeleccionados")} />
              <input hidden type="text" {...register("personasTotales")} />
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsEditing(false)} disabled={loading}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleSubmit(onSubmitEdit)} disabled={loading}>
            {loading ? "Guardando..." : "Guardar"}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showConfirmationModal} toggle={() => setShowConfirmationModal(false)}>
        <ModalHeader>Confirmación</ModalHeader>
        <ModalBody>¿Está seguro de eliminar esta oportunidad?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleDelete(deletedOpportunity.id)}>
            Eliminar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
