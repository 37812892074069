/* eslint-disable unicorn/prefer-module */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/auth";
import { getEdificios, updateEdificio, saveEdificio, getCategorias } from "../../utils";

import "./stylesEdificiosTable.scss";

const EdificiosTable = ({ pais: propPais }) => {
  const [categorias, setCategorias] = useState();
  const [edificios, setEdificios] = useState([]);
  const [editMode, setEditMode] = useState(null); // Estado para controlar el modo edición
  const [editedEdificio, setEditedEdificio] = useState(null); // Estado para almacenar los datos editados
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newEdificio, setNewEdificio] = useState({
    edificio: "",
    estado: "",
    alianza: "",
    personas: "",
    pantallas: "",
    totems: "",
    espera: "",
    programatica: "Sí",
    estacionamientos: "",
    estacionamientos_ocupacion: "",
    ubicacion: "",
    direccion: "",
    lat: "",
    lng: "",
    empresaslistado: "",
    empresasdestacadas: "",
    vetos: "",
    comision_1_porcentaje: "",
    comision_1_fechainicio: "",
    comision_1_fechafin: "",
  }); // Estado para almacenar los datos del nuevo edificio
  const [showForm, setShowForm] = useState(false); // Estado para controlar la visibilidad del formulario

  const { pais, rol } = useAuthContext(); // Extraer el rol del contexto

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  // Función reutilizable para obtener edificios y ordenarlos
  const fetchEdificios = async () => {
    try {
      const data = await getEdificios({ pais: propPais || pais });

      // Ordenar los edificios primero por estado y luego por cantidad de personas
      const sortedData = data.sort((a, b) => {
        const estadosOrder = { OPERATIVO: 1, INSTALACIÓN: 2, PROYECTO: 3 };
        if (estadosOrder[a.estado] !== estadosOrder[b.estado]) {
          return estadosOrder[a.estado] - estadosOrder[b.estado];
        }
        return b.personas - a.personas;
      });

      setEdificios(sortedData);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Inicializa categorías y edificios
  useEffect(() => {
    const initialize = async () => {
      const newCategorias = await getCategorias();
      setCategorias(newCategorias);
    };

    initialize();
  }, [pais]);

  // Usa fetchEdificios para cargar datos iniciales
  useEffect(() => {
    fetchEdificios();
  }, [propPais, pais]);

  const handleEditClick = (edificio) => {
    setEditMode(edificio.id);
    // Convertir vetos de string a array de IDs (asumiendo que viene como "id1, id2, id3")
    const vetosArray = edificio.vetos
      ? edificio.vetos.split(",").map((veto) => veto.trim())
      : [];
    setEditedEdificio({
      ...edificio,
      vetos: vetosArray, // Almacenar como array de IDs
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    
    if (name === "personas" || name === "estacionamientos") {
      // Remover cualquier caracter no numérico excepto coma para permitir escritura
      formattedValue = value.replace(/[^\d,]/g, "");
    }
    
    setEditedEdificio({ ...editedEdificio, [name]: formattedValue });
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewEdificio({ ...newEdificio, [name]: value });
  };

  const handleAddEdificio = async () => {
    if (!newEdificio.edificio || !newEdificio.estado) {
      toast.error("Nombre y estado son obligatorios.");
      return;
    }

    try {
      const nuevo = {
        ...newEdificio,
        pais, // Agregar el país al objeto del edificio
      };

      // Guardar el edificio en la base de datos
      const savedEdificio = await saveEdificio(nuevo);

      // Agregar el nuevo edificio a la lista local
      setEdificios((prev) => [...prev, savedEdificio]);

      // Limpiar el formulario
      setNewEdificio({
        edificio: "",
        estado: "",
        alianza: "",
        personas: "",
        pantallas: "",
        totems: "",
        espera: "",
        programatica: "Sí",
        estacionamientos: "",
        estacionamientos_ocupacion: "",
        ubicacion: "",
        direccion: "",
        lat: "",
        lng: "",
        empresaslistado: "",
        empresasdestacadas: "",
        vetos: "",
        comision_1_porcentaje: "",
        comision_1_fechainicio: "",
        comision_1_fechafin: "",
      });

      toast.success("Edificio agregado exitosamente.");
    } catch (error) {
      console.error("Error al guardar el edificio:", error);
      toast.error("Error al agregar el edificio.");
    }
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);
    
      const formattedPersonas =
        typeof editedEdificio.personas === "string"
          ? parseInt(editedEdificio.personas.replace(/,/g, ""), 10) || 0
          : editedEdificio.personas || 0;
    
      const formattedEstacionamientos =
        typeof editedEdificio.estacionamientos === "string"
          ? parseInt(editedEdificio.estacionamientos.replace(/,/g, ""), 10) || 0
          : editedEdificio.estacionamientos || 0;
    
      const formattedVetos = Array.isArray(editedEdificio.vetos)
        ? editedEdificio.vetos.join(", ") // Enviar como string de IDs separados por comas
        : "";
    
      const updatedData = {
        id: editedEdificio.id,
        personas: formattedPersonas,
        estacionamientos: formattedEstacionamientos,
        vetos: formattedVetos,
        empresaslistado: editedEdificio.empresaslistado,
        empresasdestacadas: editedEdificio.empresasdestacadas,
      };
    
      // Actualizar en la base de datos sin asignar a una variable
      await updateEdificio(updatedData);
    
      // Actualizar el estado local inmediatamente
      setEdificios((prevEdificios) =>
        prevEdificios.map((edificio) =>
          edificio.id === editedEdificio.id
            ? {
                ...edificio,
                ...updatedData,
                vetos: formattedVetos, // Aseguramos que vetos se muestre como string
              }
            : edificio
        )
      );
    
      toast.success("Edificio actualizado exitosamente");
    
      // Opcional: Todavía podemos mantener el fetch después de 3 segundos como respaldo
      setTimeout(async () => {
        await fetchEdificios();
      }, 3000);
    } catch (error) {
      console.error("Error al actualizar el edificio:", error);
      toast.error("Error al actualizar el edificio");
    } finally {
      setLoading(false);
      setEditMode(null);
      setEditedEdificio(null);
    }
  };

  const handleCancelClick = () => {
    setEditMode(null); // Sal del modo edición sin guardar
    setEditedEdificio(null);
  };

  if (loading) return <div>Cargando edificios...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleVetosChange = (categoriaId) => {
    setEditedEdificio((prev) => {
      const vetos = prev.vetos || [];
      if (vetos.includes(categoriaId)) {
        return {
          ...prev,
          vetos: vetos.filter((id) => id !== categoriaId),
        };
      }
      return {
        ...prev,
        vetos: [...vetos, categoriaId],
      };
    });
  };

  return (
    <div className="edificios">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div style={{ maxWidth: "95%", margin: "0 auto" }}>
        {rol === "superadmin" && (
          <div className="text-center mb-3">
            <button
              className={`btn ${showForm ? "btn-warning" : "btn-primary"}`}
              onClick={() => setShowForm((prev) => !prev)}
            >
              {showForm ? "Cancelar" : "Agregar Edificio"}
            </button>
          </div>
        )}

        {showForm && (
          <div className="row g-3 justify-content-center text-center">
            <div className="col-md-3">
              <input
                type="text"
                name="edificio"
                placeholder="Nombre"
                value={newEdificio.edificio}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="estado"
                placeholder="Estado"
                value={newEdificio.estado}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="alianza"
                placeholder="Propietario"
                value={newEdificio.alianza}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="personas"
                placeholder="Personas"
                value={newEdificio.personas}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="pantallas"
                placeholder="Pantallas"
                value={newEdificio.pantallas}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="totems"
                placeholder="Tótems"
                value={newEdificio.totems}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="espera"
                placeholder="Espera"
                value={newEdificio.espera}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="estacionamientos"
                placeholder="Estacionamientos"
                value={newEdificio.estacionamientos}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="estacionamientos_ocupacion"
                placeholder="Ocupación estacionamientos"
                value={newEdificio.estacionamientos_ocupacion}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="ubicacion"
                placeholder="Ubicación"
                value={newEdificio.ubicacion}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="direccion"
                placeholder="Dirección"
                value={newEdificio.direccion}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="lat"
                placeholder="Latitud"
                value={newEdificio.lat}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="lng"
                placeholder="Longitud"
                value={newEdificio.lng}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="empresaslistado"
                placeholder="Empresas listado"
                value={newEdificio.empresaslistado}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>     
            <div className="col-md-3">
              <input
                type="text"
                name="empresasdestacadas"
                placeholder="Empresas destacadas"
                value={newEdificio.empresasdestacadas}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="vetos"
                placeholder="Vetos"
                value={newEdificio.vetos}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="comision_1_porcentaje"
                placeholder="Comisión (%)"
                value={newEdificio.comision_1_porcentaje}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                name="comision_1_fechainicio"
                placeholder="Inicio contrato"
                value={newEdificio.comision_1_fechainicio}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                name="comision_1_fechafin"
                placeholder="Fin contrato"
                value={newEdificio.comision_1_fechafin}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-12 text-center mb-3">
              <button className="btn btn-primary" onClick={handleAddEdificio}>
      Agregar
              </button>
            </div>
          </div>
        )}
      </div>
      
      <div style={{ maxWidth: "95%", margin: "0 auto" }}>
        <table className="table table-bordered table-striped table-hover small text-sm">          
          <thead>
            <tr className="table-dark text-center">
              <th style={{ width: "4%" }}>ID</th>
              <th style={{ width: "15%" }}>NOMBRE</th>
              <th style={{ width: "9%" }}>STATUS</th>
              <th style={{ width: "7%" }}>PERSONAS</th>
              <th style={{ width: "6%" }}>PARKING</th>
              <th style={{ width: "18%" }}>EMPRESAS</th>
              <th style={{ width: "18%" }}>EMPRESAS DESTACADAS</th>
              <th style={{ width: "18%" }}>VETOS</th>
              <th style={{ width: "5%" }}>EDIT</th>
            </tr>
          </thead>
          <tbody>
            {edificios.map((edificio) => (
              <tr key={edificio.id}>
                {editMode === edificio.id ? (
                  <>
                    <td>{edificio.id}</td>
                    <td style={{ textAlign: "left" }}>
                      {edificio.edificio ? edificio.edificio.toUpperCase() : "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor:
          edificio.estado === "OPERATIVO"
            ? "green"
            : edificio.estado === "INSTALACIÓN"
              ? "red"
              : edificio.estado === "PROYECTO"
                ? "blue"
                : "black",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {edificio.estado}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="personas"
                        value={editedEdificio.personas.toLocaleString(localString(pais), {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}
                        onChange={handleInputChange}
                        style={{
                          height: "20px",
                          width: "80px",
                          textAlign: "right",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="estacionamientos" // Corregido de "personas" a "estacionamientos"
                        value={
                          editedEdificio.estacionamientos !== undefined && editedEdificio.estacionamientos !== null
                            ? editedEdificio.estacionamientos.toLocaleString(localString(pais), {
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                            })
                            : ""
                        }
                        onChange={handleInputChange}
                        style={{
                          height: "20px",
                          width: "80px",
                          textAlign: "right",
                        }}
                      />
                    </td>
                    <td>
                      <textarea
                        name="empresaslistado"
                        value={editedEdificio.empresaslistado || ""}
                        onChange={(e) =>
                          setEditedEdificio((prev) => ({
                            ...prev,
                            empresaslistado: e.target.value.replace(/\n/g, ""), // Elimina cualquier salto de línea
                          }))
                        }
                        style={{
                          height: "100px", // Ajusta la altura
                          width: "100%", // Asegura que el campo ocupe todo el ancho
                          resize: "none", // Opcional: desactiva el redimensionamiento manual
                          textTransform: "uppercase", // Visualmente, convierte todo a mayúsculas
                        }}
                        className="form-control form-control-sm"
                      />
                    </td>
                    <td>
                      <textarea
                        name="empresasdestacadas"
                        value={editedEdificio.empresasdestacadas || ""}
                        onChange={(e) =>
                          setEditedEdificio((prev) => ({
                            ...prev,
                            empresasdestacadas: e.target.value.replace(/\n/g, ""), // Elimina cualquier salto de línea
                          }))
                        }
                        style={{
                          height: "100px", // Ajusta la altura
                          width: "100%", // Asegura que el campo ocupe todo el ancho
                          resize: "none", // Opcional: desactiva el redimensionamiento manual
                          textTransform: "uppercase", // Visualmente, convierte todo a mayúsculas
                        }}
                        className="form-control form-control-sm"
                      />
                    </td>
                    <td>
                      {editMode === edificio.id ? (
                        <div
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            padding: "5px",
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                            textAlign: "left", // Alinea todo el texto a la izquierda dentro del listado
                          }}
                        >
                          {categorias?.map((categoria) => (
                            <label
                              key={categoria.id}
                              style={{
                                display: "block", // Cada checkbox en una línea nueva
                                margin: "5px 0", // Espaciado entre checkboxes
                                fontSize: "12px", // Tamaño de fuente pequeño para caber en la celda
                                textAlign: "left", // Asegura que el texto del label esté alineado a la izquierda
                              }}
                            >
                              <input
                                type="checkbox"
                                id={`categoria-${categoria.id}`}
                                checked={editedEdificio.vetos?.includes(categoria.id.toString())}
                                onChange={() => handleVetosChange(categoria.id.toString())} // Enviar ID como string
                              />
                              <span style={{ marginLeft: "8px" }}>{categoria.nombre}</span>
                            </label>
                          ))}
                        </div>
                      ) : (
                        edificio.vetos
                          ?.map((vetoId) => categorias?.find((cat) => cat.id === vetoId)?.nombre || "-")
                          .join(", ") || "-"
                      )}
                    </td>
                    <td>
                      <div className="d-flex flex-column align-items-center">
                        <button
                          onClick={handleSaveClick}
                          className="btn btn-success btn-sm"
                          style={{ fontSize: "11px", marginBottom: "5px", width: "100%" }}
                        >
          OK
                        </button>
                        <button
                          onClick={handleCancelClick}
                          className="btn btn-danger btn-sm"
                          style={{ fontSize: "11px", width: "100%" }}
                        >
          CANCEL
                        </button>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{edificio.id}</td>
                    <td style={{ textAlign: "left" }}>
                      {edificio.edificio ? edificio.edificio.toUpperCase() : "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor:
          edificio.estado === "OPERATIVO"
            ? "green"
            : edificio.estado === "INSTALACIÓN"
              ? "red"
              : edificio.estado === "PROYECTO"
                ? "blue"
                : "black",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {edificio.estado}
                    </td>
                    <td>
                      {edificio.personas
                        ? edificio.personas.toLocaleString(localString(pais), {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })
                        : "-"}
                    </td>
                    <td>
                      {edificio.estacionamientos !== undefined && edificio.estacionamientos !== null
                        ? edificio.estacionamientos.toLocaleString(localString(pais), {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })
                        : "-"}
                    </td>                    <td>{(edificio.empresaslistado || "-").toUpperCase()}</td>
                    <td>{(edificio.empresasdestacadas || "-").toUpperCase()}</td>
                    <td>
                      {edificio.vetos
                        ? edificio.vetos
                          .split(",")
                          .map((vetoId) =>
                            categorias?.find((cat) => cat.id.toString() === vetoId.trim())?.nombre || vetoId
                          )
                          .join(", ")
                        : "-"}
                    </td>                    <td>
                      <button
                        onClick={() => handleEditClick(edificio)}
                        className="btn btn-primary"
                        style={{ padding: "3px 6px", fontSize: "10px" }}
                      >
        EDIT
                      </button>
                    </td>
                  </>
                )}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EdificiosTable;
