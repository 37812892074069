/* eslint-disable unicorn/prefer-module */
/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getOportunidades, getIntermediarios } from "../../utils";
import "./stylesVentasFacturas.scss";

export const VentasFacturas = () => {
  const [todasOportunidades, setTodasOportunidades] = useState([]);
  const [oportunidadesFiltradas, setOportunidadesFiltradas] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [filtroNumeroFactura, setFiltroNumeroFactura] = useState("");
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroCobrada, setFiltroCobrada] = useState("");
  const [numerosFacturaUnicos, setNumerosFacturaUnicos] = useState([]);
  const [clientesUnicos, setClientesUnicos] = useState([]);
  const [filtroAno, setFiltroAno] = useState(new Date().getFullYear().toString());
  const [filtroMeses, setFiltroMeses] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [intermediariosUnicos, setIntermediariosUnicos] = useState([]);

  const { pais } = useAuthContext();

  const navigate = useNavigate();

  const goToOportunidad = useCallback((id) => navigate(`/admin/factura/${id}`), [navigate]);

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      }[pais] || "es-UY"),
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      }[pais] || "$"),
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      }[pais] || 0),
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes, 10) - 1] || "";
  }, []);

  // Inicializar datos
  useEffect(() => {
    const initialize = async () => {
      const allOportunidades = await getOportunidades({ pais });
      const allIntermediarios = await getIntermediarios({ pais });

      setTodasOportunidades(allOportunidades);
      setIntermediarios(allIntermediarios);

      // Opciones únicas de intermediarios
      const intermediariosUnicos = [...new Set(allIntermediarios.map((i) => i.nombre))].sort();
      setIntermediariosUnicos(intermediariosUnicos);
    };
    initialize();
  }, [pais]);

  // Filtrar oportunidades basadas en los filtros seleccionados
  useEffect(() => {
    const filtrarOportunidades = () => {
      const yearSeleccionado = filtroAno ? parseInt(filtroAno, 10) : null;

      const filtradas = todasOportunidades
        .filter((oportunidad) => {
          const fechaFactura = oportunidad.facturafecha ? new Date(oportunidad.facturafecha) : null;
          const mesFactura = fechaFactura ? (fechaFactura.getMonth() + 1).toString().padStart(2, "0") : null;

          const mesFiltrado =
            filtroMeses.length > 0
              ? filtroMeses.includes(mesFactura) || filtroMeses.includes(oportunidad.mes)
              : true;

          const intermediarioFiltrado =
            filtroIntermediario ? oportunidad.intermediario === filtroIntermediario : true;

          const perteneceAlAno =
            yearSeleccionado
              ? parseInt(oportunidad.ano, 10) === yearSeleccionado ||
                (fechaFactura && fechaFactura.getFullYear() === yearSeleccionado)
              : true;

          return (
            mesFiltrado &&
            intermediarioFiltrado &&
            (filtroNumeroFactura ? oportunidad.facturanumero === filtroNumeroFactura : true) &&
            (filtroCliente ? oportunidad.cliente === filtroCliente : true) &&
            (filtroCobrada
              ? (filtroCobrada === "Sí" ? oportunidad.facturafechacobro : !oportunidad.facturafechacobro)
              : true) &&
            perteneceAlAno &&
            oportunidad.estatus === "100%" &&
            !oportunidad.eliminada
          );
        })
        .map((oportunidad) => {
          const intermediarioAsociado = intermediarios.find(
            (intermediario) => intermediario.nombre === oportunidad.intermediario
          );
          return { ...oportunidad, comision: intermediarioAsociado ? intermediarioAsociado.comision : 0 };
        });

      setOportunidadesFiltradas(filtradas);

      // Actualizar listas únicas para filtros
      const numerosFactura = [...new Set(filtradas.map((o) => o.facturanumero))]
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
      setNumerosFacturaUnicos(numerosFactura);

      const clientes = [...new Set(filtradas.map((o) => o.cliente))].filter(Boolean).sort();
      setClientesUnicos(clientes);
    };

    if (todasOportunidades.length > 0) {
      filtrarOportunidades();
    }
  }, [
    filtroMeses,
    filtroNumeroFactura,
    filtroCliente,
    filtroCobrada,
    filtroAno,
    filtroIntermediario,
    todasOportunidades,
    intermediarios,
  ]);

  const calcularTotales = () => {
    let totalVenta = 0;
    let totalComision = 0;
    let totalNeto = 0;
    let totalCobrado = 0;

    oportunidadesFiltradas.forEach(({ importe, facturafechacobro, comision }) => {
      totalVenta += importe || 0;
      totalComision += importe * (comision / 100);
      if (facturafechacobro) {
        totalCobrado += importe || 0;
      }
    });

    totalNeto = totalVenta - totalComision;

    return {
      totalVenta,
      totalComision,
      totalNeto,
      totalCobrado,
    };
  };

  const totales = calcularTotales();
  const fecha = { day: "2-digit", month: "2-digit", year: "numeric" };

  if (todasOportunidades.length === 0 || intermediarios.length === 0) return <Loader />;

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check" style={{ marginBottom: "5px" }}>
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMeses.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={(e) => handleMesChange(e.target.value)}
          style={{ marginLeft: "-15px" }} // Ajusta la posición del checkbox
        />
        <label
          className="form-check-label"
          htmlFor={`mes${index}`}
          style={{ marginLeft: "10px" }} // Añade margen entre el checkbox y el texto
        >
          {mes}
        </label>
      </div>
    ));

  const handleMesChange = (mes) => {
    setFiltroMeses((prev) =>
      prev.includes(mes) ? prev.filter((item) => item !== mes) : [...prev, mes]
    );
  };

  return (
    <div className="container-facturas-detalle">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div className="table-responsive">
        {/* Mini Dashboard */}
        <div className="row justify-content-center mb-3">
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                BRUTO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalVenta.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                INTERMEDIACIÓN
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalComision.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                NETO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalNeto.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                COBRADO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalCobrado.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                PENDIENTE DE COBRO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${(
                  totales.totalVenta - totales.totalCobrado
                ).toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-2 mb-4">
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroNumeroFactura}
              onChange={(e) => setFiltroNumeroFactura(e.target.value)}
            >
              <option value="">Número de Factura</option>
              {numerosFacturaUnicos.map((numero) => (
                <option key={numero} value={numero}>{numero}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroCliente}
              onChange={(e) => setFiltroCliente(e.target.value)}
            >
              <option value="">Cliente Publicitario</option>
              {clientesUnicos.map((cliente) => (
                <option key={cliente} value={cliente}>{cliente}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="white border"
                id="dropdown-basic"
                className={`form-control text-start ${filtroMeses.length > 0 ? "active-filter" : ""}`}
              >
                {filtroMeses.length > 0 ? filtroMeses.map((mes) => mesEnLetras(mes)).join(", ") : "Mes/meses"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ maxHeight: "370px", overflowY: "auto" }}>
                {renderMesOptions()}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroIntermediario}
              onChange={(e) => setFiltroIntermediario(e.target.value)}
            >
              <option value="">Intermediario</option>
              {intermediariosUnicos.map((intermediario) => (
                <option key={intermediario} value={intermediario}>
                  {intermediario}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroAno}
              onChange={(e) => setFiltroAno(e.target.value)}
            >
              <option value="">HTD</option>
              {["2024", "2025"].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroCobrada}
              onChange={(e) => setFiltroCobrada(e.target.value)}
            >
              <option value="">Cobrada</option>
              <option value="Sí">Sí</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <table className="table table-hover table-bordered table-sm">
          <thead className="table-dark text-center align-middle">
            <tr>
              <th className="col-status">STATUS</th>
              <th className="col-numero-factura">NÚMERO FACTURA</th>
              <th className="col-fecha-factura">FECHA FACTURA</th>
              <th className="col-cliente">CLIENTE PUBLICITARIO</th>
              <th className="col-intermediario">INTERMEDIARIO</th>
              <th className="col-venta">VENTA</th>
              <th className="col-comision">COMISIÓN {moneda(pais)}</th>
              <th className="col-neto">NETO</th>
              <th className="col-cobrada">COBRADA</th>
              <th className="col-editar">EDITAR</th>
            </tr>
          </thead>
          <tbody>
            {oportunidadesFiltradas.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center">
                  No hay facturas ni ventas según los filtros seleccionados :(
                </td>
              </tr>
            ) : (
              oportunidadesFiltradas.map(
                ({
                  estatus,
                  mes,
                  cliente,
                  comision,
                  facturafecha,
                  facturafechacobro,
                  intermediario,
                  id,
                  importe,
                  plazocobro,
                  facturanumero,
                }) => (
                  <tr key={id}>
                    <td className="col-status bg-success text-white">{estatus}</td>
                    <td className="col-numero-factura">{facturanumero ? facturanumero : "-"}</td>
                    <td className="col-fecha-factura">
                      {facturafecha
                        ? new Date(facturafecha).toLocaleDateString("es-ES", fecha)
                        : "-"}
                    </td>
                    <td className="col-cliente">{cliente}</td>
                    <td className="col-intermediario">{intermediario}</td>
                    <td className="col-venta text-end">{`${moneda(pais)} ${importe?.toLocaleString(
                      localString(pais),
                      {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      }
                    ) || "error"}`}</td>
                    <td className="col-comision text-end">
                      {intermediario === "DIRECTO"
                        ? "-"
                        : `${moneda(pais)} ${(
                          importe * (comision / 100)
                        ).toLocaleString(localString(pais), {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        })}`}
                    </td>
                    <td className="col-neto text-end">
                      {`${moneda(pais)} ${(importe - importe * (comision / 100)).toLocaleString(
                        localString(pais),
                        {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        }
                      )}`}
                    </td>
                    <td
                      className="col-cobrada"
                      style={{
                        backgroundColor: facturafechacobro
                          ? "#198754" // Verde para cobrado
                          : facturafecha
                            ? "#dc3545" // Rojo para pendiente
                            : "#4c5257", // Gris para sin facturar
                        color: "white", // Texto blanco para todos los estados
                      }}
                    >
                      {facturafechacobro
                        ? new Date(facturafechacobro).toLocaleDateString("es-ES", fecha)
                        : facturafecha
                          ? "Sin cobrar"
                          : "Sin facturar"}
                    </td>
                    <td className="col-editar text-center align-middle">
                      <FaEdit onClick={() => goToOportunidad(id)} />
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};