/* eslint-disable unicorn/prefer-module */
/* eslint-disable no-console */
import { useMemo, useState } from "react";
import { VentasTabla, VentasTop5 } from "../../components";
import { useAuthContext } from "../../context/auth";
import "./stylesVentasProyeccion.scss";

const VentasProyeccion = () => {
  const [filtroAno, setFiltroAno] = useState("2025");

  const { pais } = useAuthContext() || {};

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  return (
    <div className="ventas-proyeccion">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <VentasTabla filtroAno={filtroAno} setFiltroAno={setFiltroAno} />
      <VentasTop5 filtroAno={filtroAno} />
    </div>
  );
};

export default VentasProyeccion;
