/* eslint-disable indent */
/* eslint-disable no-console */
import { useEffect, useState, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { FaEdit } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts";
import { useAuthContext } from "../../context/auth";
import {
  getClientesPublicitarios,
  getIntermediarios,
  updateCliente,
  getOportunidades,
  getCategorias,
  getUsers,
} from "../../utils";
import "./stylesVentasMiListaClientes.scss";

export const VentasMiListaClientes = ({ filtroEjecutivo }) => {
  const [clientes, setClientesPublicitarios] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingClienteId, setEditingClienteId] = useState(null);
  const [ultimo_contacto_fecha, setUltimoContactoFecha] = useState(null);
  const [ultimo_contacto_accion, setUltimoContactoAccion] = useState("");
  const [todasLasOportunidades, setTodasLasOportunidades] = useState([]);
  const [ventasPorCliente, setVentasPorCliente] = useState({});
  const [oportunidadesRelevantes, setOportunidadesRelevantes] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [sumasFiltradas, setSumasFiltradas] = useState({
    "100%": 0,
    "90%": 0,
    "75%": 0,
    "50%": 0,
    "0%": 0,
  });
  const [proximo_contacto_fecha, setProximoContactoFecha] = useState(null);
  const [proximo_contacto_accion, setProximoContactoAccion] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [data, setData] = useState([
    {
      name: "Total",
      "100%": 0,
      "90%": 0,
      "75%": 0,
      "50%": 0,
      "0%": 0,
      empty: 100,
    },
  ]);
  const [tipoCliente, setTipoCliente] = useState("");
  const [filtroCategoriaCliente, setFiltroCategoriaCliente] = useState([]);

  const { pais, rol, id } = useAuthContext() || {};

  const getPaisCode = (countryName) => {
    switch (countryName.toLowerCase()) {
      case "Uruguay":
        return "URU";
      case "Chile":
        return "CHI";
      case "Perú":
        return "PER";
      default:
        return countryName;
    }
  };

  const toUpperCase = (text) => text?.toString().toUpperCase();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "";
  }, []);

  const getDaysDifference = (dateString, isFuture = false) => {
    if (!dateString) return "-";

    // Normalizar ambas fechas a medianoche en UTC para evitar problemas de zona horaria
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // Establecer a medianoche UTC

    const targetDate = new Date(dateString);
    targetDate.setUTCHours(0, 0, 0, 0); // Establecer a medianoche UTC

    // Asegurarse de que las fechas sean válidas
    if (isNaN(targetDate.getTime())) return "-";

    const diffTime = targetDate - today;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (isFuture) {
      // Lógica para "Próximo Contacto" (mantener como está)
      if (diffDays < 0) {
        return "Contactar!";
      }

      if (diffDays === 0 || diffDays === 1) {
        return diffDays === 0 ? "Hoy!" : "Mañana";
      }

      // Calcular el inicio y fin de la semana actual (lunes al domingo)
      const startOfCurrentWeek = new Date(today);
      startOfCurrentWeek.setUTCDate(today.getUTCDate() - ((today.getUTCDay() + 6) % 7)); // Lunes de la semana actual
      const endOfCurrentWeek = new Date(startOfCurrentWeek);
      endOfCurrentWeek.setUTCDate(startOfCurrentWeek.getUTCDate() + 6); // Domingo de la semana actual

      // Calcular el inicio y fin de la próxima semana (lunes al domingo)
      const startOfNextWeek = new Date(endOfCurrentWeek);
      startOfNextWeek.setUTCDate(endOfCurrentWeek.getUTCDate() + 1); // Lunes de la próxima semana
      const endOfNextWeek = new Date(startOfNextWeek);
      endOfNextWeek.setUTCDate(startOfNextWeek.getUTCDate() + 6); // Domingo de la próxima semana

      if (targetDate > today && targetDate >= startOfCurrentWeek && targetDate <= endOfCurrentWeek) {
        return "Esta semana";
      }
      if (targetDate > today && targetDate >= startOfNextWeek && targetDate <= endOfNextWeek) {
        return "Próxima semana";
      }
      if (targetDate > today) {
        return "15 días o más";
      }
    } else {
      // Lógica para "Último Contacto" con las nuevas reglas
      if (diffDays === 0) {
        return "Hoy"; // Si es hoy
      }
      if (diffDays === -1) {
        return "Ayer"; // Si es ayer
      }
      if (diffDays >= -5 && diffDays <= -2) {
        return "Esta semana"; // Entre -2 y -5 días
      }
      if (diffDays >= -15 && diffDays <= -6) {
        return "Semana pasada"; // Entre -6 y -12 días
      }
      if (diffDays < -15) {
        return "15 días o más"; // Más viejo que 12 días
      }
    }
    return "-"; // Caso por defecto (no debería llegar aquí)
  };

  const getStatusFromText = (text, isFuture = false) => {
    if (!text || text === "-") return "default";

    const normalizedText = text.toLowerCase(); // Normalizar a minúsculas

    if (isFuture) {
      if (normalizedText.startsWith("contactar!")) return "expired";
      if (normalizedText === "hoy!" || normalizedText === "mañana") return "todayTomorrow";
      if (normalizedText === "esta semana") return "thisWeek";
      if (normalizedText === "próxima semana") return "nextWeek";
      if (normalizedText === "15 días o más") return "later";
    }
    return "default"; // Para "Último Contacto" o casos no identificados
  };

  const handleMesChange = useCallback((e) => {
    const selectedMonths = Array.from(
      e.target.closest(".dropdown-menu").querySelectorAll("input[type=checkbox]:checked"),
      (checkbox) => checkbox.value
    );
    setFiltroMes(selectedMonths);
  }, []);

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMes.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={handleMesChange}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`mes${index}`} style={{ marginLeft: "10px" }}>
          {mes}
        </label>
      </div>
    ));

  const renderClientesOptions = () => {
    const clientesOrdenados = [...new Set(todasLasOportunidades.map((oportunidad) => oportunidad.cliente))].sort();
    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const renderIntermediariosOptions = () => {
    const intermediariosOrdenados = [
      ...new Set(todasLasOportunidades.map((oportunidad) => oportunidad.intermediario)),
    ].sort();
    return intermediariosOrdenados.map((intermediario, index) => (
      <option key={index} value={intermediario}>
        {intermediario}
      </option>
    ));
  };

  const renderCategoriaClienteOptions = () =>
    ["A", "B", "C"].map((categoria, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`categoriaCliente${categoria}`}
          value={categoria}
          checked={filtroCategoriaCliente.includes(categoria)}
          onChange={handleCategoriaClienteChange}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`categoriaCliente${categoria}`} style={{ marginLeft: "10px" }}>
          {categoria}
        </label>
      </div>
    ));

  const handleCategoriaClienteChange = (e) => {
    const categoria = e.target.value;
    setFiltroCategoriaCliente((prev) =>
      prev.includes(categoria) ? prev.filter((c) => c !== categoria) : [...prev, categoria]
    );
  };

  const handleEstatusChange = (e) => {
    const value = e.target.value;
    setFiltroEstatus((prev) => (prev.includes(value) ? prev.filter((estatus) => estatus !== value) : [...prev, value]));
  };

  const renderEstatusOptions = () =>
    ["100%", "90%", "75%", "50%", "0%"].map((estatus, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`estatus${index}`}
          value={estatus}
          checked={filtroEstatus.includes(estatus)}
          onChange={handleEstatusChange}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`estatus${index}`} style={{ marginLeft: "10px" }}>
          {estatus}
        </label>
      </div>
    ));

  const renderCategoriaOptions = () =>
    categorias.map((categoria, index) => (
      <option key={index} value={categoria.nombre}>
        {categoria.nombre}
      </option>
    ));

  const handleCategoriaChange = (e) => {
    setFiltroCategoria(e.target.value);
  };

  const handleUpdateCliente = async (
    clienteId,
    newEjecutivo,
    newAgencia,
    ultimoFecha,
    ultimoAccion,
    proximoFecha,
    proximoAccion,
    newContactoId,
    newTipo
  ) => {
    try {
      const cliente = clientes.find((c) => c.id === clienteId);

      const updatedData = {
        id: clienteId,
        ejecutivo: newEjecutivo !== undefined ? newEjecutivo : cliente.ejecutivo,
        agencia: newAgencia !== undefined ? newAgencia : cliente.agencia,
        ultimo_contacto_fecha: ultimoFecha !== null ? ultimoFecha : cliente.ultimo_contacto_fecha,
        ultimo_contacto_accion: ultimoAccion !== "" ? ultimoAccion : cliente.ultimo_contacto_accion,
        proximo_contacto_fecha: proximoFecha !== null ? proximoFecha : cliente.proximo_contacto_fecha,
        proximo_contacto_accion: proximoAccion !== "" ? proximoAccion : cliente.proximo_contacto_accion,
        contactoId: newContactoId !== null ? newContactoId : cliente.contactoId,
        categoria_cliente: newTipo !== "" ? newTipo : cliente.categoria_cliente,
      };

      await updateCliente(updatedData);

      setClientesPublicitarios(
        clientes.map((cliente) => (cliente.id === clienteId ? { ...cliente, ...updatedData } : cliente))
      );
      setEditingClienteId(null);
      setUltimoContactoFecha(null);
      setUltimoContactoAccion("");
      setProximoContactoFecha(null);
      setProximoContactoAccion("");
      setTipoCliente("");
    } catch (error) {
      console.error("Error updating cliente:", error.message);
    }
  };

  const calculateVentasYOportunidades = (oportunidades, clientes) => {
    const ventasCliente = {};
    const oportunidadesCliente = {};
    oportunidades.forEach((oportunidad) => {
      if (oportunidad.ano === 2025) {
        const clienteMatch = clientes.find((c) => c.nombre === oportunidad.cliente);
        if (clienteMatch) {
          const clienteId = clienteMatch.id;
          if (oportunidad.estatus === "100%") {
            ventasCliente[clienteId] = (ventasCliente[clienteId] || 0) + Number(oportunidad.importe);
          }
          if (["75%", "90%"].includes(oportunidad.estatus)) {
            oportunidadesCliente[clienteId] = (oportunidadesCliente[clienteId] || 0) + Number(oportunidad.importe);
          }
        }
      }
    });
    return [ventasCliente, oportunidadesCliente];
  };

  const filtrarOportunidades = () =>
    todasLasOportunidades.filter(
      (oportunidad) =>
        (!filtroCliente || oportunidad.cliente.toLowerCase().includes(filtroCliente.toLowerCase())) &&
        (filtroMes.length === 0 || filtroMes.includes(oportunidad.mes.padStart(2, "0"))) &&
        (filtroEstatus.length === 0 || filtroEstatus.includes(oportunidad.estatus)) &&
        (filtroIntermediario === "" || oportunidad.intermediario === filtroIntermediario) &&
        (filtroCategoria === "" || oportunidad.categoria === filtroCategoria)
    );

  const calcularSumasFiltradas = useCallback(() => {
    const sumas = {
      "100%": 0,
      "90%": 0,
      "75%": 0,
      "50%": 0,
      "0%": 0,
    };

    todasLasOportunidades.forEach((oportunidad) => {
      if (
        oportunidad.ano === 2025 &&
        (!filtroCliente || oportunidad.cliente.toLowerCase().includes(filtroCliente.toLowerCase())) &&
        (filtroMes.length === 0 || filtroMes.includes(oportunidad.mes.padStart(2, "0"))) &&
        (filtroEstatus.length === 0 || filtroEstatus.includes(oportunidad.estatus)) &&
        (filtroIntermediario === "" || oportunidad.intermediario === filtroIntermediario) &&
        (filtroCategoria === "" || oportunidad.categoria === filtroCategoria)
      ) {
        sumas[oportunidad.estatus] += Number(oportunidad.importe);
      }
    });

    setSumasFiltradas(sumas);
  }, [todasLasOportunidades, filtroCliente, filtroMes, filtroEstatus, filtroIntermediario, filtroCategoria]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const paisCode = getPaisCode(pais);
        const [categoriasData, clientesData, intermediariosData, oportunidadesData, usuariosData] = await Promise.all([
          getCategorias(),
          getClientesPublicitarios({ pais: paisCode }),
          getIntermediarios({ pais: paisCode }),
          getOportunidades({ pais: paisCode }),
          getUsers(),
        ]);

        const oportunidadesNoEliminadas = oportunidadesData.filter((oportunidad) => !oportunidad.eliminada);

        // Filtrar oportunidades basadas en el ejecutivo seleccionado
        const oportunidadesFiltradas = filtroEjecutivo
          ? oportunidadesNoEliminadas.filter((op) => op.ejecutivo === filtroEjecutivo)
          : oportunidadesNoEliminadas;

        setTodasLasOportunidades(oportunidadesFiltradas);
        setCategorias(categoriasData);

        let filteredClientes = clientesData;

        if (rol === "gerencia" || rol === "superadmin") {
          if (filtroEjecutivo) {
            filteredClientes = filteredClientes.filter((cliente) => cliente.ejecutivo === filtroEjecutivo);
          }
        } else if (rol === "comercial") {
          filteredClientes = clientesData.filter((cliente) => cliente.ejecutivo === id.toString());
          setTodasLasOportunidades(oportunidadesNoEliminadas.filter((op) => op.ejecutivo === id.toString()));
        }

        const [ventasCliente, oportunidadesCliente] = calculateVentasYOportunidades(
          oportunidadesFiltradas,
          filteredClientes
        );

        setClientesPublicitarios(filteredClientes);
        setIntermediarios(intermediariosData);
        setLoading(false);
        setOportunidadesRelevantes(oportunidadesCliente);
        setUsuarios(usuariosData);
        setVentasPorCliente(ventasCliente);
      } catch (error) {
        console.error("Error al inicializar datos:", error);
        toast.error("Hubo un error al cargar los datos. Inténtalo de nuevo.");
        setLoading(false);
      }
    };

    fetchData();
  }, [pais, id, rol, filtroEjecutivo]);

  useEffect(() => {
    calcularSumasFiltradas();
  }, [calcularSumasFiltradas]);

  useEffect(() => {
    const filteredOportunidades = filtrarOportunidades();
    const [newVentasCliente, newOportunidadesCliente] = calculateVentasYOportunidades(filteredOportunidades, clientes);
    setVentasPorCliente(newVentasCliente);
    setOportunidadesRelevantes(newOportunidadesCliente);
  }, [clientes, filtroCategoria, filtroCliente, filtroEstatus, filtroIntermediario, filtroMes, todasLasOportunidades]);

  const [ordenamiento, setOrdenamiento] = useState({
    criterio: "100%",
    direccion: "desc",
    porFecha: null,
  });

  const filtrarClientes = useCallback(() => {
    let sortedClientes = clientes.filter((cliente) => {
      const clienteMatch = !filtroCliente || cliente.nombre.toLowerCase().includes(filtroCliente.toLowerCase());
      const categoriaClienteMatch =
        filtroCategoriaCliente.length === 0 || filtroCategoriaCliente.includes(cliente.categoria_cliente);
      const categoriaMatch = !filtroCategoria || cliente.categoria === filtroCategoria;
      const agenciaMatch = !filtroIntermediario || cliente.agencia === filtroIntermediario;
      const mesMatch =
        filtroMes.length === 0 ||
        todasLasOportunidades.some(
          (op) => op.cliente === cliente.nombre && filtroMes.includes(op.mes.padStart(2, "0")) && op.ano === 2025
        );
      const estatusMatch =
        filtroEstatus.length === 0 ||
        todasLasOportunidades.some(
          (op) => op.cliente === cliente.nombre && filtroEstatus.includes(op.estatus) && op.ano === 2025
        );

      // Solo filtramos si al menos uno de los filtros está activo
      return filtroCliente ||
        filtroCategoria ||
        filtroCategoriaCliente.length > 0 ||
        filtroIntermediario ||
        filtroMes.length > 0 ||
        filtroEstatus.length > 0
        ? clienteMatch && categoriaMatch && categoriaClienteMatch && agenciaMatch && mesMatch && estatusMatch
        : true; // Si no hay filtros activos, mostramos todo
    });

    const parseDate = (dateString, isFuture = false) => {
      if (!dateString) return isFuture ? new Date(8640000000000000) : new Date(-8640000000000000);
      return new Date(dateString);
    };

    sortedClientes = !ordenamiento.porFecha
      ? sortedClientes.sort((a, b) => {
          const a100 = ventasPorCliente[a.id] || 0;
          const b100 = ventasPorCliente[b.id] || 0;
          if (a100 !== b100) {
            return ordenamiento.direccion === "desc" ? b100 - a100 : a100 - b100;
          }
          const a90_75 = oportunidadesRelevantes[a.id] || 0;
          const b90_75 = oportunidadesRelevantes[b.id] || 0;
          return ordenamiento.direccion === "desc" ? b90_75 - a90_75 : a90_75 - b90_75;
        })
      : sortedClientes.sort((a, b) => {
          const isLastContact = ordenamiento.porFecha && ordenamiento.porFecha.includes("last");
          const isOldest = ordenamiento.porFecha && ordenamiento.porFecha.includes("Oldest");
          const dateField = isLastContact ? "ultimo_contacto_fecha" : "proximo_contacto_fecha";

          const aDate = a[dateField] ? parseDate(a[dateField], !isLastContact) : null;
          const bDate = b[dateField] ? parseDate(b[dateField], !isLastContact) : null;

          // Manejo de fechas nulas o indefinidas
          if (aDate === null && bDate === null) return 0;
          if (aDate === null) return 1;
          if (bDate === null) return -1;

          // Comparación de fechas válidas
          return isLastContact ? (isOldest ? aDate - bDate : bDate - aDate) : isOldest ? bDate - aDate : aDate - bDate;
        });

    return sortedClientes;
  }, [
    clientes,
    filtroCategoria,
    filtroCategoriaCliente,
    filtroCliente,
    filtroEstatus,
    filtroIntermediario,
    filtroMes,
    oportunidadesRelevantes,
    ordenamiento,
    todasLasOportunidades,
    ventasPorCliente,
  ]);

  const getRadius = (key, data) => {
    const keys = Object.keys(data).filter((key) => data[key] > 0);
    const firstKey = keys[0];
    const lastKey = keys.at(-1);
    if (firstKey === key && lastKey === key) {
      return [10, 10, 10, 10];
    } else if (firstKey === key) {
      return [10, 0, 0, 10];
    } else if (lastKey === key) {
      return [0, 10, 10, 0];
    }
    return [0, 0, 0, 0];
  };

  const getBootstrapClassForStatus = (status) => {
    switch (status) {
      case "100%":
        return "bg-success";
      case "90%":
        return "bg-danger";
      case "75%":
        return "bg-primary";
      case "50%":
        return "bg-warning";
      case "0%":
        return "bg-black";
      default:
        return "bg-secondary";
    }
  };

  const getColorForStatus = (status) => {
    switch (status) {
      case "100%":
        return "#198754"; // Verde
      case "90%":
        return "#dc3545"; // Rojo
      case "75%":
        return "#0d6efd"; // Azul
      case "50%":
        return "#ffc107"; // Amarillo
      case "0%":
        return "#000000"; // Negro
      default:
        return "#555555"; // Gris por defecto
    }
  };

  const getLabelColorForStatus = (status) => (status === "50%" ? "#000" : "#fff");

  useEffect(() => {
    const newData = [
      {
        name: "Total",
        "100%": (sumasFiltradas["100%"] / (Object.values(sumasFiltradas).reduce((a, b) => a + b, 0) || 1)) * 100 || 0,
        "90%": (sumasFiltradas["90%"] / (Object.values(sumasFiltradas).reduce((a, b) => a + b, 0) || 1)) * 100 || 0,
        "75%": (sumasFiltradas["75%"] / (Object.values(sumasFiltradas).reduce((a, b) => a + b, 0) || 1)) * 100 || 0,
        "50%": (sumasFiltradas["50%"] / (Object.values(sumasFiltradas).reduce((a, b) => a + b, 0) || 1)) * 100 || 0,
        "0%": (sumasFiltradas["0%"] / (Object.values(sumasFiltradas).reduce((a, b) => a + b, 0) || 1)) * 100 || 0,
        empty: Object.values(sumasFiltradas).reduce((a, b) => a + b, 0) === 0 ? 100 : 0,
      },
    ];
    setData(newData);
  }, [sumasFiltradas]);

  return (
    <div>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <>
          <div className="row justify-content-center mt-2 mb-2">
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <select
                className={`form-control ${filtroCliente ? "active-filter" : ""}`}
                value={filtroCliente}
                onChange={(e) => setFiltroCliente(e.target.value)}
              >
                <option value="">Cliente</option>
                {renderClientesOptions()}
              </select>
            </div>
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="white border"
                  id="dropdown-basic"
                  className={`form-control text-start ${filtroMes.length > 0 ? "active-filter" : ""}`}
                >
                  {filtroMes.length > 0 ? filtroMes.map((mes) => mesEnLetras(mes)).join(", ") : "Mes/meses"}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderMesOptions()}</Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="white border"
                  id="dropdown-status"
                  className={`form-control text-start ${filtroEstatus.length > 0 ? "active-filter" : ""}`}
                >
                  {filtroEstatus.length > 0 ? filtroEstatus.join(", ") : "Status"}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderEstatusOptions()}</Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <select
                className={`form-control ${filtroCategoria ? "active-filter" : ""}`}
                value={filtroCategoria}
                onChange={handleCategoriaChange}
              >
                <option value="">Categoría</option>
                {renderCategoriaOptions()}
              </select>
            </div>
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <select
                className={`form-control ${filtroIntermediario ? "active-filter" : ""}`}
                value={filtroIntermediario}
                onChange={(e) => setFiltroIntermediario(e.target.value)}
              >
                <option value="">Intermediario</option>
                {renderIntermediariosOptions()}
              </select>
            </div>
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <select
                className={`form-control ${ordenamiento.porFecha ? "active-filter" : ""}`}
                value={ordenamiento.porFecha || ""}
                onChange={(e) => setOrdenamiento({ ...ordenamiento, porFecha: e.target.value || null })}
              >
                <option value="">Contacto</option>
                <option value="lastRecent">Último + reciente</option>
                <option value="lastOldest">Último - reciente</option>
                <option value="nextRecent">Próximo + reciente</option>
                <option value="nextOldest">Próximo - reciente</option>
              </select>
            </div>
            <div className="col-md-2" style={{ width: "12.5%" }}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="white border"
                  id="dropdown-categoria-cliente"
                  className={`form-control text-start ${filtroCategoriaCliente.length > 0 ? "active-filter" : ""}`}
                >
                  {filtroCategoriaCliente.length > 0 ? filtroCategoriaCliente.join(", ") : "Tipo Cliente"}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderCategoriaClienteOptions()}</Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="mb-2 d-flex justify-content-center">
            <div className="d-flex justify-content-between">
              {["100%", "90%", "75%", "50%", "0%"].map((estatus, index) => (
                <div
                  key={index}
                  className={`text-center p-2 ${getBootstrapClassForStatus(estatus)} border border-dark m-3 text-${estatus === "50%" ? "dark" : "white"}`}
                  style={{ borderRadius: "5px", width: "200px" }}
                >
                  <div>
                    <strong>{estatus}</strong>
                  </div>
                  <div>
                    {sumasFiltradas[estatus] > 0
                      ? `${moneda(pais)} ${sumasFiltradas[estatus].toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <ResponsiveContainer width="98%" height={80} className="p-0 m-0">
              <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <XAxis type="number" domain={[0, 100]} tick={false} axisLine={false} />
                <YAxis type="category" dataKey="name" hide />
                {data[0].empty > 0 ? (
                  <Bar dataKey="empty" fill="#555555" isAnimationActive={false} radius={[10, 10, 10, 10]}>
                    <LabelList dataKey="empty" position="center" formatter={() => "SIN DATOS"} fill="#FFF" />
                  </Bar>
                ) : (
                  ["100%", "90%", "75%", "50%", "0%"].map(
                    (estatus) =>
                      data[0][estatus] > 0 && (
                        <Bar
                          key={estatus}
                          dataKey={estatus}
                          stackId="a"
                          fill={getColorForStatus(estatus)}
                          radius={getRadius(estatus, data[0])}
                        >
                          <LabelList
                            dataKey={estatus}
                            position="center"
                            formatter={(value) => `${Math.round(value)}%`}
                            fill={getLabelColorForStatus(estatus)}
                          />
                        </Bar>
                      )
                  )
                )}
              </BarChart>
            </ResponsiveContainer>
          </div>

          <table className="ventas-mi-lista-clientes-table table table-bordered table-hover table-striped">
            <thead className="table-dark">
              <tr>
                <th className="text-center col-cliente">{toUpperCase("CLIENTE")}</th>
                <th className="text-center col-contacto">{toUpperCase("CONTACTO")}</th>
                <th className="text-center col-tipo">{toUpperCase("TIPO")}</th>
                <th className="text-center bg-success text-white col-importes">{toUpperCase("100%")}</th>
                <th className="text-center col-importes">{toUpperCase("90% + 75%")}</th>
                <th colSpan="2" className="text-center">
                  {toUpperCase("ÚLTIMO CONTACTO")}
                </th>
                <th colSpan="2" className="text-center">
                  {toUpperCase("PRÓXIMO CONTACTO")}
                </th>
                <th className="text-center col-edit">{toUpperCase("EDIT")}</th>
              </tr>
            </thead>
            <tbody>
              {filtrarClientes().map((cliente, index) => (
                <tr key={index}>
                  <td className="text-start">{toUpperCase(cliente.nombre)}</td>
                  <td className="text-start">
                    {editingClienteId === cliente.id &&
                    (rol === "superadmin" || rol === "gerencia" || rol === "comercial") ? (
                      <select
                        className="small-input"
                        value={cliente.contactoId || ""}
                        onChange={(e) => {
                          const newContactoId = e.target.value;
                          setClientesPublicitarios(
                            clientes.map((c) =>
                              c.id === cliente.id ? { ...c, contactoId: Number(newContactoId) || null } : c
                            )
                          );
                        }}
                      >
                        <option className="text-start" value="">
                          Seleccionar
                        </option>
                        {usuarios
                          .filter((user) => ["agencia", "cliente"].includes(user.rol))
                          .sort((a, b) => `${a.nombre} ${a.apellido}`.localeCompare(`${b.nombre} ${b.apellido}`))
                          .map((user) => {
                            const intermediario = intermediarios.find(
                              (inter) => String(inter.id) === String(user.agencia)
                            );
                            let intermediarioDisplay = "";

                            if (intermediario) {
                              intermediarioDisplay = intermediario.nombre.includes("|")
                                ? intermediario.nombre.split("|")[1].trim()
                                : intermediario.nombre;
                            }

                            return (
                              <option key={user.id} value={user.id} className="text-start">
                                {`${user.nombre} ${user.apellido}${intermediarioDisplay ? `, ${intermediarioDisplay}` : ""}`}
                              </option>
                            );
                          })}
                      </select>
                    ) : cliente.contactoId && usuarios.find((user) => user.id === cliente.contactoId) ? (
                      (() => {
                        const contacto = usuarios.find((user) => user.id === cliente.contactoId);
                        const intermediario = intermediarios.find((inter) => inter.id === contacto.agencia);
                        let intermediarioDisplay = "";

                        if (intermediario) {
                          intermediarioDisplay = intermediario.nombre.includes("|")
                            ? intermediario.nombre.split("|")[1].trim()
                            : intermediario.nombre;
                        }

                        return `${contacto.nombre} ${contacto.apellido}${intermediarioDisplay ? `, ${intermediarioDisplay}` : ""}`;
                      })()
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-center">
                    {editingClienteId === cliente.id &&
                    (rol === "superadmin" || rol === "gerencia" || rol === "comercial") ? (
                      <div className="edit-tipo">
                        <select
                          value={tipoCliente}
                          className="small-input"
                          onChange={(e) => setTipoCliente(e.target.value)}
                        >
                          <option value="">Seleccionar</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                        </select>
                      </div>
                    ) : (
                      toUpperCase(cliente.categoria_cliente || "-")
                    )}
                  </td>
                  <td className="text-end">
                    {ventasPorCliente[cliente.id] !== undefined && ventasPorCliente[cliente.id] !== 0
                      ? `${moneda(pais)} ${ventasPorCliente[cliente.id].toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                      : "-"}
                  </td>
                  <td className="text-end">
                    {oportunidadesRelevantes[cliente.id] !== undefined && oportunidadesRelevantes[cliente.id] !== 0
                      ? `${moneda(pais)} ${oportunidadesRelevantes[cliente.id].toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                      : "-"}
                  </td>
                  {/* Último Contacto */}
                  <td className="text-center">
                    {editingClienteId === cliente.id &&
                    (rol === "superadmin" || rol === "gerencia" || rol === "comercial") ? (
                      <div className="edit-ultimo-contacto">
                        <DatePicker
                          selected={ultimo_contacto_fecha}
                          onChange={(date) => setUltimoContactoFecha(date)}
                          dateFormat="dd MMM"
                          className="small-input"
                          calendarStartDay={1} // Semana de lunes a domingo
                          maxDate={new Date()} // Restringe a hoy o fechas pasadas
                        />
                      </div>
                    ) : cliente.ultimo_contacto_fecha ? (
                      getDaysDifference(cliente.ultimo_contacto_fecha, false)
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-center">
                    {editingClienteId === cliente.id &&
                    (rol === "superadmin" || rol === "gerencia" || rol === "comercial") ? (
                      <div className="edit-ultimo-contacto">
                        <select
                          value={ultimo_contacto_accion}
                          className="small-input"
                          onChange={(e) => setUltimoContactoAccion(e.target.value)}
                        >
                          <option value="">Seleccionar</option>
                          <option value="RRSS">{toUpperCase("RRSS")}</option>
                          <option value="CALL">{toUpperCase("CALL")}</option>
                          <option value="MAIL">{toUpperCase("MAIL")}</option>
                          <option value="MEET">{toUpperCase("MEET")}</option>
                          <option value="QUOTE">{toUpperCase("QUOTE")}</option>
                          <option value="SALE!">{toUpperCase("SALE!")}</option>
                        </select>
                      </div>
                    ) : (
                      toUpperCase(cliente.ultimo_contacto_accion || "-")
                    )}
                  </td>
                  {/* Próximo Contacto */}
                  <td className="text-center">
                    {editingClienteId === cliente.id &&
                    (rol === "superadmin" || rol === "gerencia" || rol === "comercial") ? (
                      <div className="edit-proximo-contacto">
                        <DatePicker
                          selected={proximo_contacto_fecha}
                          onChange={(date) => setProximoContactoFecha(date)}
                          dateFormat="dd MMM"
                          className="small-input"
                          calendarStartDay={1}
                        />
                      </div>
                    ) : cliente.proximo_contacto_fecha ? (
                      (() => {
                        const text = getDaysDifference(cliente.proximo_contacto_fecha, true);
                        const status = getStatusFromText(text, true);
                        return <span className={`contacto-status ${status}`}>{text}</span>;
                      })()
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-center">
                    {editingClienteId === cliente.id &&
                    (rol === "superadmin" || rol === "gerencia" || rol === "comercial") ? (
                      <div className="edit-proximo-contacto">
                        <select
                          value={proximo_contacto_accion}
                          className="small-input"
                          onChange={(e) => setProximoContactoAccion(e.target.value)}
                        >
                          <option value="">Seleccionar</option>
                          <option value="RRSS">{toUpperCase("RRSS")}</option>
                          <option value="CALL">{toUpperCase("CALL")}</option>
                          <option value="MAIL">{toUpperCase("MAIL")}</option>
                          <option value="MEET">{toUpperCase("MEET")}</option>
                          <option value="QUOTE">{toUpperCase("QUOTE")}</option>
                          <option value="SALE!">{toUpperCase("SALE!")}</option>
                        </select>
                      </div>
                    ) : (
                      toUpperCase(cliente.proximo_contacto_accion || "-")
                    )}
                  </td>
                  {/* Fin de Próximo Contacto */}
                  <td className="text-center">
                    {(rol === "superadmin" || rol === "gerencia" || rol === "comercial") &&
                    String(id) === cliente.ejecutivo ? (
                      editingClienteId === cliente.id ? (
                        <FiSave
                          onClick={() =>
                            handleUpdateCliente(
                              cliente.id,
                              cliente.ejecutivo,
                              cliente.agencia,
                              ultimo_contacto_fecha,
                              ultimo_contacto_accion,
                              proximo_contacto_fecha,
                              proximo_contacto_accion,
                              cliente.contactoId,
                              tipoCliente
                            )
                          }
                        />
                      ) : (
                        <FaEdit
                          onClick={() => {
                            setEditingClienteId(cliente.id);
                            setUltimoContactoFecha(
                              cliente.ultimo_contacto_fecha ? new Date(cliente.ultimo_contacto_fecha) : null
                            );
                            setUltimoContactoAccion(cliente.ultimo_contacto_accion || "");
                            setProximoContactoFecha(
                              cliente.proximo_contacto_fecha ? new Date(cliente.proximo_contacto_fecha) : null
                            );
                            setProximoContactoAccion(cliente.proximo_contacto_accion || "");
                            setTipoCliente(cliente.categoria_cliente || "");
                          }}
                        />
                      )
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
