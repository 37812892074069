/* eslint-disable unicorn/prefer-module */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import Modal from "react-modal"; // Importa react-modal
import { useAuthContext } from "../../context/auth";
import { getEdificios } from "../../utils";
import FichaEdificioWindow from "../FichaEdificioWindow";
import { Loader } from "../Loader";
import "./stylesInventarioAvanzado.scss";

Modal.setAppElement("#root"); // Necesario para accesibilidad

export const InventarioAvanzado = () => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [selectedEdificioId, setSelectedEdificioId] = useState(null);
  const [allEdificios, setAllEdificios] = useState([]); // Almacena todos los edificios
  const [filtroEstado, setFiltroEstado] = useState([]);
  const [filtroUbicacion, setFiltroUbicacion] = useState([]);
  const [filtroCircuito, setFiltroCircuito] = useState([]);
  const [resumen, setResumen] = useState({
    cantidadEdificios: 0,
    cantidadPantallas: 0,
    cantidadPersonas: 0,
  });
  const [estadosUnicos, setEstadosUnicos] = useState([]);
  const [showEstadoFilter, setShowEstadoFilter] = useState(true);

  const { pais } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  useEffect(() => {
    const initialize = async () => {
      const allBuildings = await getEdificios({ pais });
      setAllEdificios(allBuildings);
    };

    initialize();
  }, [pais]);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const handleEdificioClick = (id) => {
    setSelectedEdificioId(id); // Establece el ID del edificio seleccionado para abrir en la nueva ventana
  };

  useEffect(() => {
    if (allEdificios.length > 0) {
      const uniqueEstados = [...new Set(allEdificios.map((edificio) => edificio.estado))].filter(
        (estado) => estado !== "PROYECTO"
      );
      setEstadosUnicos(uniqueEstados);
      setShowEstadoFilter(uniqueEstados.length > 1); // Mostrar el filtro solo si hay más de un estado
    }
  }, [allEdificios]);

  const edificios = useMemo(
    () =>
      allEdificios.filter((edificio) => {
        // Filtro por estado
        if (filtroEstado.length > 0 && !filtroEstado.includes(edificio.estado)) return false;
        // Filtro por ubicación
        if (filtroUbicacion.length > 0 && !filtroUbicacion.includes(edificio.ubicacion)) return false;

        // Filtro por circuito (pantallas)
        if (filtroCircuito.length > 0) {
          const hasAnyCircuito = filtroCircuito.some((circuito) => {
            if (circuito === "pantallas") return edificio.pantallas > 0;
            if (circuito === "circulación") return (edificio.totems || edificio.cowork) > 0;
            if (circuito === "espera") return edificio.espera > 0;
            if (circuito === "cowork") return (edificio.cowork || 0) > 1; // Nueva condición para cowork
            return false;
          });
          if (!hasAnyCircuito) return false;
        }

        // Si quieres mostrar todos los estados, no solo OPERATIVO e INSTALACIÓN, quita esta condición
        return edificio.estado === "OPERATIVO" || edificio.estado === "INSTALACIÓN";
      }),
    [allEdificios, filtroEstado, filtroUbicacion, filtroCircuito]
  );

  const sortedEdificios = useMemo(() => {
    if (!edificios) return [];

    const estadoPrioridad = {
      OPERATIVO: 1,
      INSTALACIÓN: 2,
      STANDBY: 3,
      PRÓXIMO: 4,
    };

    return [...edificios].sort((a, b) => {
      if (sortedColumn === "estado") {
        if (estadoPrioridad[a.estado] < estadoPrioridad[b.estado]) return sortOrder === "asc" ? -1 : 1;
        if (estadoPrioridad[a.estado] > estadoPrioridad[b.estado]) return sortOrder === "asc" ? 1 : -1;
      } else if (sortedColumn === "ubicacion") {
        return sortOrder === "asc" ? a.ubicacion.localeCompare(b.ubicacion) : b.ubicacion.localeCompare(a.ubicacion);
      } else if (sortedColumn === "personas") {
        return sortOrder === "asc" ? a.personas - b.personas : b.personas - a.personas;
      } else if (sortedColumn === "edificio") {
        return sortOrder === "asc" ? a.edificio.localeCompare(b.edificio) : b.edificio.localeCompare(a.edificio);
      } else if (sortedColumn === "estacionamientos") {
        return sortOrder === "asc"
          ? (a.estacionamientos || 0) - (b.estacionamientos || 0)
          : (b.estacionamientos || 0) - (a.estacionamientos || 0);
      } else {
        // Orden por defecto si no hay columna seleccionada
        if (estadoPrioridad[a.estado] < estadoPrioridad[b.estado]) return -1;
        if (estadoPrioridad[a.estado] > estadoPrioridad[b.estado]) return 1;
        if (a.personas !== b.personas) {
          return b.personas - a.personas;
        }
        return a.edificio.localeCompare(b.edificio);
      }
    });
  }, [edificios, sortedColumn, sortOrder]);

  useEffect(() => {
    const updateResumen = () => {
      if (filtroEstado.length > 0 || filtroUbicacion.length > 0 || filtroCircuito.length > 0) {
        const filteredEdificios = edificios;
        const resumenData = {
          cantidadEdificios: filteredEdificios.length,
          cantidadPantallas: filteredEdificios.reduce(
            (sum, edificio) =>
              sum +
              (edificio.pantallas || 0) +
              (edificio.totems || 0) +
              (edificio.espera || 0) +
              (edificio.cowork || 0),
            0
          ),
          cantidadPersonas: filteredEdificios.reduce((sum, edificio) => sum + (edificio.personas || 0), 0),
          cantidadEstacionamientos: filteredEdificios.reduce(
            (sum, edificio) => sum + (edificio.estacionamientos || 0),
            0
          ),
        };
        setResumen(resumenData);
      } else {
        setResumen({ cantidadEdificios: 0, cantidadPantallas: 0, cantidadPersonas: 0 });
      }
    };

    updateResumen();
  }, [edificios, filtroEstado, filtroUbicacion, filtroCircuito]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  let a = 1;

  const renderUbicacionOptions = () => {
    if (!allEdificios) return null;

    // Definimos el orden de los estados
    const ordenEstados = ["OPERATIVO", "INSTALACIÓN", "STANDBY", "PRÓXIMO"];

    // Creamos un mapa para almacenar ubicaciones y sus estados predominantes
    const ubicacionesConEstados = {};

    // Iteramos sobre todos los edificios para agregar su ubicación y estado al mapa
    allEdificios.forEach((edificio) => {
      if (!ubicacionesConEstados[edificio.ubicacion]) {
        ubicacionesConEstados[edificio.ubicacion] = [];
      }
      ubicacionesConEstados[edificio.ubicacion].push(edificio.estado);
    });

    // Ordenamos las ubicaciones basado en el estado predominante (el estado que aparece primero en ordenEstados)
    const ubicacionesOrdenadas = Object.keys(ubicacionesConEstados).sort((a, b) => {
      const estadoA = Math.min(...ubicacionesConEstados[a].map((estado) => ordenEstados.indexOf(estado)));
      const estadoB = Math.min(...ubicacionesConEstados[b].map((estado) => ordenEstados.indexOf(estado)));
      return estadoA - estadoB;
    });

    return ubicacionesOrdenadas.map((ubicacion, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`ubicacion-${index}`}
          value={ubicacion}
          checked={filtroUbicacion.includes(ubicacion)}
          onChange={() => {
            if (filtroUbicacion.includes(ubicacion)) {
              setFiltroUbicacion(filtroUbicacion.filter((u) => u !== ubicacion));
            } else {
              setFiltroUbicacion([...filtroUbicacion, ubicacion]);
            }
          }}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`ubicacion-${index}`} style={{ marginLeft: "10px" }}>
          {ubicacion}
        </label>
      </div>
    ));
  };

  const renderCircuitoOptions = () =>
    ["pantallas", "circulación", "espera", "cowork"].map((circuito, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`circuito-${index}`}
          value={circuito}
          checked={filtroCircuito.includes(circuito)}
          onChange={() => {
            if (filtroCircuito.includes(circuito)) {
              setFiltroCircuito(filtroCircuito.filter((c) => c !== circuito));
            } else {
              setFiltroCircuito([...filtroCircuito, circuito]);
            }
          }}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`circuito-${index}`} style={{ marginLeft: "10px" }}>
          {circuito === "pantallas" ? "Ascensores" : circuito.charAt(0).toUpperCase() + circuito.slice(1)}
        </label>
      </div>
    ));

  return (
    <div className="inventario-avanzado-table ">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div className="row mb-3 justify-content-center">
        {showEstadoFilter && (
          <div className="col-md-2">
            <div className="form-group">
              <select
                className="form-control"
                id="estadoFilter"
                onChange={(e) => {
                  const value = e.target.value;
                  setFiltroEstado(value ? [value] : []);
                }}
              >
                <option value="">Status</option>
                {estadosUnicos.map((estado, index) => (
                  <option key={index} value={estado}>
                    {estado}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className={`col-md-${showEstadoFilter ? "2" : "3"}`}>
          <Dropdown>
            <Dropdown.Toggle
              variant="white border"
              id="dropdown-ubicacion"
              className={`form-control text-start ${filtroUbicacion.length > 0 ? "active-filter" : ""}`}
            >
              {filtroUbicacion.length > 0 ? filtroUbicacion.join(", ") : "Ubicación"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderUbicacionOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={`col-md-${showEstadoFilter ? "2" : "3"}`}>
          <Dropdown>
            <Dropdown.Toggle
              variant="white border"
              id="dropdown-circuito"
              className={`form-control text-start ${filtroCircuito.length > 0 ? "active-filter" : ""}`}
            >
              {filtroCircuito.length > 0
                ? filtroCircuito.map((c) => c.charAt(0).toUpperCase() + c.slice(1)).join(", ")
                : "Circuito"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderCircuitoOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {resumen.cantidadEdificios > 0 && (
        <div className="row mb-3 justify-content-center">
          <div className="col text-center">
            <strong>Selección: </strong>
            <span className="resumen-value">{resumen.cantidadEdificios}</span> edificios
            <span className="resumen-separator"> | </span>
            <span className="resumen-value">{resumen.cantidadPantallas}</span> pantallas
            <span className="resumen-separator"> | </span>
            <span className="resumen-value">
              {resumen.cantidadPersonas.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>{" "}
            personas
            <span className="resumen-separator"> | </span>
            <span className="resumen-value">
              {resumen.cantidadEstacionamientos.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>{" "}
            estacionamientos
          </div>
        </div>
      )}
      {allEdificios.length > 0 ? (
        <table className="inventario-avanzado-table table table-striped table-hover table-bordered mt-4 table-sm">
          <thead className="table-dark text-center align-middle">
            <tr>
              <th rowSpan="2" className="col-3">
                #
              </th>
              <th
                className={`col-20 ${sortedColumn === "edificio" ? "sorted" : ""}`} // Increased from `col-15` to `col-20`
                onClick={() => handleSort("edificio")}
                rowSpan="2"
              >
                EDIFICIO{sortedColumn === "edificio" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col-10 ${sortedColumn === "estado" ? "sorted" : ""}`}
                onClick={() => handleSort("estado")}
                rowSpan="2"
              >
                STATUS{sortedColumn === "estado" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col-10 ${sortedColumn === "ubicacion" ? "sorted" : ""}`}
                onClick={() => handleSort("ubicacion")}
                rowSpan="2"
              >
                UBICACIÓN{sortedColumn === "ubicacion" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col-18 ${sortedColumn === "direccion" ? "sorted" : ""}`}
                onClick={() => handleSort("direccion")}
                rowSpan="2"
              >
                DIRECCIÓN
              </th>
              <th
                className={`col-10 ${sortedColumn === "programatica" ? "sorted" : ""}`} // Increased from `col-8` to `col-10`
                onClick={() => handleSort("programatica")}
                rowSpan="2"
              >
                PROGRAMMATIC{sortedColumn === "programatica" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col-8 ${sortedColumn === "personas" ? "sorted" : ""}`}
                onClick={() => handleSort("personas")}
                rowSpan="2"
              >
                PERSONAS {sortedColumn === "personas" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col-8 ${sortedColumn === "estacionamientos" ? "sorted" : ""}`}
                onClick={() => handleSort("estacionamientos")}
                rowSpan="2"
              >
                PARKINGS{sortedColumn === "estacionamientos" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col-20 ${sortedColumn === "pantallas" ? "sorted" : ""}`}
                onClick={() => handleSort("pantallas")}
                colSpan="4"
              >
                PANTALLAS
              </th>
            </tr>
            <tr className="table-active">
              <th
                className={`col-5 ${sortedColumn === "ascensores" ? "sorted" : ""}`}
                onClick={() => handleSort("ascensores")}
              >
                ASC.
              </th>
              <th className={`col-5 ${sortedColumn === "tótems" ? "sorted" : ""}`} onClick={() => handleSort("tótems")}>
                CIRC.
              </th>
              <th className={`col-5 ${sortedColumn === "espera" ? "sorted" : ""}`} onClick={() => handleSort("espera")}>
                ESP.
              </th>
              <th className={`col-5 ${sortedColumn === "espera" ? "sorted" : ""}`} onClick={() => handleSort("espera")}>
                TOTAL{sortedColumn === "espera" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
            </tr>
          </thead>
          <tbody className="table-striped text-center align-middle">
            {sortedEdificios.map(
              ({
                id,
                edificio,
                pantallas,
                ubicacion,
                direccion,
                personas,
                estado,
                programatica,
                totems,
                espera,
                cowork,
                estacionamientos,
              }) => {
                const className =
                  estado === "OPERATIVO" ? "estadoOperativo" : estado === "PRÓXIMO" ? "estadoProximo" : "estadoStandby";
                const statusStyle = {
                  backgroundColor:
                    estado === "OPERATIVO"
                      ? "green"
                      : estado === "INSTALACIÓN" // Changed to orange for INSTALACIÓN
                        ? "orange"
                        : estado === "STANDBY"
                          ? "red"
                          : "darkorange", // Keep darkorange for PRÓXIMO or other states
                  color: "white",
                };
                return (
                  <tr key={id}>
                    <td className="col-3">{a++}</td>
                    <td className="text-start col-15" onClick={() => handleEdificioClick(id)}>
                      {edificio.toUpperCase()}
                    </td>
                    <td className={`col-8 ${className}`} style={statusStyle}>
                      {estado}
                    </td>
                    <td className="col-8">{ubicacion}</td>
                    <td className="text-start col-18">{direccion}</td>
                    <td className="col-8">{programatica ? <FaCheck /> : "-"}</td>
                    <td className="col-8">
                      {personas.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </td>
                    <td className="col-8">{estacionamientos ? estacionamientos : "-"}</td>
                    <td className="col-5">{pantallas ? pantallas : "-"}</td>
                    <td className="col-5">{totems ? totems : cowork ? cowork : "-"}</td>
                    <td className="col-6">{espera ? espera : "-"}</td>
                    <td className="col-6">{pantallas + totems + cowork + espera}</td>
                  </tr>
                );
              }
            )}
          </tbody>
          <tfoot className="bottom">
            <tr className="table-danger text-center align-middle">
              <td colSpan="6" />
              <td className="col-8 bottomtotals">
                {sortedEdificios
                  .map((item) => item.personas)
                  .reduce((prev, curr) => prev + curr, 0)
                  .toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </td>
              <td className="col-8 bottomtotals">
                {sortedEdificios
                  .map((item) => item.estacionamientos)
                  .reduce((prev, curr) => prev + curr, 0)
                  .toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </td>
              <td className="col-5 bottomtotals">
                {sortedEdificios.map((item) => item.pantallas).reduce((prev, curr) => prev + curr, 0)}
              </td>
              <td className="col-5 bottomtotals">
                {sortedEdificios.map((item) => item.totems + item.cowork).reduce((prev, curr) => prev + curr, 0)}
              </td>
              <td className="col-6 bottomtotals">
                {sortedEdificios.map((item) => item.espera).reduce((prev, curr) => prev + curr, 0)}
              </td>
              <td className="col-6 bottomtotals">
                {sortedEdificios
                  .map((item) => item.pantallas + item.totems + item.cowork + item.espera)
                  .reduce((prev, curr) => prev + curr, 0)}
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <Loader />
      )}
      {selectedEdificioId && <FichaEdificioWindow id={selectedEdificioId} pais={pais} />}
    </div>
  );
};
