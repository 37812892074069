import React from "react";
import { BlackArrow, OportunidadesFactura } from "../../components";
import "./stylesOportunidadFactura.scss";

export const OportunidadFactura = () => (
  <>
    <BlackArrow />
    <OportunidadesFactura />
  </>
);
