/* eslint-disable unicorn/prefer-module */
/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { ListaEdificios } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificios, saveUsuario, getUsers, getIntermediarios, updateUsuario } from "../../utils";
import "./stylesUsuarios.scss";

export const Usuarios = () => {
  const [edificios, setEdificios] = useState([]);
  const methods = useForm();
  const [usuarios, setUsuarios] = useState([]);
  const [sortColumn, setSortColumn] = useState("nombre");
  const [sortDirection, setSortDirection] = useState("asc");
  const [newUser, setNewUser] = useState({
    nombre: "",
    apellido: "",
    email: "",
    rol: "",
    edificiosOk: "",
    agencia: "",
  });
  const [showListaEdificios, setShowListaEdificios] = useState(false);
  const [intermediarios, setIntermediarios] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { pais, rol } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  useEffect(() => {
    const fetchEdificios = async () => {
      try {
        const data = await getEdificios({ pais });
        setEdificios(data);
      } catch (error) {
        console.error("Error al obtener edificios:", error);
      }
    };
    fetchEdificios();
  }, [pais]);

  const fetchUsuarios = async () => {
    try {
      const newUsuarios = await getUsers();
      setUsuarios(newUsuarios);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  useEffect(() => {
    fetchUsuarios();
    const fetchIntermediarios = async () => {
      try {
        const newIntermediarios = await getIntermediarios({ pais });
        setIntermediarios(newIntermediarios);
      } catch (error) {
        console.error("Error al obtener intermediarios:", error);
      }
    };
    fetchIntermediarios();
  }, [pais]);

  const handleEditClick = (usuario) => {
    setEditingUser(usuario);
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    if (editingUser && editingUser.id) {
      try {
        const updatedUser = await updateUsuario({
          id: editingUser.id,
          email: editingUser.email,
          agencia: editingUser.agencia !== undefined ? parseInt(editingUser.agencia, 10) : null,
        });
        if (updatedUser) {
          setUsuarios((prevUsers) =>
            prevUsers.map((user) => (user.id === updatedUser.id ? { ...user, ...updatedUser } : user))
          );
          setEditingUser(null);
          setIsEditing(false);
          const newUsuarios = await getUsers();
          setUsuarios(newUsuarios);
          setTimeout(() => {}, 1000);
        }
      } catch (error) {
        console.error("Error saving user:", error);
      }
    }
  };

  const handleEmailChange = (e) => {
    if (editingUser) {
      setEditingUser({ ...editingUser, email: e.target.value });
    }
  };

  const handleAgenciaChange = (e) => {
    if (editingUser) {
      setEditingUser({ ...editingUser, agencia: e.target.value });
    }
  };

  const getNombresEdificios = (edificiosOk) => {
    if (typeof edificiosOk === "string") {
      edificiosOk = edificiosOk.split(",").map((id) => parseInt(id.trim(), 10));
    }
    if (!edificiosOk || !Array.isArray(edificiosOk)) return "No Aplica";
    const nombres = edificios
      .filter((edificio) => edificiosOk.includes(edificio.id))
      .map((edificio) => edificio.edificio)
      .join(", ");
    return nombres || "No Aplica";
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(sortColumn === column && sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedUsuarios = usuarios
    .filter((usuario) => {
      const validPais =
        (pais === "Uruguay" && usuario.pais === "URU") ||
        (pais === "Perú" && usuario.pais === "PER") ||
        (pais === "Chile" && usuario.pais === "CHI");
      const validRole =
        rol === "comercial"
          ? usuario.rol === "agencia"
          : !["superadmin", "comercial", "administrativo"].includes(usuario.rol);
      return validPais && validRole;
    })
    .sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;
    if (name === "nombre" || name === "apellido") {
      processedValue = capitalizeFirstLetter(value.trimEnd());
    } else if (name === "email") {
      processedValue = value.toLowerCase();
    }
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: processedValue,
    }));
    if (name === "rol") {
      setShowListaEdificios(value === "edificio");
      if (value !== "edificio") {
        setNewUser((prevUser) => ({
          ...prevUser,
          edificiosOk: "",
        }));
      }
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    const edificiosSeleccionados = methods.getValues("edificiosSeleccionados");
    const userWithCountryAndIntermediary = {
      ...newUser,
      pais:
        {
          Uruguay: "URU",
          Perú: "PER",
          Chile: "CHI",
        }[pais] || "",
      edificiosOk: edificiosSeleccionados,
      agencia: newUser.agencia,
      rol: rol === "comercial" ? "agencia" : newUser.rol,
    };
    try {
      const data = await saveUsuario(userWithCountryAndIntermediary);
      if (Array.isArray(data)) {
        setUsuarios([...usuarios, ...data]);
      } else if (data) {
        setUsuarios([...usuarios, data]);
      }
      setNewUser({
        nombre: "",
        apellido: "",
        email: "",
        rol: "",
        edificiosOk: "",
        agencia: "",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error al agregar usuario:", error);
    }
  };

  return (
    <div className="usuarios-table">
      <div className="header-container">
        <div className="logo-container">
          <img src={logo} alt={`${pais} Logo`} className="logo" />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleAddUser} className="form-container mb-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-2">
                <input
                  type="text"
                  className="form-control mb-2"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  value={newUser.nombre}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trimEnd();
                    handleInputChange({ target: { name: "nombre", value: trimmedValue } });
                  }}
                  onBlur={(e) => {
                    const trimmedValue = capitalizeFirstLetter(e.target.value.trimEnd());
                    handleInputChange({ target: { name: "nombre", value: trimmedValue } });
                  }}
                  required
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  className="form-control mb-2"
                  id="apellido"
                  name="apellido"
                  placeholder="Apellido"
                  value={newUser.apellido}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trimEnd();
                    handleInputChange({ target: { name: "apellido", value: trimmedValue } });
                  }}
                  onBlur={(e) => {
                    const trimmedValue = capitalizeFirstLetter(e.target.value.trimEnd());
                    handleInputChange({ target: { name: "apellido", value: trimmedValue } });
                  }}
                  required
                />
              </div>
              <div className="col-2">
                <input
                  type="email"
                  className="form-control mb-2"
                  id="email"
                  name="email"
                  placeholder="Correo"
                  value={newUser.email}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value.trim().endsWith(" ")) {
                      handleInputChange({ target: { name: "email", value: value } });
                    }
                  }}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trimEnd();
                    if (trimmedValue !== e.target.value) {
                      handleInputChange({ target: { name: "email", value: trimmedValue } });
                    }
                  }}
                  required
                />
              </div>
              {rol !== "comercial" && (
                <div className="col-2">
                  <select
                    className="form-control mb-2"
                    id="rol"
                    name="rol"
                    value={newUser.rol}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Seleccionar
                    </option>
                    <option value="agencia">Agencia</option>
                    <option value="edificio">Edificio</option>
                  </select>
                </div>
              )}
              {rol === "comercial" && <input type="hidden" name="rol" value="agencia" />}
              <div className="col-2">
                <select
                  className="form-control mb-2"
                  id="agencia"
                  name="agencia"
                  value={newUser.agencia || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccionar Intermediario</option>
                  {intermediarios.map((intermediario) => (
                    <option key={intermediario.id} value={intermediario.id}>
                      {intermediario.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-auto">
                <button type="submit" className="btn btn-primary mb-2">
                  Agregar Usuario
                </button>
              </div>
            </div>
          </form>
          {showListaEdificios && (
            <div className="mb-5">
              <ListaEdificios
                forceSubmit={() => {
                  const edificiosSeleccionados = methods.getValues("edificiosSeleccionados");
                  setNewUser((prevState) => ({
                    ...prevState,
                    edificiosOk: edificiosSeleccionados,
                  }));
                }}
              />
            </div>
          )}
        </FormProvider>
      </div>
      <table className="table table-bordered table-hover align-middle" style={{ tableLayout: "fixed", width: "100%" }}>
        <thead className="table-dark text-center align-middle">
          <tr>
            <th className="col-nombre" onClick={() => handleSort("nombre")} style={{ cursor: "pointer" }}>
              Nombre {sortColumn === "nombre" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th className="col-apellido" onClick={() => handleSort("apellido")} style={{ cursor: "pointer" }}>
              Apellido {sortColumn === "apellido" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th className="col-correo" onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
              Correo {sortColumn === "email" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th className="col-agencia" onClick={() => handleSort("agencia")} style={{ cursor: "pointer" }}>
              Agencia {sortColumn === "agencia" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            {rol !== "comercial" && (
              <th className="col-rol" onClick={() => handleSort("rol")} style={{ cursor: "pointer" }}>
                Rol {sortColumn === "rol" && (sortDirection === "asc" ? "↑" : "↓")}
              </th>
            )}
            {rol !== "comercial" && (
              <th className="col-edificios" onClick={() => handleSort("edificiosOk")} style={{ cursor: "pointer" }}>
                Edificios OK {sortColumn === "edificiosOk" && (sortDirection === "asc" ? "↑" : "↓")}
              </th>
            )}
            <th className="col-edit">Edit</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td className="col-nombre text-start">{usuario.nombre}</td>
              <td className="col-apellido text-start">{usuario.apellido}</td>
              <td className="col-correo text-center" style={{ verticalAlign: "middle" }}>
                {isEditing && editingUser && editingUser.id === usuario.id ? (
                  <input
                    type="email"
                    value={editingUser.email}
                    onChange={handleEmailChange}
                    className="form-control mb-2"
                    style={{
                      width: "90%",
                      fontSize: "16px",
                      textAlign: "center",
                      margin: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                ) : (
                  <span style={{ overflow: "hidden", textOverflow: "ellipsis", display: "block" }}>
                    {usuario.email.toLowerCase()}
                  </span>
                )}
              </td>
              <td className="col-agencia text-center" style={{ verticalAlign: "middle" }}>
                {isEditing && editingUser && editingUser.id === usuario.id ? (
                  <select
                    value={editingUser.agencia || ""}
                    onChange={handleAgenciaChange}
                    className="form-control mb-2"
                    style={{
                      width: "90%",
                      fontSize: "16px",
                      textAlign: "center",
                      margin: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <option value="">Seleccionar</option>
                    {intermediarios.map((intermediario) => (
                      <option key={intermediario.id} value={intermediario.id} style={{ textAlign: "center" }}>
                        {intermediario.nombre}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span style={{ overflow: "hidden", textOverflow: "ellipsis", display: "block" }}>
                    {intermediarios.find((i) => i.id === usuario.agencia)?.nombre || "-"}
                  </span>
                )}
              </td>
              {rol !== "comercial" && <td className="col-rol text-center">{usuario.rol}</td>}
              {rol !== "comercial" && (
                <td className="col-edificios text-center">
                  {usuario.rol === "agencia" ? "No Aplica" : getNombresEdificios(usuario.edificiosOk)}
                </td>
              )}
              <td className="col-edit text-center">
                {isEditing && editingUser && editingUser.id === usuario.id ? (
                  <FiSave onClick={handleSaveClick} style={{ cursor: "pointer" }} />
                ) : (
                  <FaEdit onClick={() => handleEditClick(usuario)} style={{ cursor: "pointer" }} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
