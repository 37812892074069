/* eslint-disable unicorn/prefer-module */
/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from "react";
import { VentasMiListaClientes, VentasTablaEjecutivo } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getClientesPublicitarios, getUsers } from "../../utils";
import "./stylesVentasEjecutivo.scss";

const VentasEjecutivo = () => {
  const [showTable, setShowTable] = useState(false);
  const [filtroEjecutivo, setFiltroEjecutivo] = useState("");
  const [usuarios, setUsuarios] = useState([]);

  const { rol, id, pais } = useAuthContext() || {};

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const toggleTableVisibility = () => {
    setShowTable(!showTable);
  };

  const showTableCondition = () => rol === "comercial" || (rol !== "comercial" && filtroEjecutivo !== "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paisCode = pais; // Ajusta según cómo obtienes el código del país
        const [clientesData, usuariosData] = await Promise.all([
          getClientesPublicitarios({ pais: paisCode }),
          getUsers(),
        ]);

        // Obtener ejecutivos únicos de los clientes
        const ejecutivosDelPais = new Set(clientesData.map((cliente) => cliente.ejecutivo));
        const filteredUsuarios = usuariosData.filter(
          (user) =>
            ejecutivosDelPais.has(user.id.toString()) && ["comercial", "gerencia", "superadmin"].includes(user.rol)
        );
        setUsuarios(filteredUsuarios);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    if (rol === "gerencia" || rol === "superadmin") {
      fetchData();
    }
  }, [pais, rol]);

  const handleEjecutivoChange = (e) => {
    setFiltroEjecutivo(e.target.value);
  };

  return (
    <div>
      <div className="ventas-ejecutivo">
        <div className="logo-container mb-4">
          <img src={logo} alt={`${pais} Logo`} className="logo" />
        </div>
        <div className="row justify-content-center">
          {rol === "comercial" ? (
            <>
              <div className="col-md-12 text-center mb-3">
                <button
                  onClick={toggleTableVisibility}
                  className="btn btn-dark"
                  disabled={!showTableCondition() && rol !== "comercial"}
                >
                  {!showTableCondition()
                    ? "Mostrar Proyección"
                    : showTable
                      ? "Ocultar Proyección"
                      : "Mostrar Proyección"}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 text-center">
                <select
                  className={`form-control ${filtroEjecutivo ? "active-filter" : ""} mb-2 custom-select-width`}
                  value={filtroEjecutivo}
                  onChange={handleEjecutivoChange}
                >
                  <option value="">Todo el equipo</option>
                  {usuarios.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.nombre} {user.apellido}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 text-center mb-3">
                <button
                  onClick={toggleTableVisibility}
                  className="btn btn-dark"
                  disabled={!showTableCondition() && rol !== "comercial"}
                >
                  {!showTableCondition()
                    ? "Mostrar Proyección"
                    : showTable
                      ? "Ocultar Proyección"
                      : "Mostrar Proyección"}
                </button>
              </div>
            </>
          )}
        </div>
        {showTable && showTableCondition() && (
          <VentasTablaEjecutivo filtroEjecutivo={rol === "comercial" ? id : filtroEjecutivo} />
        )}
        <VentasMiListaClientes filtroEjecutivo={rol === "comercial" ? id : filtroEjecutivo} />
      </div>
    </div>
  );
};

export default VentasEjecutivo;
