import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom"; // Importa useNavigate en lugar de useHistory
import cx from "classix";
import { Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { log, logout, updateUsuario } from "../../utils";

const Perfil = () => {
  const { nombre, apellido, correo } = useAuthContext();
  const [isUsuarioActualizado, setIsUsuarioActualizado] = useState(false);
  const navigate = useNavigate(); // Usa useNavigate para la redirección

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm();

  const onLogout = useCallback(async () => {
    const success = await logout();
    if (success) {
      navigate("/login"); // Usa navigate para redirigir al usuario a la página de inicio de sesión
    }
  }, [navigate]);

  const onSubmit = useCallback(async (values) => {
    try {
      const success = await updateUsuario({
        apellido: values.apellido,
        nombre: values.nombre,
      });

      if (success) setIsUsuarioActualizado(true);
    } catch (error) {
      log.error("onSubmit", error);
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (correo) {
        setValue("nombre", nombre);
        setValue("apellido", apellido);
        setValue("correo", correo);
      }
    };

    initialize();
  }, [apellido, correo, nombre, setValue]);

  if (correo === undefined) return <Loader fullPage />;

  if (isUsuarioActualizado)
    return (
      <Container className="Perfil" header titulo="¡Perfil Actualizado!">
        <p>Tus datos fueron correctamente recepcionados.</p>
        <p>Verifica tu correo en las próximas 24 hs para validar tu registro.</p>
      </Container>
    );

  return (
    <Container className="Perfil" header titulo="Perfil">
      <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-3">
          <label htmlFor="nombre" className="form-label">
            Nombre
          </label>
          <input
            className={cx("form-control", errors?.nombre && "is-invalid")}
            id="nombre"
            placeholder="Ej.: Pedro"
            type="text"
            {...register("nombre", { required: true })}
          />
          <div className="invalid-feedback">Este campo es obligatorio</div>
        </div>

        <div className="col-3">
          <label htmlFor="apellido" className="form-label">
            Apellido
          </label>
          <input
            className={cx("form-control", errors?.apellido && "is-invalid")}
            id="apellido"
            placeholder="Ej.: González"
            type="text"
            {...register("apellido", { required: true })}
          />
          <div className="invalid-feedback">Este campo es obligatorio</div>
        </div>

        <div className="col-3">
          <label htmlFor="correo" className="form-label">
            Correo
          </label>
          <input
            className={cx("form-control", errors?.correo && "is-invalid")}
            id="correo"
            placeholder="Ej.: correo@tuemail.com"
            type="text"
            {...register("correo", { required: true })}
          />
          <div className="invalid-feedback">Este campo es obligatorio</div>
        </div>

        <div className="col-6 d-flex gap-3">
          <button className="btn btn-outline-secondary" onClick={onLogout} type="button">
            Cerrar sesión <BiLogOut size={20} />
          </button>
          <Link to="/admin" className="btn btn-link">
            Ir a admin
          </Link>
        </div>
        <div className="col-2 d-flex gap-3 justify-content-end">
          <button className="btn btn-primary" type="submit">
            Actualizar perfil
          </button>
        </div>
      </form>
    </Container>
  );
};

export default Perfil;
