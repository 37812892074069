import React, { useState } from "react";
import "./stylesCotizadorProgrammaticFrecuencia.scss";

export const CotizadorProgrammaticFrecuencia = ({
  onFrecuenciaChange,
  isProgrammaticSelected,
  isDigitalSelected,
  selectedOption,
}) => {
  const [selectedPaquete, setSelectedPaquete] = useState("");

  const handlePaqueteChange = (event) => {
    setSelectedPaquete(event.target.value);
    onFrecuenciaChange(event.target.value);
  };

  // Determinar si una frecuencia está recomendada según la opción seleccionada
  const isRecommended = (value) => {
    if (isProgrammaticSelected) {
      return ["25", "30"].includes(value);
    }
    if (isDigitalSelected) {
      if (selectedOption === "FULL DAY | 07:00 - 21:00") {
        return ["5", "10"].includes(value);
      }
      if (selectedOption === "AM | 07:00 - 14:00" || selectedOption === "PM | 14:00 - 21:00") {
        return ["15", "20"].includes(value);
      }
    }
    return false;
  };

  return (
    <div className="frecuencia">
      <p className="titulofrecuencia">FRECUENCIA</p>
      <hr />
      <p className="subtitulo">
        SALIDAS POR HORA <br /> POR PANTALLA
      </p>
      <hr />
      <label className="checkboxfrecuencia">
        <input
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          checked={selectedPaquete === "5"}
          name="paquete"
          onChange={handlePaqueteChange}
          type="checkbox"
          value="5"
        />
        5 {isRecommended("5") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          checked={selectedPaquete === "10"}
          name="paquete"
          onChange={handlePaqueteChange}
          type="checkbox"
          value="10"
        />
        10 {isRecommended("10") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "15"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="15"
        />
        15 {isRecommended("15") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "20"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="20"
        />
        20 {isRecommended("20") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "25"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="25"
        />
        25 {isRecommended("25") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "30"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="30"
        />
        30 {isRecommended("30") && "(recomendado)"}
      </label>
      <br />
    </div>
  );
};
