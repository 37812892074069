/* eslint-disable unicorn/prefer-module */
/* eslint-disable no-console */
import { useState, useEffect, useMemo } from "react";
import { IoLocation } from "react-icons/io5";
import { GENERAL } from "../../constants";
import { useAuthContext } from "../../context/auth";
import { getEdificios } from "../../utils";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import "./stylesMapa.scss";

export const Mapa = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [edificios, setEdificios] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(["OPERATIVO", "INSTALACIÓN", "PRÓXIMO", "STANDBY"]);
  const [buildingCounts, setBuildingCounts] = useState({});

  const { pais } = useAuthContext();

  const localString = (pais) =>
    ({
      Chile: "es-CL",
      Perú: "es-PE",
      Uruguay: "es-UY",
    })[pais] || "es-UY";

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const position = useMemo(
    () =>
      ({
        Chile: { lat: -33.41305, lng: -70.60635 },
        Perú: { lat: -12.10979, lng: -77.01751 },
        Uruguay: { lat: -34.89434, lng: -56.15867 },
      })[pais] || { lat: -12.10979, lng: -77.01751 },
    [pais]
  );

  const handleCategoryToggle = (category) => {
    const updatedCategories = [...selectedCategories];
    const index = updatedCategories.indexOf(category);

    if (index !== -1) {
      updatedCategories.splice(index, 1);
    } else {
      updatedCategories.push(category);
    }

    setSelectedCategories(updatedCategories);
  };

  const CustomInfoBox = ({ title, ascensores, totems, personas, espera, id, estado }) => (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "whitesmoke",
        padding: 10,
        borderRadius: 5,
        width: "350px",
        border: "1px solid black",
        textAlign: "left",
      }}
    >
      <h2
        style={{
          background: "darkred",
          padding: "8px",
          margin: 0,
          borderRadius: "3px",
          color: "white",
          marginBottom: "15px",
          textAlign: "center",
        }}
      >
        {title}
      </h2>
      <p>
        AFORO:{" "}
        {personas.toLocaleString(localString(pais), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </p>
      <p>INVENTARIO:</p>
      <ul>
        {ascensores ? <li>ASCENSORES: {ascensores}</li> : null}
        {totems ? <li>TOTEMS: {totems}</li> : null}
        {espera ? <li>ESPERA: {espera}</li> : null}
      </ul>
      {(estado === "OPERATIVO" || estado === "INSTALACIÓN") && (
        <img
          src={require(`../../img/edificios/${id}.png`)}
          alt="Edificio"
          style={{
            width: "100%",
            height: "auto",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        />
      )}
    </div>
  );

  const getMarkerColor = (estado) => {
    switch (estado) {
      case "STANDBY":
        return "grey";
      case "PRÓXIMO":
        return "blue";
      case "OPERATIVO":
        return "darkred";
      default:
        return "green";
    }
  };

  const Legend = () => (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
        backgroundColor: "white",
        padding: 10,
        borderRadius: 5,
        border: "1px solid black",
        textAlign: "left",
        zIndex: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label
        style={{
          display: "flex",
          alignItems: "center",
          color: selectedCategories.includes("OPERATIVO") ? "darkred" : "black",
          marginBottom: 5,
          cursor: "pointer",
          fontSize: "20px",
        }}
      >
        <input
          type="checkbox"
          checked={selectedCategories.includes("OPERATIVO")}
          onChange={() => handleCategoryToggle("OPERATIVO")}
          style={{ marginRight: "10px" }}
        />
        OPERATIVO: {buildingCounts["OPERATIVO"] || 0} edificios
      </label>
      {buildingCounts["INSTALACIÓN"] !== 0 && (
        <label
          style={{
            display: "flex",
            alignItems: "center",
            color: selectedCategories.includes("INSTALACIÓN") ? "green" : "black",
            marginBottom: 5,
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          <input
            type="checkbox"
            checked={selectedCategories.includes("INSTALACIÓN")}
            onChange={() => handleCategoryToggle("INSTALACIÓN")}
            style={{ marginRight: "10px" }}
          />
          INSTALACIÓN: {buildingCounts["INSTALACIÓN"] || 0} edificios
        </label>
      )}
      {buildingCounts["PRÓXIMO"] !== 0 && (
        <label
          style={{
            display: "flex",
            alignItems: "center",
            color: selectedCategories.includes("PRÓXIMO") ? "blue" : "black",
            marginBottom: 5,
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          <input
            type="checkbox"
            checked={selectedCategories.includes("PRÓXIMO")}
            onChange={() => handleCategoryToggle("PRÓXIMO")}
            style={{ marginRight: "10px" }}
          />
          PRÓXIMO: {buildingCounts["PRÓXIMO"] || 0} edificios
        </label>
      )}
      {buildingCounts["STANDBY"] !== 0 && (
        <label
          style={{
            display: "flex",
            alignItems: "center",
            color: selectedCategories.includes("STANDBY") ? "grey" : "black",
            marginBottom: 5,
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          <input
            type="checkbox"
            checked={selectedCategories.includes("STANDBY")}
            onChange={() => handleCategoryToggle("STANDBY")}
            style={{ marginRight: "10px" }}
          />
          STANDBY: {buildingCounts["STANDBY"] || 0} edificios
        </label>
      )}
    </div>
  );

  const sortEdificios = (a, b) => {
    const order = {
      grey: 1,
      blue: 2,
      red: 3,
    };

    return order[getMarkerColor(a.estado)] - order[getMarkerColor(b.estado)];
  };

  const filteredEdificios = edificios
    ? edificios.filter((edificio) => selectedCategories.includes(edificio.estado))
    : [];

  const handleMarkerClick = (marker) => setSelectedMarker(marker);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificios({ pais });
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    const filteredEdificios = edificios
      ? edificios.filter((edificio) => selectedCategories.includes(edificio.estado))
      : [];

    const counts = selectedCategories.reduce((acc, category) => {
      acc[category] = filteredEdificios.filter((edificio) => edificio.estado === category).length;
      return acc;
    }, {});
    setBuildingCounts(counts);
  }, [selectedCategories, edificios]);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GENERAL.GMAP_API_KEY}&libraries=maps&callback=__googleMapsCallback__`;
      script.async = true; // Cargar el script de manera asíncrona
      script.defer = true;
      script.addEventListener("load", () => {
        console.log("Google Maps API script loaded successfully!");
      });
      script.addEventListener("error", () => {
        console.error("Error loading Google Maps API script!");
      });

      // Verificar si ya existe un script con el mismo src antes de añadirlo
      const existingScript = document.querySelector(`script[src="${script.src}"]`);
      if (!existingScript) {
        document.head.appendChild(script);
      }
    };

    loadGoogleMapsScript();
  }, []);

  return (
    <APIProvider apiKey={GENERAL.GMAP_API_KEY}>
      <div className="Mapa">
        <Map center={position} zoom={13.5} mapId={GENERAL.GMAP_MAP_ID} position="relative">
          {filteredEdificios &&
            filteredEdificios.sort(sortEdificios).map((edificio) => {
              const markerColor = getMarkerColor(edificio.estado);
              const zIndex = markerColor === "grey" ? 2 : markerColor === "blue" ? 1 : 0;

              return (
                <AdvancedMarker
                  key={edificio.id}
                  position={{ lat: edificio.lat, lng: edificio.lng }}
                  onClick={() => handleMarkerClick(edificio)}
                >
                  <IoLocation
                    style={{
                      color: getMarkerColor(edificio.estado),
                      fontSize: "30px",
                      zIndex,
                    }}
                  />
                </AdvancedMarker>
              );
            })}
        </Map>
        {selectedMarker && (
          <CustomInfoBox
            ascensores={selectedMarker.pantallas}
            totems={selectedMarker.totems}
            espera={selectedMarker.espera}
            personas={selectedMarker.personas}
            title={selectedMarker.edificio.toUpperCase()}
            id={selectedMarker.id}
            estado={selectedMarker.estado}
          />
        )}
        <Legend />

        <div className="logo">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </div>
    </APIProvider>
  );
};
