import React, { useState } from "react";
import "./stylesCotizadorProgrammaticDuracion.scss";

export const CotizadorProgrammaticDuracion = ({ onDuracionChange }) => {
  const [selectedDuration, setSelectedDuration] = useState(null);

  // Función para manejar el cambio de selección de una duración
  const handleDurationChange = (e) => {
    const duration = parseInt(e.target.value);
    setSelectedDuration(duration);
    onDuracionChange(duration);
  };

  return (
    <div className="duracion">
      <p className="tituloduracion">DURACIÓN</p>
      <hr />
      <p className="subtitulo">SPOT PUBLICITARIO</p>
      <hr />
      {[...Array(10)].map((_, index) => {
        const duration = index + 6; // Las duraciones van desde 6 hasta 15 segundos
        return (
          <div key={index}>
            <input
              checked={selectedDuration === duration}
              id={`duration-${duration}`}
              name="duration"
              onChange={handleDurationChange}
              style={{ width: "15px", height: "15px", marginRight: "10px" }}
              type="checkbox"
              value={duration}
            />
            <label htmlFor={`duration-${duration}`}>{`${duration} SEGUNDOS`}</label>
          </div>
        );
      })}
      {/* Mostramos la duración seleccionada */}
    </div>
  );
};
