// utils/index.js (o donde definas login)
import { log, supabase } from ".";

export const login = async ({ email, pais, senderEmail }) => {
  try {
    log.debug("pais enviado a loginWithEmail:", pais); // Log antes de llamar
    const user = await supabase.loginWithEmail(email, pais);
    log.debug("login resultado:", user);
    return user;
  } catch (error) {
    log.error("login", error);
    throw error;
  }
};
