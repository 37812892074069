/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getClientesPublicitarios, getUsers, getCategorias } from "../../utils";
import "./stylesListaClientesPorEjecutivo.scss";

export const ListaClientesPorEjecutivo = () => {
  const [clientes, setClientesPublicitarios] = useState([]);
  const [usuariosEjecutivos, setUsuariosEjecutivos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const { pais } = useAuthContext();

  const getPaisCode = (countryName) => {
    switch (countryName.toLowerCase()) {
      case "uruguay":
        return "URU";
      case "chile":
        return "CHI";
      case "perú":
        return "PER";
      default:
        return countryName;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const paisCode = getPaisCode(pais);

        // Fetch all data in parallel
        const [newClientesPublicitarios, allUsers, categoriasData] = await Promise.all([
          getClientesPublicitarios({ pais }),
          getUsers({}),
          getCategorias(),
        ]);

        // Set clients
        setClientesPublicitarios(newClientesPublicitarios || []);

        // Filter and sort executives
        const filteredEjecutivos = (allUsers || [])
          .filter((user) => ["comercial", "gerencia"].includes(user.rol) && getPaisCode(user.pais) === paisCode)
          .sort((a, b) => {
            // "comercial" comes before "gerencia"
            if (a.rol === "comercial" && b.rol === "gerencia") return -1;
            if (a.rol === "gerencia" && b.rol === "comercial") return 1;
            return 0;
          });
        setUsuariosEjecutivos(filteredEjecutivos);

        // Set categories
        setCategorias(categoriasData || []);
      } catch (error) {
        console.error("Error initializing:", error);
      }
    };

    initialize();
  }, [pais]);

  const getClientesPorEjecutivo = (ejecutivoId) =>
    clientes.filter(
      (cliente) =>
        String(cliente.ejecutivo) === String(ejecutivoId) && (!filtroCategoria || cliente.categoria === filtroCategoria)
    );

  const renderCategoriaOptions = () =>
    categorias.map((categoria, index) => (
      <option key={index} value={categoria.nombre}>
        {categoria.nombre}
      </option>
    ));

  const handleCategoriaChange = (e) => {
    setFiltroCategoria(e.target.value);
  };

  if (usuariosEjecutivos.length === 0) {
    return <div>No hay ejecutivos disponibles para {pais}</div>;
  }

  // Calculate equal width percentage based on number of executives
  const columnWidth = `${100 / usuariosEjecutivos.length}%`;

  return (
    <div className="container">
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-md-3">
          <select
            className={`form-control ${filtroCategoria ? "active-filter" : ""}`}
            value={filtroCategoria}
            onChange={handleCategoriaChange}
          >
            <option value="">Todas las Categorías</option>
            {renderCategoriaOptions()}
          </select>
        </div>
      </div>

      <table className="table table-bordered table-hover table-striped" style={{ tableLayout: "fixed" }}>
        <thead className="table-dark">
          <tr>
            {usuariosEjecutivos.map((ejecutivo) => {
              const clientCount = getClientesPorEjecutivo(ejecutivo.id).length;
              return (
                <th key={ejecutivo.id} className="text-center" style={{ width: columnWidth }}>
                  <div>{ejecutivo.nombre}</div>
                  <small style={{ color: "white" }}>
                    {clientCount === 0
                      ? "Sin Clientes"
                      : `${clientCount} ${clientCount === 1 ? "cliente" : "clientes"}`}
                  </small>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {(() => {
            const maxClients = Math.max(...usuariosEjecutivos.map((ej) => getClientesPorEjecutivo(ej.id).length), 1);

            const rows = [];
            for (let i = 0; i < maxClients; i++) {
              rows.push(
                <tr key={i}>
                  {usuariosEjecutivos.map((ejecutivo) => {
                    const clientesEjecutivo = getClientesPorEjecutivo(ejecutivo.id);
                    return (
                      <td key={ejecutivo.id} className="text-start">
                        {clientesEjecutivo[i] ? (
                          <div>
                            <div>{clientesEjecutivo[i].nombre}</div>
                            {!filtroCategoria && <small className="text-muted">{clientesEjecutivo[i].categoria}</small>}
                          </div>
                        ) : i === 0 && clientesEjecutivo.length === 0 ? (
                          <span className="text-muted">-</span>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            }
            return rows;
          })()}
        </tbody>
      </table>
    </div>
  );
};
