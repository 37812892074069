import { log, supabase } from ".";

export const updateUsuario = async ({ id, email, agencia }) => {
  try {
    const newUserData = {
      email: email || null,
      agencia: agencia !== undefined ? parseInt(agencia, 10) : null,
    };

    const data = await supabase.update("usuarios", newUserData, id);

    if (!data || data.length === 0) {
      throw new Error("No data returned from update");
    }

    return data;
  } catch (error) {
    log.error("updateUsuario", error);
    throw error;
  }
};
