import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getCotizaciones } from "../../utils";
import "./stylesCotizacionesTable.scss";

const CotizacionesTable = () => {
  const { pais } = useAuthContext(); // Obtener el país del contexto de autenticación
  const [cotizaciones, setCotizaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCotizaciones = async () => {
      try {
        const data = await getCotizaciones({ pais });
        setCotizaciones(data);
      } catch {
        setError("Error al cargar cotizaciones");
      } finally {
        setLoading(false);
      }
    };

    fetchCotizaciones();
  }, [pais]);

  if (loading) {
    return <div>Cargando cotizaciones...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <table className="table table-bordered table-hover">
        <thead className="table-dark">
          <tr>
            <th className="text-center">USUARIO</th>
            <th className="text-center">CÓDIGO</th>
            <th className="text-center">FECHA CREACIÓN</th>
          </tr>
        </thead>
        <tbody>
          {cotizaciones.map((cotizacion) => (
            <tr key={cotizacion.id}>
              <td>{`${cotizacion.usuario_nombre} ${cotizacion.usuario_apellido}`}</td>
              <td>{cotizacion.codigo_generado}</td>
              <td>{new Date(cotizacion.created_at).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CotizacionesTable;
